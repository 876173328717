<template>
<!-- eslint-disable vue/require-v-for-key -->
  <div id="news">
    Dernières infos USACcgt
    <div id="carousel">
      <div class="newsItem" v-for="item in news">
        {{item.shortDateFr}} : <a :href="item.link">{{item.title}}</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
div#news {
  grid-column: span 10;
  padding: .5em;
  background-color: rgba(255, 255, 255, .3);
  border-radius: var(--baseline);
}

/* CSS Caroussel, adapted from : https://www.w3.org/Style/Examples/007/slideshow.en.html */
#carousel {
  margin-top: .5em;
  position: relative;
  overflow: hidden;
  height: 100%;
}

#carousel > * {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 0;
  animation: 20s autoplay infinite ease-in-out
}

@keyframes autoplay {
  0% {opacity: 0; visibility: visible;}
  4% {opacity: 1;}
  18% {opacity: 1;}
  22% {opacity: 0; visibility: hidden;}
}
#carousel > *:nth-child(1) {animation-delay: 0s}
#carousel > *:nth-child(2) {animation-delay: 4s}
#carousel > *:nth-child(3) {animation-delay: 8s}
#carousel > *:nth-child(4) {animation-delay: 12s}
#carousel > *:nth-child(5) {animation-delay: 16s}

</style>

<script setup>
import { ref, onMounted } from 'vue';

const news = ref([]);

const isDev = process.env.NODE_ENV === 'development';
const RSS_URL = 'https://www.usac-cgt.org/rss.xml';
const RSS_LOCAL = './rss.xml'; // used as fallback in dev mode

function feed() {
  const output = fetch(RSS_URL)
    .then(
      (response) => response.text(),
      (reason) => {
        if (isDev) {
          /* provide a placeholder in dev mode */
          return fetch(RSS_LOCAL).then((response) => response.text());
        }
        return Promise.reject(reason);
      },
    )
    // some items contains &nbsp; entity wich are not allowed in XML
    .then((str) => str.replace(/&nbsp;/g, '&#xA0;'))
    .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'));
  return output;
}

onMounted(async () => {
  const f = await feed();
  const items = f.getElementsByTagName('item');
  const shortList = [...items].slice(0, 5);
  const jsObjects = shortList.map((item) => {
    const obj = {};
    ['title', 'link', 'description', 'pubDate'].forEach((tagName) => {
      obj[tagName] = item.getElementsByTagName(tagName)[0].textContent;
    });
    const pubDate = new Date(Date.parse(obj.pubDate));
    obj.shortDateFr = `${pubDate.getDate()}/${(pubDate.getMonth() + 1).toString().padStart(2, '0')}`;
    return obj;
  });
  news.value = jsObjects;
});

</script>

<script>
export default {
  name: 'NewsHeader',
};
</script>
