<template>
  <header>
    <div class="wip" v-if="!isDev">
      Version <b>interne</b> en cours de développement. Ne pas diffuser l'URL.
    </div>
    <a href="https://usac-cgt.org/" id="logo" title="Site web de l'USACcgt">
      <img alt="Site web de l'USACcgt" src="./assets/logo-usac-horizontal-web.png">
    </a>
    <NewsHeader />
    <nav>
      <router-link to="/">Simulateur</router-link> |
      <a href='https://usac-cgt.org/wiki/index.php'>Documentation</a> |
      <router-link to="/about">À propos</router-link>
    </nav>
  </header>
  <matomo-tracking />
  <router-view/>
</template>

<style scoped>
header {
  background: url('./assets/fond_bandeau_1.jpg') center left no-repeat;
  background-size: cover;
  text-align: center;
}

nav a {
  font-weight: bold;
  color: var(--primary-color);
}

nav a.router-link-exact-active {
  color: var(--secondary-color);
}

.wip {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background: red;
  color:white;
  padding-top: .2em;
  padding-bottom: .5em;
  font-size:1.1em;
  z-index: 1000;
}

/* layout */
header {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

#logo {
  grid-row:1;
  grid-column: 1 / 4;
}

#news {
  grid-row: 1;
  grid-column: 5 / 12;
}

nav {
  grid-row: 2;
  grid-column: 1 / -1;
}

/* fine-grain layout*/
header {
  min-height: calc(var(--baseline) * 15);
  padding: var(--baseline-x2) calc(1px + var(--baseline-x2));
  padding-bottom: 0px;
}
nav {
  padding-bottom: .5em;
}

nav > a {
  /* blur backgroud behind text
     add mask to soften transition
  */
  backdrop-filter: blur(5px);
  mask-image: linear-gradient(
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,1) 25%,
    rgba(0,0,0,1) 80%,
    rgba(0,0,0,0) 100%);
}

#logo>img {
  height: var(--baseline-x8);
  width: 100%;
  object-fit: contain;
}

</style>

<script>

// @ is an alias to /src
import NewsHeader from '@/components/result/inside_parts/News.vue';
import MatomoTracking from '@/components/MatomoTracking.vue';

export default {
  name: 'App',
  components: {
    NewsHeader,
    MatomoTracking,
  },
  computed: {
    isDev: () => process.env.NODE_ENV === 'development',
  },
};

</script>
