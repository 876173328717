<template>
    <AnimatedSelector
      label="Expérience"
      title = "fonction du grade"
      :modelValue="internal"
      :items="getLevels"
      @update:modelValue="handleChange($event)"
    />
</template>

<style scoped>
</style>

<script setup>
import {
  computed,
  defineEmits,
  ref,
  watch,
} from 'vue';

import AnimatedSelector from '@/components/input/widgets_general/AnimatedSelector.vue';

const props = defineProps(['modelValue', 'corps']);

const internal = ref(props.modelValue);

const levelsIESSA = [
  {
    desc: 'divisionnaire, QTS < 15 ans',
    value: 4,
  }, {
    desc: 'divisionnaire, QTS ≥ 15 ans',
    value: 5,
  },
];
const levelsICNA = [
  {
    desc: 'divisionnaire, PC < 9 ans',
    value: 4,
  }, {
    desc: 'divisionnaire, PC ≥ 9 ans',
    value: 5,
  },
];

const getLevels = computed(() => (props.corps === 'iessa' ? levelsIESSA : levelsICNA));

const emit = defineEmits(['update:modelValue']);
watch(() => props.modelValue, (nVal) => {
  internal.value = nVal;
}, {
  immediate: true,
});

function handleChange(e) {
  emit('update:modelValue', e);
  [internal.value] = e;
}

</script>

<script>
export default {
  name: 'RistExperience',
};
</script>
