/**
 * Copyright (C) 2016-2022 Bruno Spyckerelle
 * Copyright (C) 2022-2023 USACcgt
 * License AGPL 3.0
 *
 * This program is free software: you can redistribute it and/or modify it under the terms of the
 * GNU Affero General Public License as published by the Free Software Foundation, version 3.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY;
 * without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See
 * the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License along with this
 * program. If not, see <https://www.gnu.org/licenses/>
 *
 */

import { Observer } from "./utils.js";
import moment from './ext/moment.min.js';

import {echelons, table_indices, evs, partTechIEEAC, evsLevel} from "./datas/generated.js"

import {
    point_indice,
    rpc_rate,
    rpc_pat_rate,
    indemnite_nuit,
    indemnite_teletravail,
    pcs,
    points_nbi,
    validityDates,
    transfertRetenue,
    csg_deduc,
    modulationRMA,
    modulationCDGIessa,
    modulationCDGIcna,
    modulationCDGIcnaLevel,
    modulationLFRRIcna,
    domtom,
    sft_fixe,
    sft_prop,
    sft_bound,
    tauxAboTransport,
} from './datas/const.js';

import {
    modulationGeoIEEAC,
    partExperience,
    partTechIESSA,
    partTechIESSALevel,
    partTechTSQualif,
    partTechTSQualifLevel,
    partTechTSHabil,
    partTechTSHabilLevel,
    expe2016,
    expe2016IessaLevel,
    partLicence,
    partLicenceLevel,
    complementPartLicence,
    xpRH2016,
} from './datas/grilles.js';

import {
    grades,
    detachements,
    detachementsByCorps,
} from './datas/grades.js';


var csgDate = moment('2018-01-01');
var irDate = moment('2019-01-01');

/**
 * Centralize data access for paysheet computing.
 *
 * Handle time-dependent data (and fill gaps in definition,
 * ie provide last valid version when no data is present
 * for a given year).
 *
 * Fire "data-update" when date is changed
 *
 */
export class DataProvider extends Observer {
    constructor(aDate) {
        super();
        this.setDate(aDate);
        // FIXME access through globals
        this.yearlyData = {
            evs,
            partTechIEEAC,
            partTechIESSA,
            partLicence,
            complementPartLicence,
            partTechTSQualif,
            partTechTSHabil,
            modulationGeoIEEAC,
            partExperience,
            rpc_rate,
            rpc_pat_rate,
            csg_deduc,
            expe2016,
            modulationCDGIessa,
            xpRH2016,
            modulationLFRRIcna,
            sft_bound,
        };
        this.validityDates = validityDates;
        this.grades = grades;
        this.detachements = detachements;
        this.detachementsByCorps = detachementsByCorps;
        this.echelons = echelons;
        this.domtom = domtom;
        this.points_nbi = points_nbi;
        this.transfertRetenue = transfertRetenue;
        this.evsLevel = evsLevel;
        this.sft_fixe = sft_fixe;
        this.sft_prop = sft_prop;
        this.indemnite_nuit = indemnite_nuit;
        this.expe2016IessaLevel = expe2016IessaLevel;
        this.partTechIESSALevel = partTechIESSALevel;
        this.partLicenceLevel = partLicenceLevel;
        this.partTechTSHabilLevel = partTechTSHabilLevel;

        this.partYearData = {
            point_indice,
            table_indices: {data: table_indices, format: 'YYYY-MM-DD'},
            pcs,
            tauxAboTransport,
            indemnite_teletravail,
        }

        this.setupGetters();
    }

    setupGetters() {
        for (let key in this.yearlyData) {
            Object.defineProperty(this, key, {
                get: () => {
                    return this.get_yearly_data(this.yearlyData[key], this.year);
                }
            });
        }
        for (let key in this.partYearData) {
            Object.defineProperty(this, key, {
                get: () => {
                    return this.get_part_year_data(this.partYearData[key], this.currentDate);
                }
            });
        }

    }

    setDate(aDate) {
        if (aDate != this.currentDate) {
            this.currentDate = aDate;
            this.currentMoment = moment(aDate, 'DD/MM/YYYY');
            this.fire("data-update", this);
        }
    }
    suscribeValidityChange(aValidityObservable) {
        aValidityObservable.bind("validity", this.updateValidity, this);
    }
    updateValidity(newValue) {
        console.log("DataProvider | updateValidity | "+newValue);
        this.setDate(newValue)
    }

    get year() {
        return this.currentMoment.year();
    }

    getEchelons(corps,grade,ef) {
        if (grade != null) {
            if (grade.localeCompare("détaché")==0) {
                if (ef != null) {
                    return this.get_yearly_data(this.echelons["detachement"])[ef];
                }
            } else {
                return this.get_yearly_data(this.echelons[corps])[grade]
            }
        }
    }
    getGrille(corps) {
        return this.get_yearly_data(this.echelons[corps])
    }

    get_yearly_data(anObj, aYear) {
        if (aYear == undefined) {
            aYear = this.year;
        }
        if (aYear in anObj) {
            return anObj[aYear];
        }
        if (typeof aYear == "string") {
            aYear = parseInt(aYear)
        }
        for (var z = aYear; z--; z >= 2015) {
            if (z in anObj) {
               anObj[aYear] = anObj[z]
                return anObj[aYear];
            }
        }
        throw "error!";
    }

    get_part_year_data(anObj, aDate) {
        const dateFormat = "D/M/YYYY";
        let dateFormatParse;
        if ("format" in anObj) {
            dateFormatParse = anObj.format;
            anObj = anObj.data;
        } else {
            dateFormatParse = dateFormat;
        }
        const originalDate = aDate
        if (aDate == undefined) {
            aDate = this.currentMoment;
        } else if (aDate in anObj) {
            return anObj[aDate]
        } else if (typeof aDate == "string") {
            aDate = moment(aDate, dateFormat)
        }
        const key = aDate.format(dateFormat)
        if (key in anObj) {
            anObj[originalDate] = anObj[key];
            return anObj[key];
        } else {
            let keys = Object.keys(anObj);
            let moments = keys.map(d => {
                return {k:d,m:moment(d, dateFormatParse)}
            })
            let invSortedMoments = moments.sort((a,b)=>b.m-a.m)
            for (let i = 0; i < invSortedMoments.length; i++) {
                if(aDate >= invSortedMoments[i].m) {
                    anObj[originalDate] = anObj[invSortedMoments[i].k];
                    return anObj[originalDate];
                }
            }
        }
    }
    beforeCSG() {
        return this.currentMoment < csgDate;
    }
    beforeIR() {
        return this.beforeIR < irDate;
    }
}
