export var grades = {
    "ieeac": [
        "élève",
        "normal",
        "principal",
        "hors classe",
        "détaché"
    ],
    "icna": [
        "élève",
        "stagiaire",
        "normal",
        "principal",
        "divisionnaire",
        "en chef",
        "détaché"
    ],
    "iessa": [
        "élève",
        "stagiaire",
        "normal",
        "principal (jusqu'à 2018)",
        "divisionnaire",
        "en chef",
        "détaché"
    ],
    "tseeac": [
        "élève",
        "stagiaire",
        "normal",
        "principal",
        "exceptionnel",
        "détaché"
    ]
};

export var detachementsByCorps = {
  "tseeac": [
    "RTAC",
    "CTAC",
    "CSTAC",
  ],
  "ieeac": [
    "CTAC",
    "CSTAC",
    "CUTAC",
    "CST",
    "CSTP",
  ],
  "iessa": [
    "CTAC",
    "CSTAC",
    "CUTAC",
    "CST",
    "CSTP",
  ],
  "icna": [
    "CUTAC",
    "CST",
    "CSTP",
  ]
};

/* retrocompat with jekyll */
function generateDetachements(byC) {
    let output = [];
    Object.values(byC).forEach(element => {
        element.forEach(ef => {
            if (!output.includes(ef)) {
                output.push(ef);
            }
        })
    });
    return output;
}

export var detachements = generateDetachements(detachementsByCorps);
