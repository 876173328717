import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

const isDev = process.env.NODE_ENV === 'development';

const app = createApp(App)
  .use(store)
  .use(router)
  .mount('#app');

if (window.Cypress || isDev) {
  // only available during Dev or E2E tests
  window.app = app;
  window.store = store;
}
