<template>
  <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
  <div class="item_social">
    <p class="see_tooltip" @pointerover="showTooltip=true" @pointerleave="showTooltip=false">?</p>
    <h5>{{title}}</h5>
    <p class="item_social_part_salariale">
      <span :data-value="value_sal">
        {{ typeof value_sal === 'number' ? value_sal.toFixed(2) +' €' : value_sal }}
      </span>
    </p>
    <p class="item_social_part_patronale">
      <span>
        {{ typeof value_pat === 'number' ? value_pat.toFixed(2) +' €' : value_pat }}
      </span>
    </p>
    <p class="item_social_description" v-show="showTooltip">
      <b v-if="ligne">{{ligne}}</b><br>
          {{desc}}
          <em v-if="complement"><br>{{complement}}</em>
    </p>
  </div>
</template>

<style scoped>
/* Une grosse partie du code html est commun avec la composant ItemSocial.
 * Du coup, une bonne partie  * des styles CSS est dans le composant ItemSocial.
 */
.see_tooltip {
  position: absolute;
  left: calc(50% - var(--baseline));
  top: var(--baseline-x4);
}
span {
    display: block;
}

.item_social_part_patronale span, .item_social_part_salariale span {
  font-family: var(--title-text);
  text-transform: uppercase;
  font-size: var(--font-size-x2);
  line-height: var(--baseline-x4);
  height: var(--baseline-x4);
  /*margin-bottom: calc(var(--baseline));*/
  text-align: center;
  border-radius: var(--baseline-x2);
}

.item_social_part_salariale span {
  background-color: var(--secondary-lt2-color);
  border: 1px solid var(--secondary-color);
}

.item_social_part_patronale span {
  background-color: var(--primary-lt2-color);
  border: 1px solid var(--primary-color);
}

/* 1rst adapt Css vite fait, à modifier par la suite */
@media (max-width: 1024px) {
  .item_social_part_patronale span, .item_social_part_salariale span {
    font-size: var(--font-size-normal);
  }
}
@media (max-width: 800px) {
  .item_social_part_patronale span, .item_social_part_salariale span {
    font-size: var(--font-size-x2);
  }
}
@media (max-width: 640px) {
  .item_social_part_patronale span, .item_social_part_salariale span {
    font-size: var(--font-size-normal);
  }
}
@media (max-width: 1024px) and (min-width: 800px) {
  .see_tooltip {
    position: static;
    left: auto;
    top: auto;
    order: 99;
    text-align: center;
  }
}
</style>

<script setup>
import {
  defineProps,
  onMounted,
  ref,
  /* computed, */
} from 'vue';
// import { useStore } from 'vuex';
// const store = useStore();
defineProps(['title', 'value_sal', 'value_pat', 'desc', 'ligne', 'complement']);
const myId = ref();

// const boolTooltipId = computed(() => `boolTooltipD${myId.value}`);

const showTooltip = ref(false);

onMounted(() => {
  myId.value = getId();
});

</script>

<script>
export default {
  name: 'SocialDouble',
};

let counter = 0;
function getId() {
  counter += 1;
  return counter;
}
</script>
