<template>
  <aside>
    <InputForm />
  </aside>

  <main>
    <header>
      <h1>Simulateur de Salaire</h1>
      <h2>Votre fiche de Paye</h2>
    </header>

    <section id="chiffreclef">
      <ItemSocial
        title="Salaire brut"
        :value="paysheet.total_pos"
        desc="Le salaire brut est une base de calcul."
        />
      <ItemSocial
        title="Salaire net imposable"
        :value="paysheet.totalImposable"
        desc="Une partie de la CSG est soumise à l’impôt sur le revenu. C'est sur ce
        montant qu'est calculé l’impôt sur le revenu."
        />
      <ItemSocial
        title="Salaire net à payer"
        :value="paysheet.total"
        desc="Sur ce salaire net, il faut retirer l'impôt à la source pour obtenir
        le versement mensuel sur son compte bancaire"
        />
      <ItemSocial
        title="Salaire versé"
        :value="paysheet.netir"
        desc="Ce qui est réellement versé sur le compte bancaire"
        data-cy="netapayer"
        />
    </section>

    <GnavTabs />
  </main>
</template>

<script>
// @ is an alias to /src
import { computed } from 'vue';
import { useStore } from 'vuex';

import InputForm from '@/components/input/InputForm.vue';
import ItemSocial from '@/components/result/inside_parts/ItemSocial.vue';
import GnavTabs from '@/components/result/inside_parts/GnavTabs.vue';

export default {
  name: 'HomeView',
  components: {
    InputForm,
    ItemSocial,
    GnavTabs,
  },
  beforeRouteEnter(to) {
    // instance store is not yet created beforeEnter
    const sto = useStore();
    sto.dispatch('handleQuery', to.query);
  },
};
</script>

<script setup>

const store = useStore();

const paysheet = computed(() => store.getters.paysheet);

</script>
