import { reactive } from 'vue';
import { createStore } from 'vuex';

import router from '@/router/index';

import deducers from '@/store/deducers';
import { PaySheetComputer } from '../../../js/core';
import { DataProvider } from '../../../js/data';

import { getSiteFromId } from '../../../js/sites';
import { Observer, isValidFrenchdateStr } from '../../../js/utils';
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

const handlerMixin = {
  get(obj, prop) {
    if (prop in obj) {
      return obj[prop];
    }
    if (prop in state.deduced) {
      return state.deduced[prop];
    }
    if (prop in state.default) {
      return state.default[prop];
    }
    return undefined;
  },
  set(obj, prop, val) {
    console.log(`set(${obj},${prop})`);
    const idx = state.actions.findIndex((a) => a[0] === 'set' && a[1] === prop);
    if (idx !== -1) {
      state.actions.splice(idx, 1);
    }
    state.actions.push(['set', prop, val]);
    /* callback is required to handle batch property change
     ie, if two or more properties are pushed to route
     in a raw, only the last one is visible in url */
    setTimeout(() => updateRoute(prop, val), 0);
    obj[prop] = val;
    this.fire(prop, state);
    return true;
  },
  ownKeys() {
    return [...new Set([
      ...state.actions.map((a) => a[1]),
      ...Reflect.ownKeys(state.deduced),
      ...Reflect.ownKeys(state.default),
    ])];
  },
  getOwnPropertyDescriptor(target, key) {
    return { value: this.get(target, key), enumerable: true, configurable: true };
  },
};

const handler = new Observer();
Object.assign(handler, handlerMixin);

deducers.forEach((d) => { d.subscribeOn(handler); });

async function updateRoute(prop, val) {
  const newQuery = { ...router.currentRoute.value.query };
  newQuery[prop] = val;
  await router.push({
    name: router.currentRoute.value.name,
    query: newQuery,
  });
}
async function removeFromRoute(prop) {
  const newQuery = { ...router.currentRoute.value.query };
  delete newQuery[prop];
  await router.push({
    name: router.currentRoute.value.name,
    query: newQuery,
  });
}

const state = {
  default: {
    corps: 'iessa',
    validity: '01/01/2024',
    grade: 'élève',
    echelon: 1,
    famille: 0,
  },
  actions: [],
  deduced: reactive({}),
  input: new Proxy({
    get siteData() {
      return getSiteFromId(this.site);
    },
    getStatus(prop) {
      if (prop in this) {
        return 'input';
      }
      if (prop in state.deduced) {
        return 'deduced';
      }
      if (prop in state.default) {
        return 'default';
      }
      return 'notset';
    },
  }, handler),
};

const dp = new DataProvider('1/7/2023');
const c = new PaySheetComputer(dp);

function compute(input) {
  dp.setDate(input.validity);
  return c.compute_income(input);
}

const specialActions = {
  site: 'setSite',
  validity: 'setDate',
};

function handleQuery(context, queryObj) {
  setTimeout(() => {
    delayedHandleQuery(context, queryObj);
  }, 0);
}

async function delayedHandleQuery(context, queryObj) {
  Object.entries(queryObj).forEach(
    async ([key, value]) => {
      if (key in specialActions) {
        await context.dispatch(specialActions[key], value);
      } else {
        context.state.input[key] = value;
      }
    },
  );
}

async function resetState(context) {
  if (context && false) {
    /* FIXME this approach avoir reload but need
      to reset all inputs => including custom widget */
    context.state.actions = [];
    context.state.deduced = {};
    context.state.input = new Proxy({
      get siteData() {
        return getSiteFromId(this.site);
      },
    }, handler);
  }
  await router.push({
    name: router.currentRoute.value.name,
    query: {},
  });
  window.scrollTo(0, 0);
  window.location.reload();
}

export default createStore({
  state,
  getters: {
    paysheet(s) {
      return compute(s.input);
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setValidity(s, aDate) {
      s.input.validity = aDate;
    },
    setSite(s, newSiteCode) {
      const newSite = getSiteFromId(newSiteCode);
      s.input.site = newSiteCode;
      s.deduced.region = newSite.region;
      s.deduced.groupe = newSite.groupe;
      if (newSite.reorgST) {
        s.deduced.reorg = newSite.reorgST;
      } else {
        delete s.deduced.reorg;
      }
      if (newSite.xpRH) {
        s.deduced.xpRHFromSite = newSite.xpRH;
      } else {
        delete s.deduced.xpRHFromSite;
      }
    },
    deleteInput(s, prop) {
      delete s.input[prop];
      setTimeout(() => removeFromRoute(prop), 0);
    },
  },
  actions: {
    setDate(context, aDate) {
      if (isValidFrenchdateStr(aDate)) {
        return context.commit('setValidity', aDate);
      }
      return true;
      // FIXME choose to accept any date or only significant
      // if (dp.validityDates.includes(aDate)) {
      // return context.commit('setValidity', aDate);
      // }
      // return Promise.reject(Error(`date ${aDate} invalide\nvalides: ${dp.validityDates}`));
    },
    setSite(context, aSite) {
      return context.commit('setSite', aSite);
    },
    handleQuery,
    resetState,
  },
  modules: {
  },
});
