/**
  * Generated by script/csv/import.
  */

var echelons = {
  "detachement": {
    "2016": {
      "CTAC": {
        "1": "645",
        "2": "699",
        "3": "743",
        "4": "785",
        "5": "831",
        "6": "875",
        "7": "915",
        "8": "966"
      },
      "CSTAC": {
        "3": "818",
        "4": "864",
        "5": "916",
        "6": "966",
        "7": "1015"
      },
      "CUTAC": {
        "1": "818",
        "2": "864",
        "3": "916",
        "4": "966",
        "5": "1015",
        "6A1": "HEA1",
        "6A2": "HEA2",
        "6A3": "HEA3"
      },
      "CST": {
        "1": "852",
        "2": "901",
        "3": "1015",
        "4A1": "HEA1",
        "4A2": "HEA2",
        "4A3": "HEA3",
        "5B1": "HEB1",
        "5B2": "HEB2",
        "5B3": "HEB3"
      },
      "CSTP": {
        "1": "901",
        "2": "1015",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3",
        "4B1": "HEB1",
        "4B2": "HEB2",
        "4B3": "HEB3",
        "5BB1": "HEBB1",
        "5BB2": "HEBB2",
        "5BB3": "HEBB3"
      }
    },
    "2017": {
      "CTAC": {
        "1": "650",
        "2": "703",
        "3": "748",
        "4": "790",
        "5": "836",
        "6": "880",
        "7": "920",
        "8": "971"
      },
      "CSTAC": {
        "1": "715",
        "2": "760",
        "3": "822",
        "4": "869",
        "5": "921",
        "6": "971",
        "7": "1021"
      },
      "CUTAC": {
        "1": "822",
        "2": "869",
        "3": "921",
        "4": "971",
        "5": "1021",
        "6A1": "HEA1",
        "6A2": "HEA2",
        "6A3": "HEA3"
      },
      "CST": {
        "1": "857",
        "2": "906",
        "3": "1021",
        "4A1": "HEA1",
        "4A2": "HEA2",
        "4A3": "HEA3",
        "5B1": "HEB1",
        "5B2": "HEB2",
        "5B3": "HEB3"
      },
      "CSTP": {
        "1": "906",
        "2": "1021",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3",
        "4B1": "HEB1",
        "4B2": "HEB2",
        "4B3": "HEB3",
        "5BB1": "HEBB1",
        "5BB2": "HEBB2",
        "5BB3": "HEBB3"
      }
    },
    "2019": {
      "CTAC": {
        "1": "657",
        "2": "710",
        "3": "755",
        "4": "797",
        "5": "843",
        "6": "887",
        "7": "927",
        "8": "977"
      },
      "CSTAC": {
        "1": "722",
        "2": "767",
        "3": "828",
        "4": "876",
        "5": "929",
        "6": "977",
        "7": "1027"
      },
      "CUTAC": {
        "1": "828",
        "2": "876",
        "3": "929",
        "4": "977",
        "5": "1027",
        "6A1": "HEA1",
        "6A2": "HEA2",
        "6A3": "HEA3"
      },
      "CST": {
        "1": "862",
        "2": "912",
        "3": "1027",
        "4A1": "HEA1",
        "4A2": "HEA2",
        "4A3": "HEA3",
        "5B1": "HEB1",
        "5B2": "HEB2",
        "5B3": "HEB3"
      },
      "CSTP": {
        "1": "912",
        "2": "1027",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3",
        "4B1": "HEB1",
        "4B2": "HEB2",
        "4B3": "HEB3",
        "5BB1": "HEBB1",
        "5BB2": "HEBB2",
        "5BB3": "HEBB3"
      }
    },
    "2020": {
      "RTAC": {
        "1": "562",
        "2": "593",
        "3": "626",
        "4": "660",
        "5": "687",
        "6": "725",
        "7": "761"
      },
      "CTAC": {
        "1": "661",
        "2": "710",
        "3": "755",
        "4": "797",
        "5": "843",
        "6": "890",
        "7": "946",
        "8": "995"
      },
      "CSTAC": {
        "1": "741",
        "2": "784",
        "3": "828",
        "4": "876",
        "5": "929",
        "6": "977",
        "7": "1027"
      },
      "CUTAC": {
        "1": "784",
        "2": "837",
        "3": "890",
        "4": "946",
        "5": "995",
        "6": "1027",
        "7A1": "HEA1",
        "7A2": "HEA2",
        "7A3": "HEA3"
      },
      "CST": {
        "1": "862",
        "2": "912",
        "3": "1027",
        "4A1": "HEA1",
        "4A2": "HEA2",
        "4A3": "HEA3",
        "5B1": "HEB1",
        "5B2": "HEB2",
        "5B3": "HEB3"
      },
      "CSTP": {
        "1": "912",
        "2": "1027",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3",
        "4B1": "HEB1",
        "4B2": "HEB2",
        "4B3": "HEB3",
        "5BB1": "HEBB1",
        "5BB2": "HEBB2",
        "5BB3": "HEBB3"
      }
    }
  },
  "icna": {
    "2017": {
      "élève": {
        "1": "321"
      },
      "stagiaire": {
        "1": "334"
      },
      "normal": {
        "1": "383",
        "2": "402",
        "3": "418",
        "4": "446",
        "5": "470",
        "6": "507",
        "7": "519",
        "8": "545",
        "9": "560"
      },
      "divisionnaire": {
        "1": "403",
        "2": "419",
        "3": "447",
        "4": "471",
        "5": "508",
        "6": "545",
        "7": "557",
        "8": "595",
        "9": "628",
        "10": "675",
        "11": "704",
        "12": "755",
        "13": "793",
        "14": "826"
      },
      "en chef": {
        "1": "628",
        "2": "675",
        "3": "704",
        "4": "755",
        "5": "793",
        "6": "826",
        "7A1": "885",
        "7A2": "920",
        "7A3": "967"
      }
    },
    "2018": {
      "élève": {
        "1": "321"
      },
      "stagiaire": {
        "1": "334"
      },
      "normal": {
        "1": "388",
        "2": "407",
        "3": "423",
        "4": "451",
        "5": "475",
        "6": "512",
        "7": "524",
        "8": "550",
        "9": "565"
      },
      "divisionnaire": {
        "1": "408",
        "2": "424",
        "3": "452",
        "4": "476",
        "5": "513",
        "6": "550",
        "7": "562",
        "8": "600",
        "9": "633",
        "10": "680",
        "11": "709",
        "12": "760",
        "13": "798",
        "14": "9999"
      },
      "en chef": {
        "1": "625",
        "2": "680",
        "3": "709",
        "4": "760",
        "5": "798",
        "6": "9999",
        "7A1": "9999",
        "7A2": "9999",
        "7A3": "9999"
      }
    },
    "2019": {
      "élève": {
        "1": "340"
      },
      "stagiaire": {
        "1": "359",
        "2": "359"
      },
      "normal": {
        "1": "441",
        "2": "465",
        "3": "490",
        "4": "527",
        "5": "561",
        "6": "610",
        "7": "625",
        "8": "659",
        "9": "675"
      },
      "divisionnaire": {
        "1": "467",
        "2": "491",
        "3": "528",
        "4": "562",
        "5": "611",
        "6": "659",
        "7": "675",
        "8": "725",
        "9": "769",
        "10": "830",
        "11": "868",
        "12": "935",
        "13": "985",
        "14": "1027"
      },
      "en chef": {
        "1": "769",
        "2": "830",
        "3": "868",
        "4": "935",
        "5": "985",
        "6": "1027",
        "7A1": "9999",
        "7A2": "9999",
        "7A3": "9999"
      }
    },
    "2020": {
      "élève": {
        "1": "340"
      },
      "stagiaire": {
        "1": "359",
        "2": "359"
      },
      "normal": {
        "1": "444",
        "2": "469",
        "3": "500",
        "4": "535",
        "5": "572",
        "6": "618",
        "7": "635",
        "8": "661",
        "9": "689"
      },
      "divisionnaire": {
        "1": "471",
        "2": "501",
        "3": "538",
        "4": "573",
        "5": "615",
        "6": "661",
        "7": "699",
        "8": "741",
        "9": "784",
        "10": "837",
        "11": "890",
        "12": "946",
        "13": "995",
        "14": "1027"
      },
      "en chef": {
        "1": "784",
        "2": "837",
        "3": "890",
        "4": "946",
        "5": "995",
        "6": "1027",
        "7A1": "HEA1",
        "7A2": "HEA2",
        "7A3": "HEA3"
      }
    }
  },
  "ieeac": {
    "2016": {
      "élève": {
        "1": "340",
        "2": "359"
      },
      "normal": {
        "1": "416",
        "2": "476",
        "3": "496",
        "4": "510",
        "5": "555",
        "6": "595",
        "7": "634",
        "8": "670",
        "9": "707",
        "10": "732",
        "11": "762"
      },
      "principal": {
        "1": "561",
        "2": "622",
        "3": "685",
        "4": "745",
        "5": "805",
        "6": "860",
        "7": "915",
        "8": "966",
        "9": "1015"
      },
      "hors classe": {
        "1": "966",
        "2": "1015",
        "3A1": "HEA1-16",
        "3A2": "HEA2-16",
        "3A3": "HEA3-16"
      }
    },
    "2017": {
      "élève": {
        "1": "340",
        "2": "359"
      },
      "normal": {
        "1": "434",
        "2": "483",
        "3": "508",
        "4": "525",
        "5": "565",
        "6": "610",
        "7": "649",
        "8": "686",
        "9": "727",
        "10": "744",
        "11": "774"
      },
      "principal": {
        "1": "609",
        "2": "633",
        "3": "697",
        "4": "763",
        "5": "821",
        "6": "879",
        "7": "929",
        "8": "979",
        "9": "1022"
      },
      "hors classe": {
        "1": "979",
        "2": "1022",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3"
      }
    },
    "2018": {
      "élève": {
        "1": "340",
        "2": "359"
      },
      "normal": {
        "1": "434",
        "2": "483",
        "3": "508",
        "4": "525",
        "5": "565",
        "6": "610",
        "7": "649",
        "8": "686",
        "9": "727",
        "10": "744",
        "11": "774"
      },
      "principal": {
        "1": "609",
        "2": "633",
        "3": "697",
        "4": "763",
        "5": "821",
        "6": "879",
        "7": "929",
        "8": "979",
        "9": "1022"
      },
      "hors classe": {
        "1": "979",
        "2": "1022",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3"
      }
    },
    "2019": {
      "élève": {
        "1": "340",
        "2": "359"
      },
      "normal": {
        "1": "441",
        "2": "490",
        "3": "514",
        "4": "532",
        "5": "572",
        "6": "616",
        "7": "656",
        "8": "697",
        "9": "733",
        "10": "751",
        "11": "780"
      },
      "principal": {
        "1": "621",
        "2": "640",
        "3": "702",
        "4": "770",
        "5": "827",
        "6": "885",
        "7": "935",
        "8": "985",
        "9": "1027"
      },
      "hors classe": {
        "1": "985",
        "2": "1027",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3"
      }
    },
    "2020": {
      "élève": {
        "1": "340",
        "2": "359"
      },
      "normal": {
        "1": "444",
        "2": "500",
        "3": "521",
        "4": "538",
        "5": "590",
        "6": "620",
        "7": "659",
        "8": "699",
        "9": "741",
        "10": "758",
        "11": "780"
      },
      "principal": {
        "1": "630",
        "2": "646",
        "3": "706",
        "4": "776",
        "5": "831",
        "6": "896",
        "7": "946",
        "8": "995",
        "9": "1027"
      },
      "hors classe": {
        "1": "995",
        "2": "1027",
        "3A1": "HEA1",
        "3A2": "HEA2",
        "3A3": "HEA3"
      }
    }
  },
  "iessa": {
    "2017": {
      "élève": {
        "1": "321"
      },
      "stagiaire": {
        "1": "334"
      },
      "normal": {
        "1": "383",
        "2": "383",
        "3": "418",
        "4": "446",
        "5": "470",
        "6": "507",
        "7": "519",
        "8": "545",
        "9": "560"
      },
      "divisionnaire": {
        "1": "419",
        "2": "447",
        "3": "471",
        "4": "508",
        "5": "545",
        "6": "557",
        "7": "595",
        "8": "628",
        "9": "653",
        "10": "675",
        "11": "704",
        "12": "755",
        "13": "793",
        "14": "826"
      },
      "en chef": {
        "1": "675",
        "2": "704",
        "3": "755",
        "4": "793",
        "5": "826",
        "6A1": "885",
        "6A2": "920",
        "6A3": "967"
      }
    },
    "2018": {
      "élève": {
        "1": "321"
      },
      "stagiaire": {
        "1": "334"
      },
      "normal": {
        "1": "388",
        "2": "407",
        "3": "423",
        "4": "451",
        "5": "475",
        "6": "512",
        "7": "524",
        "8": "550",
        "9": "565"
      },
      "divisionnaire": {
        "1": "424",
        "2": "452",
        "3": "476",
        "4": "513",
        "5": "550",
        "6": "562",
        "7": "600",
        "8": "633",
        "9": "658",
        "10": "680",
        "11": "709",
        "12": "760",
        "13": "798",
        "14": "9999"
      },
      "en chef": {
        "1": "680",
        "2": "709",
        "3": "760",
        "4": "798",
        "5": "9999",
        "6A1": "9999",
        "6A2": "9999",
        "6A3": "9999"
      }
    },
    "2019": {
      "élève": {
        "1": "321"
      },
      "stagiaire": {
        "1": "334"
      },
      "normal": {
        "1": "390",
        "2": "410",
        "3": "431",
        "4": "456",
        "5": "483",
        "6": "518",
        "7": "532",
        "8": "556",
        "9": "572"
      },
      "divisionnaire": {
        "1": "432",
        "2": "457",
        "3": "484",
        "4": "516",
        "5": "552",
        "6": "580",
        "7": "612",
        "8": "645",
        "9": "670",
        "10": "685",
        "11": "725",
        "12": "760",
        "13": "798",
        "14": "9999"
      },
      "en chef": {
        "1": "685",
        "2": "725",
        "3": "760",
        "4": "798",
        "5": "9999",
        "6A1": "9999",
        "6A2": "9999",
        "6A3": "9999"
      }
    },
    "2020": {
      "élève": {
        "1": "340"
      },
      "stagiaire": {
        "1": "359"
      },
      "normal": {
        "1": "444",
        "2": "469",
        "3": "500",
        "4": "535",
        "5": "572",
        "6": "618",
        "7": "635",
        "8": "661",
        "9": "689"
      },
      "divisionnaire": {
        "1": "471",
        "2": "501",
        "3": "538",
        "4": "573",
        "5": "615",
        "6": "661",
        "7": "699",
        "8": "741",
        "9": "784",
        "10": "818",
        "11": "837",
        "12": "890",
        "13": "946",
        "14": "995",
        "15": "1027"
      },
      "en chef": {
        "1": "837",
        "2": "890",
        "3": "946",
        "4": "995",
        "5": "1027",
        "6A1": "HEA1",
        "6A2": "HEA2",
        "6A3": "HEA3"
      }
    }
  },
  "tseeac": {
    "2017": {
      "élève": {
        "1": "314"
      },
      "stagiaire": {
        "1": "316",
        "2": "322"
      },
      "normal": {
        "1": "341",
        "2": "358",
        "3": "374",
        "4": "389",
        "5": "406",
        "6": "423",
        "7": "440",
        "8": "458",
        "9": "478",
        "10": "500"
      },
      "principal": {
        "1": "409",
        "2": "426",
        "3": "445",
        "4": "462",
        "5": "479",
        "6": "498",
        "7": "513",
        "8": "525"
      },
      "exceptionnel": {
        "1": "477",
        "2": "493",
        "3": "510",
        "4": "530",
        "5": "555",
        "6": "566",
        "7": "589"
      }
    },
    "2018": {
      "élève": {
        "1": "314"
      },
      "stagiaire": {
        "1": "316",
        "2": "322"
      },
      "normal": {
        "1": "355",
        "2": "365",
        "3": "381",
        "4": "396",
        "5": "413",
        "6": "429",
        "7": "447",
        "8": "464",
        "9": "486",
        "10": "511"
      },
      "principal": {
        "1": "416",
        "2": "432",
        "3": "451",
        "4": "468",
        "5": "486",
        "6": "504",
        "7": "520",
        "8": "538"
      },
      "exceptionnel": {
        "1": "483",
        "2": "500",
        "3": "517",
        "4": "538",
        "5": "561",
        "6": "571",
        "7": "593",
        "8": "608"
      }
    },
    "2019": {
      "élève": {
        "1": "325"
      },
      "stagiaire": {
        "1": "333",
        "2": "341"
      },
      "normal": {
        "1": "388",
        "2": "404",
        "3": "431",
        "4": "452",
        "5": "475",
        "6": "498",
        "7": "521",
        "8": "546",
        "9": "576",
        "10": "608"
      },
      "principal": {
        "1": "480",
        "2": "501",
        "3": "527",
        "4": "551",
        "5": "576",
        "6": "599",
        "7": "620",
        "8": "644"
      },
      "exceptionnel": {
        "1": "572",
        "2": "593",
        "3": "616",
        "4": "644",
        "5": "674",
        "6": "687",
        "7": "715",
        "8": "736"
      }
    }
  }
}
var evs = {
  "2017": {
    "1": "419.55",
    "2": "524.43",
    "3": "607.19",
    "4": "677.19",
    "5": "1101.34",
    "6": "1182.91",
    "7": "1258.67",
    "8": "1363.55",
    "9": "1445.14",
    "10": "1515.07",
    "11": "1619.96",
    "12": "1689.89",
    "13": "1788.15",
    "14": "1843.28",
    "15": "1898.41"
  },
  "2023": {
    "1": "434.23",
    "2": "542.79",
    "3": "628.44",
    "4": "700.89",
    "5": "1139.89",
    "6": "1224.31",
    "7": "1302.72",
    "8": "1411.27",
    "9": "1495.72",
    "10": "1568.1",
    "11": "1676.66",
    "12": "1749.04",
    "13": "1850.74",
    "14": "1907.79",
    "15": "1964.85"
  },
  "2024": {
    "1": "440.74",
    "2": "550.93",
    "3": "637.87",
    "4": "711.4",
    "5": "1156.99",
    "6": "1242.67",
    "7": "1322.26",
    "8": "1432.44",
    "9": "1518.16",
    "10": "1591.62",
    "11": "1701.81",
    "12": "1775.28",
    "13": "1878.5",
    "14": "1936.41",
    "15": "1994.32"
  }
}
var partTechIEEAC = {
  "2017": {
    "normal": {
      "1": "429.21",
      "2": "514.53",
      "3": "541.68",
      "4": "563.01",
      "5": "625.06",
      "6": "683.23",
      "7": "741.41",
      "8": "795.7",
      "9": "849.99",
      "10": "884.9",
      "11": "929.5"
    },
    "principal": {
      "1": "650.82",
      "2": "741.95",
      "3": "835.03",
      "4": "924.23",
      "5": "1011.49",
      "6": "1081.6",
      "7": "1081.6",
      "8": "1081.6",
      "9": "1081.6"
    },
    "hors classe": {
      "1": "1081.6",
      "2": "1081.6",
      "3A1": "1081.6",
      "3A2": "1081.6",
      "3A3": "1081.6"
    },
    "CSTAC": {
      "1": "0",
      "2": "0",
      "3": "1028.94",
      "4": "1081.6",
      "5": "1081.6",
      "6": "1081.6",
      "7": "1081.6"
    },
    "CTAC": {
      "1": "0",
      "2": "0",
      "3": "0",
      "4": "982.4",
      "5": "1050.27",
      "6": "1081.6",
      "7": "1081.6",
      "8": "1081.6"
    },
    "détaché_autre": {
      "1": "1081.6"
    }
  },
  "2018": {
    "normal": {
      "1": "440.21",
      "2": "525.53",
      "3": "552.68",
      "4": "574.01",
      "5": "636.06",
      "6": "694.23",
      "7": "752.41",
      "8": "806.7",
      "9": "860.99",
      "10": "895.9",
      "11": "940.5"
    },
    "principal": {
      "1": "667.82",
      "2": "758.95",
      "3": "852.03",
      "4": "941.23",
      "5": "1028.49",
      "6": "1101.6",
      "7": "1101.6",
      "8": "1101.6",
      "9": "1101.6"
    },
    "hors classe": {
      "1": "1101.6",
      "2": "1101.6",
      "3A1": "1101.6",
      "3A2": "1101.6",
      "3A3": "1101.6"
    },
    "CSTAC": {
      "1": "0",
      "2": "0",
      "3": "1045.94",
      "4": "1101.6",
      "5": "1101.6",
      "6": "1101.6",
      "7": "1101.6"
    },
    "CTAC": {
      "1": "0",
      "2": "0",
      "3": "0",
      "4": "999.4",
      "5": "1067.27",
      "6": "1101.6",
      "7": "1101.6",
      "8": "1101.6"
    },
    "détaché_autre": {
      "1": "1101.6"
    }
  },
  "2019": {
    "normal": {
      "1": "451.21",
      "2": "536.53",
      "3": "563.68",
      "4": "585.01",
      "5": "647.06",
      "6": "705.23",
      "7": "763.41",
      "8": "817.7",
      "9": "871.99",
      "10": "906.9",
      "11": "951.5"
    },
    "principal": {
      "1": "684.82",
      "2": "775.95",
      "3": "869.03",
      "4": "958.23",
      "5": "1045.49",
      "6": "1121.6",
      "7": "1121.6",
      "8": "1121.6",
      "9": "1121.6"
    },
    "hors classe": {
      "1": "1121.6",
      "2": "1121.6",
      "3A1": "1121.6",
      "3A2": "1121.6",
      "3A3": "1121.6"
    },
    "CSTAC": {
      "1": "0",
      "2": "0",
      "3": "1062.94",
      "4": "1121.6",
      "5": "1121.6",
      "6": "1121.6",
      "7": "1121.6"
    },
    "CTAC": {
      "1": "0",
      "2": "0",
      "3": "0",
      "4": "1016.4",
      "5": "1084.27",
      "6": "1121.6",
      "7": "1121.6",
      "8": "1121.6"
    },
    "détaché_autre": {
      "1": "1121.6"
    }
  },
  "2023": {
    "normal": {
      "1": "467",
      "2": "555.31",
      "3": "583.41",
      "4": "605.49",
      "5": "669.71",
      "6": "729.91",
      "7": "790.13",
      "8": "846.32",
      "9": "902.51",
      "10": "938.64",
      "11": "984.8"
    },
    "principal": {
      "1": "708.79",
      "2": "803.11",
      "3": "899.45",
      "4": "991.77",
      "5": "1082.08",
      "6": "1160.86",
      "7": "1160.86",
      "8": "1160.86",
      "9": "1160.86"
    },
    "hors classe": {
      "1": "1160.86",
      "2": "1160.86",
      "3A1": "1160.86",
      "3A2": "1160.86",
      "3A3": "1160.86"
    },
    "CSTAC": {
      "1": "0",
      "2": "0",
      "3": "1100.14",
      "4": "1160.86",
      "5": "1160.86",
      "6": "1160.86",
      "7": "1160.86"
    },
    "CTAC": {
      "1": "0",
      "2": "0",
      "3": "0",
      "4": "1051.97",
      "5": "1122.22",
      "6": "1160.86",
      "7": "1160.86",
      "8": "1160.86"
    },
    "détaché_autre": {
      "1": "1160.86"
    }
  },
  "2024": {
    "normal": {
      "1": "474.01",
      "2": "563.64",
      "3": "592.16",
      "4": "614.57",
      "5": "679.76",
      "6": "740.86",
      "7": "801.98",
      "8": "859.01",
      "9": "916.05",
      "10": "952.72",
      "11": "999.57"
    },
    "principal": {
      "1": "719.42",
      "2": "815.16",
      "3": "912.94",
      "4": "1006.65",
      "5": "1098.31",
      "6": "1178.27",
      "7": "1178.27",
      "8": "1178.27",
      "9": "1178.27"
    },
    "hors classe": {
      "1": "1178.27",
      "2": "1178.27",
      "3A1": "1178.27",
      "3A2": "1178.27",
      "3A3": "1178.27"
    },
    "CSTAC": {
      "1": "0",
      "2": "0",
      "3": "1116.64",
      "4": "1178.27",
      "5": "1178.27",
      "6": "1178.27",
      "7": "1178.27"
    },
    "CTAC": {
      "1": "0",
      "2": "0",
      "3": "0",
      "4": "1067.75",
      "5": "1139.05",
      "6": "1178.27",
      "7": "1178.27",
      "8": "1178.27"
    },
    "détaché_autre": {
      "1": "1178.27"
    }
  }
}
var evsLevel = [
  {
    "name": "Adjoint à la DFPV de l‘ENAC",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoint au chef de domaine à la DSI",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoint au chef de l’organisme de Roissy",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoint au chef de la mission « Evaluation et Amélioration de la Sécurité (MEAS) à la DSAC",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoint au chef de la MALGH",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoint au chef de pôle Ciel Unique à la DTA/MCU",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoint au chef du CESNAC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoint au chef du SIA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoint au directeur du BEA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Adjoint au DSI",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoints au chef d’organisme classés dans les groupes D et E",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Adjoints au chef de bureau",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints au chef de département du SNIA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints au chef de pôle à la DTI à l’exception des pôles classés au niveau 14",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Adjoints au chef de salle en charge de l‘ATFCM",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Adjoints au chef des services Orly-Aviation générale et circulation aérienne Le Bourget",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Adjoints au directeur d‘aérodrome ou au chef d‘organisme classés dans les groupes D et E",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Adjoints au secrétaire général de l’ENAC",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoints aux chefs d’antennes du SNIA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs de département de l’ENAC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs de département de la DSNA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs de division",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Adjoints aux chefs de domaine à la DTI",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoints aux chefs de mission de la DSNA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs de pôle classés au niveau 14",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs de pôle à DSNA/EC, à la DO/EC, dans les sièges des SNA, et dans les organismes classés dans le groupe A",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Adjoints aux chefs de pôle de la DO, à l’exception de ceux classés au niveau 11",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Adjoints aux chefs de pôle de la DO, à l’exception de ceux classés au niveau 12",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Adjoints aux chefs des services d’exploitation dans les SNA et dans les organismes du groupe A",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs des services techniques dans les SNA et dans les organismes du groupe A",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Adjoints aux chefs des SNA Centre-Est, Sud-Est, Sud-Sud-Est et OI",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoints aux chefs du CRNA",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoints aux délégués en DSAC/IR",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Adjoints aux directeurs des directions interrégionales de l’aviation civile",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Adjoints aux directeurs techniques et au DGR de la DSAC/EC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Adjoints aux sous-directeurs ou aux chefs de mission DTA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Agents d‘exploitation",
    "evs": "1",
    "rsi": "-1"
  },
  {
    "name": "Agents des bureaux d‘information aéronautique et des bureaux de piste autres que ceux de Paris - CDG",
    "evs": "3",
    "rsi": "-1"
  },
  {
    "name": "Agents des BTIV des CRNA",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Agents des bureaux régionaux d‘information et d‘assistance aux vols (BRIA). ",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "Agents du BNIA de Bordeaux",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Agents vigie trafic et sol de l‘aérodrome de Paris - CDG",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Assistants de subdivision affectés à DO/EC, dans les sièges des DSAC/IR, dans les sièges des SEAC et de la DAC/NC, dans les sièges des SNA, dans les organismes classés dans le groupe A, à l‘ENAC, au BEA, et au STAC",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Assistants de subdivision et les experts confirmés, à l‘exception de ceux classés au niveau 11 ci-dessous",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Assistants techniques",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Chargé de corps des IEEAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chargés de communication",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chargés de mission conseil technique et défense, qualité, communication et développement durable au SNIA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chargés de projet et les chargés d‘affaires",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Chef de la division « transport aérien de Roissy » de la DSAC/N",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chef de la DSI",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chef de la mission aéroport Grand-Ouest",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chef de laboratoire de recherche de l’ENAC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chef de pôle « Ciel Unique » à la DTA/MCU",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chef de pôle à la DSI",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chef de service sécurité de la DSAC/NC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chef du BNIA et ses adjoints et les chefs des BRIA et leur adjoint",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Chef du centre de contrôle de Cayenne Félix Eboué",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chef du CESNAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chef du DAC/NC et le chef du SNA SEAC/PF",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chef du DSNA/SPM (Saint-Pierre-et-Miquelon)",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chef du service de gestion de la taxe d’aéroport (SGTA)",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chef du SIA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chef du SMQS de la DTI",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs d‘équipe dans les CRNA et les chefs de quart dans les organismes d‘approche classés dans le groupe A ou B",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Chefs d’antenne Atlantique et Méditerranée du SNIA",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de bureau",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de cabinet en DSAC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de centrales énergie dans les CRNA",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "Chefs de centre de l’ENAC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de DCC",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Chefs de département à l’ENAC, au STAC, au BEA, à la DSNA",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de département des DSAC/IR",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de département du SNIA",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de division des aérodromes B, C, D et E et dans les délégations en DSAC/IR",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs de division, à l’exception de ceux classés au niveau 12 ou 14",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de domaine à la DSI",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de domaine de la DTI",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chefs de l‘approche de CDG",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Chefs de l’organisme d‘Orly, de Roissy et des SNA/RP",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chefs de la CA dans les organismes classés dans le groupe F ou G",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Adjoints aux chefs de la CA dans les organismes classés dans le groupe F ou G",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Chefs de la CA dans les organismes classés dans le groupe E",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Adjoints aux chefs de la CA dans les organismes classés dans le groupe E",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Chefs de la CA dans les organismes classés dans le groupe D",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Adjoints aux chefs de la CA dans les organismes classés dans le groupe D",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Chefs de maintenance locale en horaire programmé",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Chefs de maintenance locale en horaire de bureau",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Chefs de maintenance régionale",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs de mission de la DSNA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chefs de mission DSAC/EC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chefs de mission du SG",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chefs de pôle de la DO à l’exception de ceux classés au niveau 13.",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs de pôle à la DSNA/EC, à la DO/EC, dans les sièges des SNA, dans les organismes classés dans le groupe A",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de pôle à la DTI sauf ceux classés au niveau 14",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de pôle de l’ENAC",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de pôle de la DSAC",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs de programme",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs de projet construction aéronautique à la sous-direction de la construction aéronautique de la DTA (DTA/SDC)",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs de projet",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs de quart dans les organismes classés dans le groupe C",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Chefs de quart vigie trafic et sol de l‘aérodrome de Paris - CDG",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "Chefs de salle dans les CRNA",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Chefs de tour dans les organismes d‘approche classés dans le groupe A ou B",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Chefs de section au CESNAC et à l‘ENAC",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Chefs d‘équipe au CESNAC et à l‘ENAC",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Chefs de subdivision, à l‘exception de ceux classés au niveau 12 ci-dessous",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Chefs de subdivision, de la DO/EC, de la DSAC, dans les sièges des DSAC/IR, dans les sièges des SEAC et de la DAC/NC, dans les sièges des SNA, dans les organismes classés dans les groupes A, à l‘ENAC et au STAC",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs de tour dans les organismes classés dans le groupe C",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Chefs de tour, les chefs de quart instruction (CDQI) et les chefs de quart experts dans les organismes classés dans le groupe E",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "Chefs de tour, les CDQI et les chefs de quart experts des organismes classés dans le groupe D",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Chefs des BTIV des CRNA et leurs adjoints",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "Chefs des bureaux exécutifs permanents des CRNA",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs des CRNA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Chefs des organismes de contrôle de la circulation aérienne classés dans les groupes D et E",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Chefs des organismes de la CA dans le groupe B ou C",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs des pôles consolidation des exigences opérationnelles, exigences systèmes et architecture, vol et information générale, outils du contrôleur et surveillance, communication vocal et liaisons de données air-sol, capteurs de surveillance, infrastructure de télécommunication et réseaux, intégration, validation et déploiement, équipements techniques communs et installations de la DTI",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs des services exploitation des SNA et dans les organismes du groupe A",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs des services Orly-AG et CA du Bourget",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Chefs des services techniques dans les SNA et dans les organismes du groupe A",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Chefs des SNA, à l‘exception des SNA du SEAC/PF et de la DAC/NC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Concepteurs de procédures en formation initiale",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Concepteurs de procédures ayant effectué la formation initiale et justifiant de six mois d‘activité en qualité de concepteur de procédures",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Concepteurs de procédures confirmés justifiant de 18 mois d‘activité en qualité de concepteur de procédures",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Concepteurs de procédures experts justifiant de dix-huit mois d‘activité en qualité de concepteurs de procédures confirmés",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Conseillers du directeur de la DTI",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Conseillers mobilité-carrière du SG",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Conseillers techniques au cabinet du directeur général de l’aviation civile",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Contrôleurs d‘aérodrome chargés des fonctions d‘examinateur, d‘évaluateur-contrôleurs ou de facilitateur facteurs humains affectés dans les organismes classés dans les groupes F et G",
    "evs": "4",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs d‘aérodrome dans les organismes classés dans les groupes F et G",
    "evs": "3",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs d‘aérodrome TSEEAC dans les organismes classés dans les groupes D et E dont le déclassement en groupe F et G est prévu par arrêté du ministre chargé de l‘aviation civile",
    "evs": "3",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs multi systèmes au CESNAC",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs systèmes au CESNAC et à l‘ENAC",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs techniques d‘exploitation (CTE) en formation",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs techniques d‘exploitation (CTE) qualifiés depuis moins de trois ans",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs techniques d‘exploitation (CTE) qualifiés depuis plus de trois ans",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Coordinateurs navigation aérienne de l’aérodrome de Paris-Orly",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Coordonnateurs d‘exploitation détachés au CESNAC",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Coordonnateurs dans les détachements civils de coordination (DCC)",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Coordonnateurs de site à la DTI",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Délégués en DSAC/IR",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Directeur adjoint de l’ENAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeur de cabinet de l’ENAC",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Directeur de cabinet DSNA, de la DSAC, du SG et de la DTA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeur de l’aviation civile en Nouvelle Calédonie",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeur de l’international et du développement de l’ENAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeur de la formation au pilotage et des vols de l’ENAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeur des études et de la recherche de l’ENAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeur du SEAC de Wallis et Futuna",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Directeur du SEAC/PF",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeurs adjoints du SNIA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeurs d’enquête BEA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeurs de programme",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Directeurs DSAC/IR",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Electrotechniciens dans les centrales énergie des CRNA",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Enquêteur au BEA",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Enquêteur senior du BEA",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Enquêteur expérimenté au BEA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Enquêteurs au BEA en formation depuis moins d‘un an",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Enquêteurs au BEA en formation depuis plus d‘un an",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Enquêteurs expérimentés seniors du BEA",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Enseignants de l’ENAC",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Enseignants confirmés à l’ENAC",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Enseignants seniors de l’ENAC",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Experts à la DTI",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Experts confirmés à la DTI, à la DO/EC, dans les sièges des SNA et dans les organismes classés dans le groupe A",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Experts internationaux labellisés",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "experts nationaux labellisés",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Experts seniors à la DO/EC, à la DTI, dans les sièges des SNA et dans les organismes classés dans le groupe A",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Experts seniors chargés d’un projet majeur de la DSNA",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Experts seniors de la DO, à l‘exception de ceux classés au niveau 12",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "ICNA affectés à la cellule nationale de gestion de l‘espace aérien (CNGE)",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "ICNA en formation qui ne sont pas titulaires d‘une mention intermédiaire d‘unité",
    "evs": "1",
    "rsi": "-1"
  },
  {
    "name": "ICNA en formation, titulaires d‘une mention intermédiaire d‘unité",
    "evs": "2",
    "rsi": "-1"
  },
  {
    "name": "ICNA en formation, titulaires de deux mentions intermédiaires d‘unité",
    "evs": "3",
    "rsi": "-1"
  },
  {
    "name": "ICNA en formation détenteurs de trois mentions intermédiaires d‘unité",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "ICNA instructeurs de la circulation aérienne à l‘ENAC",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "IESSA chefs de supervision technique (CDST)",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "IESSA dès leur première affectation dans un centre",
    "evs": "1",
    "rsi": "-1"
  },
  {
    "name": "IESSA gestionnaires de ressources techniques temps réel sénior",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "IESSA instructeurs à l‘ENAC",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "IESSA non titulaires de la QTS nommés sur des fonctions de chargé d’instruction ou d’études pour une période comprise 12 et 36 mois responsables de la disponibilité opérationnelle, détachés en maintenance spécialisée (MS), permanentes en MS",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "IESSA remplissant les fonctions de chef de section, les fonctions de chargé d‘instruction ou d‘études pour une période comprise entre 12 et 36 mois ou les fonctions de responsable de la disponibilité opérationnelle",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "IESSA responsables de supervision opérationnelle (RSO) qui ne sont pas titulaires de la QTS prévue aux articles 12 et 13 du décret du 16 janvier 1991",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "IESSA responsables de supervision opérationnelle (RSO), détachés en maintenance spécialisée (DMS), permanents en maintenance spécialisée (PMS), gestionnaires de ressources techniques temps réel",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "IESSA stagiaires recrutés par concours, titulaires d‘une décision favorable du jury de l‘ENAC, attestant de leur réussite aux épreuves de la formation théorique de spécialisation",
    "evs": "2",
    "rsi": "-1"
  },
  {
    "name": "IESSA stagiaires recrutés par examen professionnel affectés depuis neuf mois et ayant suivi au moins quatre modules",
    "evs": "2",
    "rsi": "-1"
  },
  {
    "name": "IESSA superviseurs techniques multi qualifiés qui ne sont pas titulaires de la QTS",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "IESSA titulaires de la QT",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "IESSA titulaires de la QTS",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Ingénieurs chargés d’études",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "IEEAC affectés",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Inspecteurs de la surveillance débutant justifiant moins de 18 mois d‘activité",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Inspecteurs de la surveillance justifiant de 18 mois d‘activité en qualité d‘inspecteur débutant",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Inspecteurs de la surveillance justifiant de 18 mois d‘activité en qualité d‘inspecteur",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Inspecteurs des études de l‘ENAC",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Instructeurs ATSEP",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Instructeurs licence",
    "evs": "11",
    "rsi": "2"
  },
  {
    "name": "Instructeurs régionaux",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Opérateurs de simulateur à l‘ENAC",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "Opérateurs systèmes au CESNAC et à l‘ENAC",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "PC chargés d‘instruction ou d‘études pour une période comprise entre 12 et 36 mois dans les organismes classés dans le groupe A ou B",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "PC chargés d‘instruction ou d‘études pour une période comprise entre 12 et 36 mois dans les organismes classés dans le groupe C",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "PC chargés des fonctions d‘examinateur, d‘évaluateur -contrôleur ou facilitateur facteurs humains et affectés dans les organismes classés dans le groupe D",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "PC chargés des fonctions d‘examinateur, d‘évaluateur-contrôleur ou de facilitateur facteurs humains et affectés dans les organismes classés dans le groupe E",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "PC chargés des fonctions d’examinateur, d’évaluateur et de facilitateur facteurs humains dans les organismes classés dans le groupe A ou B",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "PC chargés des fonctions d’examinateur, d’évaluateur et de facilitateur facteurs humains dans les organismes classés dans le groupe C",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "PC des organismes classés dans le groupe C",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "PC des organismes classés dans le groupe D",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "PC des organismes de contrôle classés dans le groupe E",
    "evs": "5",
    "rsi": "-1"
  },
  {
    "name": "PC en fonctions dans les organismes classés dans les groupes A ou B",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "PC nommés experts opérationnels dans les organismes classés dans le groupe D",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "PC nommés experts opérationnels dans les organismes classés dans le groupe E",
    "evs": "6",
    "rsi": "-1"
  },
  {
    "name": "PC nommés experts opérationnels dans les organismes classés dans les groupe A et B",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "PC nommés experts opérationnels dans les organismes classés en groupe A ou B",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "PC nommés experts opérationnels dans les organismes classés en groupe C",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Référents territoriaux",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Responsable « qualité PPO » de la DSAC/EC",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Responsable du système de management intégré (RSMI) du SNA/OI",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Responsables d’axe de recherche à l‘ENAC",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Responsables d’axe de recherche à l‘ENAC",
    "evs": "12",
    "rsi": "3"
  },
  {
    "name": "Responsables qualité PPO (pilotage par objectif) à l’exception de ceux classés au niveau 14",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "RSMI de la DSNA/DO",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "RSMI des CRNA, du SIA, et des SNA, à l‘exception de ceux classés au niveau 12",
    "evs": "13",
    "rsi": "4"
  },
  {
    "name": "Secrétaire général de l’ENAC",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Secrétaire général du BEA",
    "evs": "15",
    "rsi": "6"
  },
  {
    "name": "Secrétaire général du SNIA",
    "evs": "14",
    "rsi": "5"
  },
  {
    "name": "Spécialistes en formation",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Spécialistes",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Spécialistes confirmés",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Superviseurs systèmes au CESNAC et à l‘ENAC",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Contrôleurs d‘aérodrome TSEEAC affectés et en cours de qualification",
    "evs": "1",
    "rsi": "-1"
  },
  {
    "name": "Opérateur systèmes TSEEAC affectés et en cours de qualification",
    "evs": "1",
    "rsi": "-1"
  },
  {
    "name": "Instructeurs débutants TSEEAC à l‘ENAC justifiant de moins de 18 mois d‘activité",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Instructeurs TSEEAC à l‘ENAC justifiant de 18 mois d‘activité en qualité d‘instructeurs débutants",
    "evs": "9",
    "rsi": "-1"
  },
  {
    "name": "Instructeurs confirmés TSEEAC à l‘ENAC, justifiant de 18 mois d‘activité en qualité d‘instructeur",
    "evs": "10",
    "rsi": "1"
  },
  {
    "name": "Opérateurs TSEEAC de l‘information permanente au service d‘information aéronautique (SIA)",
    "evs": "7",
    "rsi": "-1"
  },
  {
    "name": "Opérateurs TSEEACconfirmés justifiant de 18 mois d‘activité en qualité d‘opérateur de l‘information permanente au SIA",
    "evs": "8",
    "rsi": "-1"
  },
  {
    "name": "Superviseurs TSEEAC de l‘information permanente au SIA justifiant de 18 mois d‘activité en qualité d‘opérateur confirmé",
    "evs": "9",
    "rsi": "-1"
  }
]
var table_indices = {
  "2016-01-01": {
    "100": "203",
    "101": "204",
    "102": "204",
    "103": "205",
    "104": "206",
    "105": "207",
    "106": "207",
    "107": "208",
    "108": "209",
    "109": "210",
    "110": "210",
    "111": "211",
    "112": "212",
    "113": "213",
    "114": "213",
    "115": "214",
    "116": "215",
    "117": "215",
    "118": "216",
    "119": "217",
    "120": "218",
    "121": "219",
    "122": "220",
    "123": "221",
    "124": "222",
    "125": "222",
    "126": "223",
    "127": "224",
    "128": "225",
    "129": "225",
    "130": "226",
    "131": "227",
    "132": "228",
    "133": "228",
    "134": "229",
    "135": "230",
    "136": "230",
    "137": "231",
    "138": "231",
    "139": "231",
    "140": "232",
    "141": "232",
    "142": "233",
    "143": "233",
    "144": "234",
    "145": "234",
    "146": "235",
    "147": "236",
    "148": "236",
    "149": "237",
    "150": "237",
    "151": "238",
    "152": "238",
    "153": "238",
    "154": "239",
    "155": "239",
    "156": "239",
    "157": "240",
    "158": "240",
    "159": "241",
    "160": "241",
    "161": "241",
    "162": "242",
    "163": "242",
    "164": "243",
    "165": "244",
    "166": "244",
    "167": "244",
    "168": "245",
    "169": "245",
    "170": "246",
    "171": "246",
    "172": "246",
    "173": "247",
    "174": "247",
    "175": "247",
    "176": "248",
    "177": "248",
    "178": "248",
    "179": "249",
    "180": "249",
    "181": "250",
    "182": "251",
    "183": "251",
    "184": "252",
    "185": "252",
    "186": "252",
    "187": "253",
    "188": "253",
    "189": "254",
    "190": "255",
    "191": "256",
    "192": "257",
    "193": "258",
    "194": "259",
    "195": "260",
    "196": "261",
    "197": "262",
    "198": "263",
    "199": "264",
    "200": "265",
    "201": "266",
    "202": "267",
    "203": "268",
    "204": "269",
    "205": "270",
    "206": "271",
    "207": "272",
    "208": "273",
    "209": "274",
    "210": "275",
    "211": "276",
    "212": "277",
    "213": "278",
    "214": "279",
    "215": "280",
    "216": "281",
    "217": "282",
    "218": "283",
    "219": "284",
    "220": "285",
    "221": "286",
    "222": "287",
    "223": "288",
    "224": "289",
    "225": "290",
    "226": "291",
    "227": "292",
    "228": "293",
    "229": "294",
    "230": "295",
    "231": "296",
    "232": "297",
    "233": "298",
    "234": "299",
    "235": "300",
    "236": "301",
    "237": "302",
    "238": "303",
    "239": "304",
    "240": "305",
    "241": "306",
    "242": "307",
    "243": "308",
    "244": "309",
    "245": "309",
    "246": "309",
    "247": "309",
    "248": "309",
    "249": "309",
    "250": "309",
    "251": "309",
    "252": "309",
    "253": "309",
    "254": "309",
    "255": "309",
    "256": "309",
    "257": "309",
    "258": "309",
    "259": "309",
    "260": "309",
    "261": "309",
    "262": "309",
    "263": "309",
    "264": "309",
    "265": "309",
    "266": "309",
    "267": "309",
    "268": "309",
    "269": "309",
    "270": "309",
    "271": "309",
    "272": "309",
    "273": "309",
    "274": "309",
    "275": "309",
    "276": "309",
    "277": "309",
    "278": "309",
    "279": "309",
    "280": "309",
    "281": "309",
    "282": "309",
    "283": "309",
    "284": "309",
    "285": "309",
    "286": "309",
    "287": "309",
    "288": "309",
    "289": "309",
    "290": "309",
    "291": "309",
    "292": "309",
    "293": "309",
    "294": "309",
    "295": "309",
    "296": "309",
    "297": "309",
    "298": "310",
    "299": "311",
    "300": "311",
    "301": "311",
    "302": "312",
    "303": "312",
    "304": "312",
    "305": "312",
    "306": "312",
    "307": "313",
    "308": "313",
    "309": "313",
    "310": "313",
    "311": "313",
    "312": "313",
    "313": "313",
    "314": "313",
    "315": "313",
    "316": "313",
    "317": "313",
    "318": "314",
    "319": "314",
    "320": "314",
    "321": "314",
    "322": "314",
    "323": "314",
    "324": "314",
    "325": "314",
    "326": "315",
    "327": "315",
    "328": "315",
    "329": "316",
    "330": "316",
    "331": "316",
    "332": "316",
    "333": "316",
    "334": "317",
    "335": "317",
    "336": "318",
    "337": "319",
    "338": "319",
    "339": "320",
    "340": "321",
    "341": "322",
    "342": "323",
    "343": "324",
    "344": "324",
    "345": "324",
    "346": "324",
    "347": "325",
    "348": "326",
    "349": "327",
    "350": "327",
    "351": "328",
    "352": "329",
    "353": "329",
    "354": "330",
    "355": "331",
    "356": "332",
    "357": "332",
    "358": "333",
    "359": "334",
    "360": "335",
    "361": "335",
    "362": "336",
    "363": "337",
    "364": "338",
    "365": "338",
    "366": "339",
    "367": "340",
    "368": "341",
    "369": "341",
    "370": "342",
    "371": "343",
    "372": "343",
    "373": "344",
    "374": "345",
    "375": "346",
    "376": "346",
    "377": "347",
    "378": "348",
    "379": "349",
    "380": "350",
    "381": "351",
    "382": "352",
    "383": "352",
    "384": "352",
    "385": "353",
    "386": "354",
    "387": "354",
    "388": "355",
    "389": "356",
    "390": "357",
    "391": "357",
    "392": "357",
    "393": "358",
    "394": "359",
    "395": "359",
    "396": "360",
    "397": "361",
    "398": "362",
    "399": "362",
    "400": "363",
    "401": "363",
    "402": "364",
    "403": "364",
    "404": "365",
    "405": "366",
    "406": "366",
    "407": "367",
    "408": "367",
    "409": "368",
    "410": "368",
    "411": "368",
    "412": "368",
    "413": "369",
    "414": "369",
    "415": "369",
    "416": "370",
    "417": "371",
    "418": "371",
    "419": "372",
    "420": "373",
    "421": "374",
    "422": "375",
    "423": "376",
    "424": "377",
    "425": "377",
    "426": "378",
    "427": "379",
    "428": "379",
    "429": "379",
    "430": "380",
    "431": "381",
    "432": "382",
    "433": "382",
    "434": "383",
    "435": "384",
    "436": "384",
    "437": "385",
    "438": "386",
    "439": "387",
    "440": "387",
    "441": "388",
    "442": "389",
    "443": "390",
    "444": "390",
    "445": "391",
    "446": "392",
    "447": "393",
    "448": "393",
    "449": "394",
    "450": "395",
    "451": "396",
    "452": "396",
    "453": "397",
    "454": "398",
    "455": "398",
    "456": "399",
    "457": "400",
    "458": "401",
    "459": "402",
    "460": "403",
    "461": "404",
    "462": "405",
    "463": "405",
    "464": "406",
    "465": "407",
    "466": "408",
    "467": "408",
    "468": "409",
    "469": "410",
    "470": "411",
    "471": "411",
    "472": "412",
    "473": "412",
    "474": "413",
    "475": "413",
    "476": "414",
    "477": "415",
    "478": "415",
    "479": "416",
    "480": "416",
    "481": "417",
    "482": "417",
    "483": "418",
    "484": "419",
    "485": "420",
    "486": "420",
    "487": "421",
    "488": "422",
    "489": "422",
    "490": "423",
    "491": "424",
    "492": "425",
    "493": "425",
    "494": "426",
    "495": "427",
    "496": "428",
    "497": "428",
    "498": "429",
    "499": "430",
    "500": "431",
    "501": "432",
    "502": "433",
    "503": "434",
    "504": "434",
    "505": "435",
    "506": "436",
    "507": "437",
    "508": "437",
    "509": "438",
    "510": "439",
    "511": "440",
    "512": "440",
    "513": "441",
    "514": "442",
    "515": "443",
    "516": "443",
    "517": "444",
    "518": "445",
    "519": "446",
    "520": "446",
    "521": "447",
    "522": "448",
    "523": "448",
    "524": "449",
    "525": "450",
    "526": "451",
    "527": "451",
    "528": "452",
    "529": "453",
    "530": "454",
    "531": "454",
    "532": "455",
    "533": "456",
    "534": "456",
    "535": "456",
    "536": "457",
    "537": "457",
    "538": "457",
    "539": "458",
    "540": "459",
    "541": "460",
    "542": "461",
    "543": "462",
    "544": "463",
    "545": "464",
    "546": "464",
    "547": "465",
    "548": "466",
    "549": "467",
    "550": "467",
    "551": "468",
    "552": "469",
    "553": "469",
    "554": "470",
    "555": "471",
    "556": "472",
    "557": "472",
    "558": "473",
    "559": "474",
    "560": "475",
    "561": "475",
    "562": "476",
    "563": "477",
    "564": "478",
    "565": "478",
    "566": "479",
    "567": "480",
    "568": "481",
    "569": "481",
    "570": "482",
    "571": "483",
    "572": "483",
    "573": "484",
    "574": "485",
    "575": "486",
    "576": "486",
    "577": "487",
    "578": "488",
    "579": "489",
    "580": "490",
    "581": "491",
    "582": "492",
    "583": "493",
    "584": "493",
    "585": "494",
    "586": "495",
    "587": "495",
    "588": "496",
    "589": "497",
    "590": "498",
    "591": "498",
    "592": "499",
    "593": "500",
    "594": "501",
    "595": "501",
    "596": "502",
    "597": "503",
    "598": "504",
    "599": "504",
    "600": "505",
    "601": "506",
    "602": "507",
    "603": "507",
    "604": "508",
    "605": "509",
    "606": "509",
    "607": "510",
    "608": "511",
    "609": "512",
    "610": "512",
    "611": "513",
    "612": "514",
    "613": "515",
    "614": "515",
    "615": "516",
    "616": "517",
    "617": "518",
    "618": "518",
    "619": "519",
    "620": "520",
    "621": "521",
    "622": "522",
    "623": "523",
    "624": "524",
    "625": "524",
    "626": "525",
    "627": "526",
    "628": "527",
    "629": "527",
    "630": "528",
    "631": "529",
    "632": "530",
    "633": "530",
    "634": "531",
    "635": "532",
    "636": "533",
    "637": "533",
    "638": "534",
    "639": "535",
    "640": "535",
    "641": "536",
    "642": "537",
    "643": "538",
    "644": "538",
    "645": "539",
    "646": "540",
    "647": "541",
    "648": "541",
    "649": "542",
    "650": "543",
    "651": "544",
    "652": "544",
    "653": "545",
    "654": "546",
    "655": "546",
    "656": "547",
    "657": "548",
    "658": "549",
    "659": "550",
    "660": "551",
    "661": "552",
    "662": "553",
    "663": "553",
    "664": "554",
    "665": "555",
    "666": "556",
    "667": "556",
    "668": "557",
    "669": "558",
    "670": "559",
    "671": "559",
    "672": "560",
    "673": "561",
    "674": "561",
    "675": "562",
    "676": "563",
    "677": "564",
    "678": "564",
    "679": "565",
    "680": "566",
    "681": "567",
    "682": "567",
    "683": "568",
    "684": "569",
    "685": "570",
    "686": "570",
    "687": "571",
    "688": "572",
    "689": "572",
    "690": "573",
    "691": "574",
    "692": "575",
    "693": "575",
    "694": "576",
    "695": "577",
    "696": "578",
    "697": "578",
    "698": "579",
    "699": "580",
    "700": "581",
    "701": "582",
    "702": "583",
    "703": "584",
    "704": "584",
    "705": "585",
    "706": "586",
    "707": "587",
    "708": "587",
    "709": "588",
    "710": "589",
    "711": "590",
    "712": "590",
    "713": "591",
    "714": "592",
    "715": "593",
    "716": "593",
    "717": "594",
    "718": "595",
    "719": "596",
    "720": "596",
    "721": "597",
    "722": "598",
    "723": "598",
    "724": "599",
    "725": "600",
    "726": "601",
    "727": "601",
    "728": "602",
    "729": "603",
    "730": "604",
    "731": "604",
    "732": "605",
    "733": "606",
    "734": "607",
    "735": "607",
    "736": "608",
    "737": "609",
    "738": "610",
    "739": "610",
    "740": "611",
    "741": "612",
    "742": "613",
    "743": "614",
    "744": "615",
    "745": "616",
    "746": "616",
    "747": "617",
    "748": "618",
    "749": "619",
    "750": "619",
    "751": "620",
    "752": "621",
    "753": "622",
    "754": "622",
    "755": "623",
    "756": "624",
    "757": "624",
    "758": "625",
    "759": "626",
    "760": "627",
    "761": "627",
    "762": "628",
    "763": "629",
    "764": "630",
    "765": "630",
    "766": "631",
    "767": "632",
    "768": "633",
    "769": "633",
    "770": "634",
    "771": "635",
    "772": "635",
    "773": "636",
    "774": "637",
    "775": "638",
    "776": "638",
    "777": "639",
    "778": "640",
    "779": "641",
    "780": "642",
    "781": "643",
    "782": "644",
    "783": "645",
    "784": "645",
    "785": "646",
    "786": "647",
    "787": "648",
    "788": "648",
    "789": "649",
    "790": "650",
    "791": "650",
    "792": "651",
    "793": "652",
    "794": "653",
    "795": "653",
    "796": "654",
    "797": "655",
    "798": "656",
    "799": "656",
    "800": "657",
    "801": "658",
    "802": "659",
    "803": "659",
    "804": "660",
    "805": "661",
    "806": "661",
    "807": "662",
    "808": "663",
    "809": "664",
    "810": "664",
    "811": "665",
    "812": "666",
    "813": "667",
    "814": "667",
    "815": "668",
    "816": "669",
    "817": "670",
    "818": "670",
    "819": "671",
    "820": "672",
    "821": "673",
    "822": "674",
    "823": "675",
    "824": "676",
    "825": "676",
    "826": "677",
    "827": "678",
    "828": "679",
    "829": "679",
    "830": "680",
    "831": "681",
    "832": "682",
    "833": "682",
    "834": "683",
    "835": "684",
    "836": "685",
    "837": "685",
    "838": "686",
    "839": "687",
    "840": "687",
    "841": "688",
    "842": "689",
    "843": "690",
    "844": "690",
    "845": "691",
    "846": "692",
    "847": "693",
    "848": "693",
    "849": "694",
    "850": "695",
    "851": "696",
    "852": "696",
    "853": "697",
    "854": "698",
    "855": "699",
    "856": "699",
    "857": "700",
    "858": "701",
    "859": "702",
    "860": "703",
    "861": "704",
    "862": "705",
    "863": "705",
    "864": "706",
    "865": "707",
    "866": "708",
    "867": "708",
    "868": "709",
    "869": "710",
    "870": "711",
    "871": "711",
    "872": "712",
    "873": "713",
    "874": "713",
    "875": "714",
    "876": "715",
    "877": "716",
    "878": "716",
    "879": "717",
    "880": "718",
    "881": "719",
    "882": "719",
    "883": "720",
    "884": "721",
    "885": "722",
    "886": "722",
    "887": "723",
    "888": "724",
    "889": "725",
    "890": "725",
    "891": "726",
    "892": "727",
    "893": "727",
    "894": "728",
    "895": "729",
    "896": "730",
    "897": "730",
    "898": "731",
    "899": "732",
    "900": "733",
    "901": "734",
    "902": "735",
    "903": "735",
    "904": "736",
    "905": "737",
    "906": "738",
    "907": "739",
    "908": "739",
    "909": "740",
    "910": "741",
    "911": "742",
    "912": "743",
    "913": "743",
    "914": "744",
    "915": "745",
    "916": "746",
    "917": "747",
    "918": "747",
    "919": "748",
    "920": "749",
    "921": "750",
    "922": "750",
    "923": "751",
    "924": "751",
    "925": "752",
    "926": "753",
    "927": "754",
    "928": "754",
    "929": "755",
    "930": "756",
    "931": "757",
    "932": "758",
    "933": "758",
    "934": "759",
    "935": "760",
    "936": "761",
    "937": "762",
    "938": "762",
    "939": "763",
    "940": "764",
    "941": "765",
    "942": "765",
    "943": "766",
    "944": "766",
    "945": "767",
    "946": "768",
    "947": "769",
    "948": "769",
    "949": "770",
    "950": "771",
    "951": "772",
    "952": "772",
    "953": "773",
    "954": "773",
    "955": "774",
    "956": "775",
    "957": "776",
    "958": "776",
    "959": "777",
    "960": "778",
    "961": "779",
    "962": "780",
    "963": "780",
    "964": "781",
    "965": "782",
    "966": "783",
    "967": "784",
    "968": "784",
    "969": "785",
    "970": "786",
    "971": "787",
    "972": "788",
    "973": "788",
    "974": "789",
    "975": "790",
    "976": "791",
    "977": "792",
    "978": "792",
    "979": "793",
    "980": "794",
    "981": "795",
    "982": "796",
    "983": "796",
    "984": "797",
    "985": "798",
    "986": "799",
    "987": "800",
    "988": "800",
    "989": "801",
    "990": "802",
    "991": "803",
    "992": "804",
    "993": "804",
    "994": "805",
    "995": "806",
    "996": "807",
    "997": "807",
    "998": "808",
    "999": "808",
    "1000": "809",
    "1001": "810",
    "1002": "811",
    "1003": "811",
    "1004": "812",
    "1005": "813",
    "1006": "814",
    "1007": "814",
    "1008": "815",
    "1009": "816",
    "1010": "817",
    "1011": "817",
    "1012": "818",
    "1013": "819",
    "1014": "820",
    "1015": "821",
    "1016": "821",
    "1017": "822",
    "1018": "823",
    "1019": "823",
    "1020": "824",
    "1021": "825",
    "1022": "826",
    "1023": "827",
    "1024": "827",
    "1025": "828",
    "1026": "829",
    "1027": "830",
    "HEA1": "881",
    "HEA2": "916",
    "HEA3": "963",
    "HEB1": "963",
    "HEB2": "1004",
    "HEB3": "1058",
    "HEBB1": "1058",
    "HEBB2": "1086",
    "HEBB3": "1115"
  },
  "2017-01-01": {
    "100": "203",
    "101": "204",
    "102": "204",
    "103": "205",
    "104": "206",
    "105": "207",
    "106": "207",
    "107": "208",
    "108": "209",
    "109": "210",
    "110": "210",
    "111": "211",
    "112": "212",
    "113": "213",
    "114": "213",
    "115": "214",
    "116": "215",
    "117": "215",
    "118": "216",
    "119": "217",
    "120": "218",
    "121": "219",
    "122": "220",
    "123": "221",
    "124": "222",
    "125": "222",
    "126": "223",
    "127": "224",
    "128": "225",
    "129": "225",
    "130": "226",
    "131": "227",
    "132": "228",
    "133": "228",
    "134": "229",
    "135": "230",
    "136": "230",
    "137": "231",
    "138": "231",
    "139": "231",
    "140": "232",
    "141": "232",
    "142": "233",
    "143": "233",
    "144": "234",
    "145": "234",
    "146": "235",
    "147": "236",
    "148": "236",
    "149": "237",
    "150": "237",
    "151": "238",
    "152": "238",
    "153": "238",
    "154": "239",
    "155": "239",
    "156": "239",
    "157": "240",
    "158": "240",
    "159": "241",
    "160": "241",
    "161": "241",
    "162": "242",
    "163": "242",
    "164": "243",
    "165": "244",
    "166": "244",
    "167": "244",
    "168": "245",
    "169": "245",
    "170": "246",
    "171": "246",
    "172": "246",
    "173": "247",
    "174": "247",
    "175": "247",
    "176": "248",
    "177": "248",
    "178": "248",
    "179": "249",
    "180": "249",
    "181": "250",
    "182": "251",
    "183": "251",
    "184": "252",
    "185": "252",
    "186": "252",
    "187": "253",
    "188": "253",
    "189": "254",
    "190": "255",
    "191": "256",
    "192": "257",
    "193": "258",
    "194": "259",
    "195": "260",
    "196": "261",
    "197": "262",
    "198": "263",
    "199": "264",
    "200": "265",
    "201": "266",
    "202": "267",
    "203": "268",
    "204": "269",
    "205": "270",
    "206": "271",
    "207": "272",
    "208": "273",
    "209": "274",
    "210": "275",
    "211": "276",
    "212": "277",
    "213": "278",
    "214": "279",
    "215": "280",
    "216": "281",
    "217": "282",
    "218": "283",
    "219": "284",
    "220": "285",
    "221": "286",
    "222": "287",
    "223": "288",
    "224": "289",
    "225": "290",
    "226": "291",
    "227": "292",
    "228": "293",
    "229": "294",
    "230": "295",
    "231": "296",
    "232": "297",
    "233": "298",
    "234": "299",
    "235": "300",
    "236": "301",
    "237": "302",
    "238": "303",
    "239": "304",
    "240": "305",
    "241": "306",
    "242": "307",
    "243": "308",
    "244": "309",
    "245": "309",
    "246": "309",
    "247": "309",
    "248": "309",
    "249": "309",
    "250": "309",
    "251": "309",
    "252": "309",
    "253": "309",
    "254": "309",
    "255": "309",
    "256": "309",
    "257": "309",
    "258": "309",
    "259": "309",
    "260": "309",
    "261": "309",
    "262": "309",
    "263": "309",
    "264": "309",
    "265": "309",
    "266": "309",
    "267": "309",
    "268": "309",
    "269": "309",
    "270": "309",
    "271": "309",
    "272": "309",
    "273": "309",
    "274": "309",
    "275": "309",
    "276": "309",
    "277": "309",
    "278": "309",
    "279": "309",
    "280": "309",
    "281": "309",
    "282": "309",
    "283": "309",
    "284": "309",
    "285": "309",
    "286": "309",
    "287": "309",
    "288": "309",
    "289": "309",
    "290": "309",
    "291": "309",
    "292": "309",
    "293": "309",
    "294": "309",
    "295": "309",
    "296": "309",
    "297": "309",
    "298": "310",
    "299": "311",
    "300": "311",
    "301": "311",
    "302": "312",
    "303": "312",
    "304": "312",
    "305": "312",
    "306": "312",
    "307": "313",
    "308": "313",
    "309": "313",
    "310": "313",
    "311": "313",
    "312": "313",
    "313": "313",
    "314": "313",
    "315": "313",
    "316": "313",
    "317": "313",
    "318": "314",
    "319": "314",
    "320": "314",
    "321": "314",
    "322": "314",
    "323": "314",
    "324": "314",
    "325": "314",
    "326": "315",
    "327": "315",
    "328": "315",
    "329": "316",
    "330": "316",
    "331": "316",
    "332": "316",
    "333": "316",
    "334": "317",
    "335": "317",
    "336": "318",
    "337": "319",
    "338": "319",
    "339": "320",
    "340": "321",
    "341": "322",
    "342": "323",
    "343": "324",
    "344": "324",
    "345": "324",
    "346": "324",
    "347": "325",
    "348": "326",
    "349": "327",
    "350": "327",
    "351": "328",
    "352": "329",
    "353": "329",
    "354": "330",
    "355": "331",
    "356": "332",
    "357": "332",
    "358": "333",
    "359": "334",
    "360": "335",
    "361": "335",
    "362": "336",
    "363": "337",
    "364": "338",
    "365": "338",
    "366": "339",
    "367": "340",
    "368": "341",
    "369": "341",
    "370": "342",
    "371": "343",
    "372": "343",
    "373": "344",
    "374": "345",
    "375": "346",
    "376": "346",
    "377": "347",
    "378": "348",
    "379": "349",
    "380": "350",
    "381": "351",
    "382": "352",
    "383": "352",
    "384": "352",
    "385": "353",
    "386": "354",
    "387": "354",
    "388": "355",
    "389": "356",
    "390": "357",
    "391": "357",
    "392": "357",
    "393": "358",
    "394": "359",
    "395": "359",
    "396": "360",
    "397": "361",
    "398": "362",
    "399": "362",
    "400": "363",
    "401": "363",
    "402": "364",
    "403": "364",
    "404": "365",
    "405": "366",
    "406": "366",
    "407": "367",
    "408": "367",
    "409": "368",
    "410": "368",
    "411": "368",
    "412": "368",
    "413": "369",
    "414": "369",
    "415": "369",
    "416": "370",
    "417": "371",
    "418": "371",
    "419": "372",
    "420": "373",
    "421": "374",
    "422": "375",
    "423": "376",
    "424": "377",
    "425": "377",
    "426": "378",
    "427": "379",
    "428": "379",
    "429": "379",
    "430": "380",
    "431": "381",
    "432": "382",
    "433": "382",
    "434": "383",
    "435": "384",
    "436": "384",
    "437": "385",
    "438": "386",
    "439": "387",
    "440": "387",
    "441": "388",
    "442": "389",
    "443": "390",
    "444": "390",
    "445": "391",
    "446": "392",
    "447": "393",
    "448": "393",
    "449": "394",
    "450": "395",
    "451": "396",
    "452": "396",
    "453": "397",
    "454": "398",
    "455": "398",
    "456": "399",
    "457": "400",
    "458": "401",
    "459": "402",
    "460": "403",
    "461": "404",
    "462": "405",
    "463": "405",
    "464": "406",
    "465": "407",
    "466": "408",
    "467": "408",
    "468": "409",
    "469": "410",
    "470": "411",
    "471": "411",
    "472": "412",
    "473": "412",
    "474": "413",
    "475": "413",
    "476": "414",
    "477": "415",
    "478": "415",
    "479": "416",
    "480": "416",
    "481": "417",
    "482": "417",
    "483": "418",
    "484": "419",
    "485": "420",
    "486": "420",
    "487": "421",
    "488": "422",
    "489": "422",
    "490": "423",
    "491": "424",
    "492": "425",
    "493": "425",
    "494": "426",
    "495": "427",
    "496": "428",
    "497": "428",
    "498": "429",
    "499": "430",
    "500": "431",
    "501": "432",
    "502": "433",
    "503": "434",
    "504": "434",
    "505": "435",
    "506": "436",
    "507": "437",
    "508": "437",
    "509": "438",
    "510": "439",
    "511": "440",
    "512": "440",
    "513": "441",
    "514": "442",
    "515": "443",
    "516": "443",
    "517": "444",
    "518": "445",
    "519": "446",
    "520": "446",
    "521": "447",
    "522": "448",
    "523": "448",
    "524": "449",
    "525": "450",
    "526": "451",
    "527": "451",
    "528": "452",
    "529": "453",
    "530": "454",
    "531": "454",
    "532": "455",
    "533": "456",
    "534": "456",
    "535": "456",
    "536": "457",
    "537": "457",
    "538": "457",
    "539": "458",
    "540": "459",
    "541": "460",
    "542": "461",
    "543": "462",
    "544": "463",
    "545": "464",
    "546": "464",
    "547": "465",
    "548": "466",
    "549": "467",
    "550": "467",
    "551": "468",
    "552": "469",
    "553": "469",
    "554": "470",
    "555": "471",
    "556": "472",
    "557": "472",
    "558": "473",
    "559": "474",
    "560": "475",
    "561": "475",
    "562": "476",
    "563": "477",
    "564": "478",
    "565": "478",
    "566": "479",
    "567": "480",
    "568": "481",
    "569": "481",
    "570": "482",
    "571": "483",
    "572": "483",
    "573": "484",
    "574": "485",
    "575": "486",
    "576": "486",
    "577": "487",
    "578": "488",
    "579": "489",
    "580": "490",
    "581": "491",
    "582": "492",
    "583": "493",
    "584": "493",
    "585": "494",
    "586": "495",
    "587": "495",
    "588": "496",
    "589": "497",
    "590": "498",
    "591": "498",
    "592": "499",
    "593": "500",
    "594": "501",
    "595": "501",
    "596": "502",
    "597": "503",
    "598": "504",
    "599": "504",
    "600": "505",
    "601": "506",
    "602": "507",
    "603": "507",
    "604": "508",
    "605": "509",
    "606": "509",
    "607": "510",
    "608": "511",
    "609": "512",
    "610": "512",
    "611": "513",
    "612": "514",
    "613": "515",
    "614": "515",
    "615": "516",
    "616": "517",
    "617": "518",
    "618": "518",
    "619": "519",
    "620": "520",
    "621": "521",
    "622": "522",
    "623": "523",
    "624": "524",
    "625": "524",
    "626": "525",
    "627": "526",
    "628": "527",
    "629": "527",
    "630": "528",
    "631": "529",
    "632": "530",
    "633": "530",
    "634": "531",
    "635": "532",
    "636": "533",
    "637": "533",
    "638": "534",
    "639": "535",
    "640": "535",
    "641": "536",
    "642": "537",
    "643": "538",
    "644": "538",
    "645": "539",
    "646": "540",
    "647": "541",
    "648": "541",
    "649": "542",
    "650": "543",
    "651": "544",
    "652": "544",
    "653": "545",
    "654": "546",
    "655": "546",
    "656": "547",
    "657": "548",
    "658": "549",
    "659": "550",
    "660": "551",
    "661": "552",
    "662": "553",
    "663": "553",
    "664": "554",
    "665": "555",
    "666": "556",
    "667": "556",
    "668": "557",
    "669": "558",
    "670": "559",
    "671": "559",
    "672": "560",
    "673": "561",
    "674": "561",
    "675": "562",
    "676": "563",
    "677": "564",
    "678": "564",
    "679": "565",
    "680": "566",
    "681": "567",
    "682": "567",
    "683": "568",
    "684": "569",
    "685": "570",
    "686": "570",
    "687": "571",
    "688": "572",
    "689": "572",
    "690": "573",
    "691": "574",
    "692": "575",
    "693": "575",
    "694": "576",
    "695": "577",
    "696": "578",
    "697": "578",
    "698": "579",
    "699": "580",
    "700": "581",
    "701": "582",
    "702": "583",
    "703": "584",
    "704": "584",
    "705": "585",
    "706": "586",
    "707": "587",
    "708": "587",
    "709": "588",
    "710": "589",
    "711": "590",
    "712": "590",
    "713": "591",
    "714": "592",
    "715": "593",
    "716": "593",
    "717": "594",
    "718": "595",
    "719": "596",
    "720": "596",
    "721": "597",
    "722": "598",
    "723": "598",
    "724": "599",
    "725": "600",
    "726": "601",
    "727": "601",
    "728": "602",
    "729": "603",
    "730": "604",
    "731": "604",
    "732": "605",
    "733": "606",
    "734": "607",
    "735": "607",
    "736": "608",
    "737": "609",
    "738": "610",
    "739": "610",
    "740": "611",
    "741": "612",
    "742": "613",
    "743": "614",
    "744": "615",
    "745": "616",
    "746": "616",
    "747": "617",
    "748": "618",
    "749": "619",
    "750": "619",
    "751": "620",
    "752": "621",
    "753": "622",
    "754": "622",
    "755": "623",
    "756": "624",
    "757": "624",
    "758": "625",
    "759": "626",
    "760": "627",
    "761": "627",
    "762": "628",
    "763": "629",
    "764": "630",
    "765": "630",
    "766": "631",
    "767": "632",
    "768": "633",
    "769": "633",
    "770": "634",
    "771": "635",
    "772": "635",
    "773": "636",
    "774": "637",
    "775": "638",
    "776": "638",
    "777": "639",
    "778": "640",
    "779": "641",
    "780": "642",
    "781": "643",
    "782": "644",
    "783": "645",
    "784": "645",
    "785": "646",
    "786": "647",
    "787": "648",
    "788": "648",
    "789": "649",
    "790": "650",
    "791": "650",
    "792": "651",
    "793": "652",
    "794": "653",
    "795": "653",
    "796": "654",
    "797": "655",
    "798": "656",
    "799": "656",
    "800": "657",
    "801": "658",
    "802": "659",
    "803": "659",
    "804": "660",
    "805": "661",
    "806": "661",
    "807": "662",
    "808": "663",
    "809": "664",
    "810": "664",
    "811": "665",
    "812": "666",
    "813": "667",
    "814": "667",
    "815": "668",
    "816": "669",
    "817": "670",
    "818": "670",
    "819": "671",
    "820": "672",
    "821": "673",
    "822": "674",
    "823": "675",
    "824": "676",
    "825": "676",
    "826": "677",
    "827": "678",
    "828": "679",
    "829": "679",
    "830": "680",
    "831": "681",
    "832": "682",
    "833": "682",
    "834": "683",
    "835": "684",
    "836": "685",
    "837": "685",
    "838": "686",
    "839": "687",
    "840": "687",
    "841": "688",
    "842": "689",
    "843": "690",
    "844": "690",
    "845": "691",
    "846": "692",
    "847": "693",
    "848": "693",
    "849": "694",
    "850": "695",
    "851": "696",
    "852": "696",
    "853": "697",
    "854": "698",
    "855": "699",
    "856": "699",
    "857": "700",
    "858": "701",
    "859": "702",
    "860": "703",
    "861": "704",
    "862": "705",
    "863": "705",
    "864": "706",
    "865": "707",
    "866": "708",
    "867": "708",
    "868": "709",
    "869": "710",
    "870": "711",
    "871": "711",
    "872": "712",
    "873": "713",
    "874": "713",
    "875": "714",
    "876": "715",
    "877": "716",
    "878": "716",
    "879": "717",
    "880": "718",
    "881": "719",
    "882": "719",
    "883": "720",
    "884": "721",
    "885": "722",
    "886": "722",
    "887": "723",
    "888": "724",
    "889": "725",
    "890": "725",
    "891": "726",
    "892": "727",
    "893": "727",
    "894": "728",
    "895": "729",
    "896": "730",
    "897": "730",
    "898": "731",
    "899": "732",
    "900": "733",
    "901": "734",
    "902": "735",
    "903": "735",
    "904": "736",
    "905": "737",
    "906": "738",
    "907": "739",
    "908": "739",
    "909": "740",
    "910": "741",
    "911": "742",
    "912": "743",
    "913": "743",
    "914": "744",
    "915": "745",
    "916": "746",
    "917": "747",
    "918": "747",
    "919": "748",
    "920": "749",
    "921": "750",
    "922": "750",
    "923": "751",
    "924": "751",
    "925": "752",
    "926": "753",
    "927": "754",
    "928": "754",
    "929": "755",
    "930": "756",
    "931": "757",
    "932": "758",
    "933": "758",
    "934": "759",
    "935": "760",
    "936": "761",
    "937": "762",
    "938": "762",
    "939": "763",
    "940": "764",
    "941": "765",
    "942": "765",
    "943": "766",
    "944": "766",
    "945": "767",
    "946": "768",
    "947": "769",
    "948": "769",
    "949": "770",
    "950": "771",
    "951": "772",
    "952": "772",
    "953": "773",
    "954": "773",
    "955": "774",
    "956": "775",
    "957": "776",
    "958": "776",
    "959": "777",
    "960": "778",
    "961": "779",
    "962": "780",
    "963": "780",
    "964": "781",
    "965": "782",
    "966": "783",
    "967": "784",
    "968": "784",
    "969": "785",
    "970": "786",
    "971": "787",
    "972": "788",
    "973": "788",
    "974": "789",
    "975": "790",
    "976": "791",
    "977": "792",
    "978": "792",
    "979": "793",
    "980": "794",
    "981": "795",
    "982": "796",
    "983": "796",
    "984": "797",
    "985": "798",
    "986": "799",
    "987": "800",
    "988": "800",
    "989": "801",
    "990": "802",
    "991": "803",
    "992": "804",
    "993": "804",
    "994": "805",
    "995": "806",
    "996": "807",
    "997": "807",
    "998": "808",
    "999": "808",
    "1000": "809",
    "1001": "810",
    "1002": "811",
    "1003": "811",
    "1004": "812",
    "1005": "813",
    "1006": "814",
    "1007": "814",
    "1008": "815",
    "1009": "816",
    "1010": "817",
    "1011": "817",
    "1012": "818",
    "1013": "819",
    "1014": "820",
    "1015": "821",
    "1016": "821",
    "1017": "822",
    "1018": "823",
    "1019": "823",
    "1020": "824",
    "1021": "825",
    "1022": "826",
    "1023": "827",
    "1024": "827",
    "1025": "828",
    "1026": "829",
    "1027": "830",
    "HEA1": "885",
    "HEA2": "920",
    "HEA3": "967",
    "HEB1": "967",
    "HEB2": "1008",
    "HEB3": "1062",
    "HEBB1": "1062",
    "HEBB2": "1090",
    "HEBB3": "1119"
  },
  "2018-01-01": {
    "100": "203",
    "101": "204",
    "102": "204",
    "103": "205",
    "104": "206",
    "105": "207",
    "106": "207",
    "107": "208",
    "108": "209",
    "109": "210",
    "110": "210",
    "111": "211",
    "112": "212",
    "113": "213",
    "114": "213",
    "115": "214",
    "116": "215",
    "117": "215",
    "118": "216",
    "119": "217",
    "120": "218",
    "121": "219",
    "122": "220",
    "123": "221",
    "124": "222",
    "125": "222",
    "126": "223",
    "127": "224",
    "128": "225",
    "129": "225",
    "130": "226",
    "131": "227",
    "132": "228",
    "133": "228",
    "134": "229",
    "135": "230",
    "136": "230",
    "137": "231",
    "138": "231",
    "139": "231",
    "140": "232",
    "141": "232",
    "142": "233",
    "143": "233",
    "144": "234",
    "145": "234",
    "146": "235",
    "147": "236",
    "148": "236",
    "149": "237",
    "150": "237",
    "151": "238",
    "152": "238",
    "153": "238",
    "154": "239",
    "155": "239",
    "156": "239",
    "157": "240",
    "158": "240",
    "159": "241",
    "160": "241",
    "161": "241",
    "162": "242",
    "163": "242",
    "164": "243",
    "165": "244",
    "166": "244",
    "167": "244",
    "168": "245",
    "169": "245",
    "170": "246",
    "171": "246",
    "172": "246",
    "173": "247",
    "174": "247",
    "175": "247",
    "176": "248",
    "177": "248",
    "178": "248",
    "179": "249",
    "180": "249",
    "181": "250",
    "182": "251",
    "183": "251",
    "184": "252",
    "185": "252",
    "186": "252",
    "187": "253",
    "188": "253",
    "189": "254",
    "190": "255",
    "191": "256",
    "192": "257",
    "193": "258",
    "194": "259",
    "195": "260",
    "196": "261",
    "197": "262",
    "198": "263",
    "199": "264",
    "200": "265",
    "201": "266",
    "202": "267",
    "203": "268",
    "204": "269",
    "205": "270",
    "206": "271",
    "207": "272",
    "208": "273",
    "209": "274",
    "210": "275",
    "211": "276",
    "212": "277",
    "213": "278",
    "214": "279",
    "215": "280",
    "216": "281",
    "217": "282",
    "218": "283",
    "219": "284",
    "220": "285",
    "221": "286",
    "222": "287",
    "223": "288",
    "224": "289",
    "225": "290",
    "226": "291",
    "227": "292",
    "228": "293",
    "229": "294",
    "230": "295",
    "231": "296",
    "232": "297",
    "233": "298",
    "234": "299",
    "235": "300",
    "236": "301",
    "237": "302",
    "238": "303",
    "239": "304",
    "240": "305",
    "241": "306",
    "242": "307",
    "243": "308",
    "244": "309",
    "245": "309",
    "246": "309",
    "247": "309",
    "248": "309",
    "249": "309",
    "250": "309",
    "251": "309",
    "252": "309",
    "253": "309",
    "254": "309",
    "255": "309",
    "256": "309",
    "257": "309",
    "258": "309",
    "259": "309",
    "260": "309",
    "261": "309",
    "262": "309",
    "263": "309",
    "264": "309",
    "265": "309",
    "266": "309",
    "267": "309",
    "268": "309",
    "269": "309",
    "270": "309",
    "271": "309",
    "272": "309",
    "273": "309",
    "274": "309",
    "275": "309",
    "276": "309",
    "277": "309",
    "278": "309",
    "279": "309",
    "280": "309",
    "281": "309",
    "282": "309",
    "283": "309",
    "284": "309",
    "285": "309",
    "286": "309",
    "287": "309",
    "288": "309",
    "289": "309",
    "290": "309",
    "291": "309",
    "292": "309",
    "293": "309",
    "294": "309",
    "295": "309",
    "296": "309",
    "297": "309",
    "298": "310",
    "299": "311",
    "300": "311",
    "301": "311",
    "302": "312",
    "303": "312",
    "304": "312",
    "305": "312",
    "306": "312",
    "307": "313",
    "308": "313",
    "309": "313",
    "310": "313",
    "311": "313",
    "312": "313",
    "313": "313",
    "314": "313",
    "315": "313",
    "316": "313",
    "317": "313",
    "318": "314",
    "319": "314",
    "320": "314",
    "321": "314",
    "322": "314",
    "323": "314",
    "324": "314",
    "325": "314",
    "326": "315",
    "327": "315",
    "328": "315",
    "329": "316",
    "330": "316",
    "331": "316",
    "332": "316",
    "333": "316",
    "334": "317",
    "335": "317",
    "336": "318",
    "337": "319",
    "338": "319",
    "339": "320",
    "340": "321",
    "341": "322",
    "342": "323",
    "343": "324",
    "344": "324",
    "345": "324",
    "346": "324",
    "347": "325",
    "348": "326",
    "349": "327",
    "350": "327",
    "351": "328",
    "352": "329",
    "353": "329",
    "354": "330",
    "355": "331",
    "356": "332",
    "357": "332",
    "358": "333",
    "359": "334",
    "360": "335",
    "361": "335",
    "362": "336",
    "363": "337",
    "364": "338",
    "365": "338",
    "366": "339",
    "367": "340",
    "368": "341",
    "369": "341",
    "370": "342",
    "371": "343",
    "372": "343",
    "373": "344",
    "374": "345",
    "375": "346",
    "376": "346",
    "377": "347",
    "378": "348",
    "379": "349",
    "380": "350",
    "381": "351",
    "382": "352",
    "383": "352",
    "384": "352",
    "385": "353",
    "386": "354",
    "387": "354",
    "388": "355",
    "389": "356",
    "390": "357",
    "391": "357",
    "392": "357",
    "393": "358",
    "394": "359",
    "395": "359",
    "396": "360",
    "397": "361",
    "398": "362",
    "399": "362",
    "400": "363",
    "401": "363",
    "402": "364",
    "403": "364",
    "404": "365",
    "405": "366",
    "406": "366",
    "407": "367",
    "408": "367",
    "409": "368",
    "410": "368",
    "411": "368",
    "412": "368",
    "413": "369",
    "414": "369",
    "415": "369",
    "416": "370",
    "417": "371",
    "418": "371",
    "419": "372",
    "420": "373",
    "421": "374",
    "422": "375",
    "423": "376",
    "424": "377",
    "425": "377",
    "426": "378",
    "427": "379",
    "428": "379",
    "429": "379",
    "430": "380",
    "431": "381",
    "432": "382",
    "433": "382",
    "434": "383",
    "435": "384",
    "436": "384",
    "437": "385",
    "438": "386",
    "439": "387",
    "440": "387",
    "441": "388",
    "442": "389",
    "443": "390",
    "444": "390",
    "445": "391",
    "446": "392",
    "447": "393",
    "448": "393",
    "449": "394",
    "450": "395",
    "451": "396",
    "452": "396",
    "453": "397",
    "454": "398",
    "455": "398",
    "456": "399",
    "457": "400",
    "458": "401",
    "459": "402",
    "460": "403",
    "461": "404",
    "462": "405",
    "463": "405",
    "464": "406",
    "465": "407",
    "466": "408",
    "467": "408",
    "468": "409",
    "469": "410",
    "470": "411",
    "471": "411",
    "472": "412",
    "473": "412",
    "474": "413",
    "475": "413",
    "476": "414",
    "477": "415",
    "478": "415",
    "479": "416",
    "480": "416",
    "481": "417",
    "482": "417",
    "483": "418",
    "484": "419",
    "485": "420",
    "486": "420",
    "487": "421",
    "488": "422",
    "489": "422",
    "490": "423",
    "491": "424",
    "492": "425",
    "493": "425",
    "494": "426",
    "495": "427",
    "496": "428",
    "497": "428",
    "498": "429",
    "499": "430",
    "500": "431",
    "501": "432",
    "502": "433",
    "503": "434",
    "504": "434",
    "505": "435",
    "506": "436",
    "507": "437",
    "508": "437",
    "509": "438",
    "510": "439",
    "511": "440",
    "512": "440",
    "513": "441",
    "514": "442",
    "515": "443",
    "516": "443",
    "517": "444",
    "518": "445",
    "519": "446",
    "520": "446",
    "521": "447",
    "522": "448",
    "523": "448",
    "524": "449",
    "525": "450",
    "526": "451",
    "527": "451",
    "528": "452",
    "529": "453",
    "530": "454",
    "531": "454",
    "532": "455",
    "533": "456",
    "534": "456",
    "535": "456",
    "536": "457",
    "537": "457",
    "538": "457",
    "539": "458",
    "540": "459",
    "541": "460",
    "542": "461",
    "543": "462",
    "544": "463",
    "545": "464",
    "546": "464",
    "547": "465",
    "548": "466",
    "549": "467",
    "550": "467",
    "551": "468",
    "552": "469",
    "553": "469",
    "554": "470",
    "555": "471",
    "556": "472",
    "557": "472",
    "558": "473",
    "559": "474",
    "560": "475",
    "561": "475",
    "562": "476",
    "563": "477",
    "564": "478",
    "565": "478",
    "566": "479",
    "567": "480",
    "568": "481",
    "569": "481",
    "570": "482",
    "571": "483",
    "572": "483",
    "573": "484",
    "574": "485",
    "575": "486",
    "576": "486",
    "577": "487",
    "578": "488",
    "579": "489",
    "580": "490",
    "581": "491",
    "582": "492",
    "583": "493",
    "584": "493",
    "585": "494",
    "586": "495",
    "587": "495",
    "588": "496",
    "589": "497",
    "590": "498",
    "591": "498",
    "592": "499",
    "593": "500",
    "594": "501",
    "595": "501",
    "596": "502",
    "597": "503",
    "598": "504",
    "599": "504",
    "600": "505",
    "601": "506",
    "602": "507",
    "603": "507",
    "604": "508",
    "605": "509",
    "606": "509",
    "607": "510",
    "608": "511",
    "609": "512",
    "610": "512",
    "611": "513",
    "612": "514",
    "613": "515",
    "614": "515",
    "615": "516",
    "616": "517",
    "617": "518",
    "618": "518",
    "619": "519",
    "620": "520",
    "621": "521",
    "622": "522",
    "623": "523",
    "624": "524",
    "625": "524",
    "626": "525",
    "627": "526",
    "628": "527",
    "629": "527",
    "630": "528",
    "631": "529",
    "632": "530",
    "633": "530",
    "634": "531",
    "635": "532",
    "636": "533",
    "637": "533",
    "638": "534",
    "639": "535",
    "640": "535",
    "641": "536",
    "642": "537",
    "643": "538",
    "644": "538",
    "645": "539",
    "646": "540",
    "647": "541",
    "648": "541",
    "649": "542",
    "650": "543",
    "651": "544",
    "652": "544",
    "653": "545",
    "654": "546",
    "655": "546",
    "656": "547",
    "657": "548",
    "658": "549",
    "659": "550",
    "660": "551",
    "661": "552",
    "662": "553",
    "663": "553",
    "664": "554",
    "665": "555",
    "666": "556",
    "667": "556",
    "668": "557",
    "669": "558",
    "670": "559",
    "671": "559",
    "672": "560",
    "673": "561",
    "674": "561",
    "675": "562",
    "676": "563",
    "677": "564",
    "678": "564",
    "679": "565",
    "680": "566",
    "681": "567",
    "682": "567",
    "683": "568",
    "684": "569",
    "685": "570",
    "686": "570",
    "687": "571",
    "688": "572",
    "689": "572",
    "690": "573",
    "691": "574",
    "692": "575",
    "693": "575",
    "694": "576",
    "695": "577",
    "696": "578",
    "697": "578",
    "698": "579",
    "699": "580",
    "700": "581",
    "701": "582",
    "702": "583",
    "703": "584",
    "704": "584",
    "705": "585",
    "706": "586",
    "707": "587",
    "708": "587",
    "709": "588",
    "710": "589",
    "711": "590",
    "712": "590",
    "713": "591",
    "714": "592",
    "715": "593",
    "716": "593",
    "717": "594",
    "718": "595",
    "719": "596",
    "720": "596",
    "721": "597",
    "722": "598",
    "723": "598",
    "724": "599",
    "725": "600",
    "726": "601",
    "727": "601",
    "728": "602",
    "729": "603",
    "730": "604",
    "731": "604",
    "732": "605",
    "733": "606",
    "734": "607",
    "735": "607",
    "736": "608",
    "737": "609",
    "738": "610",
    "739": "610",
    "740": "611",
    "741": "612",
    "742": "613",
    "743": "614",
    "744": "615",
    "745": "616",
    "746": "616",
    "747": "617",
    "748": "618",
    "749": "619",
    "750": "619",
    "751": "620",
    "752": "621",
    "753": "622",
    "754": "622",
    "755": "623",
    "756": "624",
    "757": "624",
    "758": "625",
    "759": "626",
    "760": "627",
    "761": "627",
    "762": "628",
    "763": "629",
    "764": "630",
    "765": "630",
    "766": "631",
    "767": "632",
    "768": "633",
    "769": "633",
    "770": "634",
    "771": "635",
    "772": "635",
    "773": "636",
    "774": "637",
    "775": "638",
    "776": "638",
    "777": "639",
    "778": "640",
    "779": "641",
    "780": "642",
    "781": "643",
    "782": "644",
    "783": "645",
    "784": "645",
    "785": "646",
    "786": "647",
    "787": "648",
    "788": "648",
    "789": "649",
    "790": "650",
    "791": "650",
    "792": "651",
    "793": "652",
    "794": "653",
    "795": "653",
    "796": "654",
    "797": "655",
    "798": "656",
    "799": "656",
    "800": "657",
    "801": "658",
    "802": "659",
    "803": "659",
    "804": "660",
    "805": "661",
    "806": "661",
    "807": "662",
    "808": "663",
    "809": "664",
    "810": "664",
    "811": "665",
    "812": "666",
    "813": "667",
    "814": "667",
    "815": "668",
    "816": "669",
    "817": "670",
    "818": "670",
    "819": "671",
    "820": "672",
    "821": "673",
    "822": "674",
    "823": "675",
    "824": "676",
    "825": "676",
    "826": "677",
    "827": "678",
    "828": "679",
    "829": "679",
    "830": "680",
    "831": "681",
    "832": "682",
    "833": "682",
    "834": "683",
    "835": "684",
    "836": "685",
    "837": "685",
    "838": "686",
    "839": "687",
    "840": "687",
    "841": "688",
    "842": "689",
    "843": "690",
    "844": "690",
    "845": "691",
    "846": "692",
    "847": "693",
    "848": "693",
    "849": "694",
    "850": "695",
    "851": "696",
    "852": "696",
    "853": "697",
    "854": "698",
    "855": "699",
    "856": "699",
    "857": "700",
    "858": "701",
    "859": "702",
    "860": "703",
    "861": "704",
    "862": "705",
    "863": "705",
    "864": "706",
    "865": "707",
    "866": "708",
    "867": "708",
    "868": "709",
    "869": "710",
    "870": "711",
    "871": "711",
    "872": "712",
    "873": "713",
    "874": "713",
    "875": "714",
    "876": "715",
    "877": "716",
    "878": "716",
    "879": "717",
    "880": "718",
    "881": "719",
    "882": "719",
    "883": "720",
    "884": "721",
    "885": "722",
    "886": "722",
    "887": "723",
    "888": "724",
    "889": "725",
    "890": "725",
    "891": "726",
    "892": "727",
    "893": "727",
    "894": "728",
    "895": "729",
    "896": "730",
    "897": "730",
    "898": "731",
    "899": "732",
    "900": "733",
    "901": "734",
    "902": "735",
    "903": "735",
    "904": "736",
    "905": "737",
    "906": "738",
    "907": "739",
    "908": "739",
    "909": "740",
    "910": "741",
    "911": "742",
    "912": "743",
    "913": "743",
    "914": "744",
    "915": "745",
    "916": "746",
    "917": "747",
    "918": "747",
    "919": "748",
    "920": "749",
    "921": "750",
    "922": "750",
    "923": "751",
    "924": "751",
    "925": "752",
    "926": "753",
    "927": "754",
    "928": "754",
    "929": "755",
    "930": "756",
    "931": "757",
    "932": "758",
    "933": "758",
    "934": "759",
    "935": "760",
    "936": "761",
    "937": "762",
    "938": "762",
    "939": "763",
    "940": "764",
    "941": "765",
    "942": "765",
    "943": "766",
    "944": "766",
    "945": "767",
    "946": "768",
    "947": "769",
    "948": "769",
    "949": "770",
    "950": "771",
    "951": "772",
    "952": "772",
    "953": "773",
    "954": "773",
    "955": "774",
    "956": "775",
    "957": "776",
    "958": "776",
    "959": "777",
    "960": "778",
    "961": "779",
    "962": "780",
    "963": "780",
    "964": "781",
    "965": "782",
    "966": "783",
    "967": "784",
    "968": "784",
    "969": "785",
    "970": "786",
    "971": "787",
    "972": "788",
    "973": "788",
    "974": "789",
    "975": "790",
    "976": "791",
    "977": "792",
    "978": "792",
    "979": "793",
    "980": "794",
    "981": "795",
    "982": "796",
    "983": "796",
    "984": "797",
    "985": "798",
    "986": "799",
    "987": "800",
    "988": "800",
    "989": "801",
    "990": "802",
    "991": "803",
    "992": "804",
    "993": "804",
    "994": "805",
    "995": "806",
    "996": "807",
    "997": "807",
    "998": "808",
    "999": "808",
    "1000": "809",
    "1001": "810",
    "1002": "811",
    "1003": "811",
    "1004": "812",
    "1005": "813",
    "1006": "814",
    "1007": "814",
    "1008": "815",
    "1009": "816",
    "1010": "817",
    "1011": "817",
    "1012": "818",
    "1013": "819",
    "1014": "820",
    "1015": "821",
    "1016": "821",
    "1017": "822",
    "1018": "823",
    "1019": "823",
    "1020": "824",
    "1021": "825",
    "1022": "826",
    "1023": "827",
    "1024": "827",
    "1025": "828",
    "1026": "829",
    "1027": "830",
    "HEA1": "890",
    "HEA2": "925",
    "HEA3": "972",
    "HEB1": "972",
    "HEB2": "1013",
    "HEB3": "1067",
    "HEBB1": "1067",
    "HEBB2": "1095",
    "HEBB3": "1124"
  },
  "2023-07-01": {
    "100": "203",
    "101": "204",
    "102": "204",
    "103": "205",
    "104": "206",
    "105": "207",
    "106": "207",
    "107": "208",
    "108": "209",
    "109": "210",
    "110": "210",
    "111": "211",
    "112": "212",
    "113": "213",
    "114": "213",
    "115": "214",
    "116": "215",
    "117": "215",
    "118": "216",
    "119": "217",
    "120": "218",
    "121": "219",
    "122": "220",
    "123": "221",
    "124": "222",
    "125": "222",
    "126": "223",
    "127": "224",
    "128": "225",
    "129": "225",
    "130": "226",
    "131": "227",
    "132": "228",
    "133": "228",
    "134": "229",
    "135": "230",
    "136": "230",
    "137": "231",
    "138": "231",
    "139": "231",
    "140": "232",
    "141": "232",
    "142": "233",
    "143": "233",
    "144": "234",
    "145": "234",
    "146": "235",
    "147": "236",
    "148": "236",
    "149": "237",
    "150": "237",
    "151": "238",
    "152": "238",
    "153": "238",
    "154": "239",
    "155": "239",
    "156": "239",
    "157": "240",
    "158": "240",
    "159": "241",
    "160": "241",
    "161": "241",
    "162": "242",
    "163": "242",
    "164": "243",
    "165": "244",
    "166": "244",
    "167": "244",
    "168": "245",
    "169": "245",
    "170": "246",
    "171": "246",
    "172": "246",
    "173": "247",
    "174": "247",
    "175": "247",
    "176": "248",
    "177": "248",
    "178": "248",
    "179": "249",
    "180": "249",
    "181": "250",
    "182": "251",
    "183": "251",
    "184": "252",
    "185": "252",
    "186": "252",
    "187": "253",
    "188": "253",
    "189": "254",
    "190": "255",
    "191": "256",
    "192": "257",
    "193": "258",
    "194": "259",
    "195": "260",
    "196": "261",
    "197": "262",
    "198": "263",
    "199": "264",
    "200": "265",
    "201": "266",
    "202": "267",
    "203": "268",
    "204": "269",
    "205": "270",
    "206": "271",
    "207": "272",
    "208": "273",
    "209": "274",
    "210": "275",
    "211": "276",
    "212": "277",
    "213": "278",
    "214": "279",
    "215": "280",
    "216": "281",
    "217": "282",
    "218": "283",
    "219": "284",
    "220": "285",
    "221": "286",
    "222": "287",
    "223": "288",
    "224": "289",
    "225": "290",
    "226": "291",
    "227": "292",
    "228": "293",
    "229": "294",
    "230": "295",
    "231": "296",
    "232": "297",
    "233": "298",
    "234": "299",
    "235": "300",
    "236": "301",
    "237": "302",
    "238": "303",
    "239": "304",
    "240": "305",
    "241": "306",
    "242": "307",
    "243": "308",
    "244": "309",
    "245": "309",
    "246": "309",
    "247": "309",
    "248": "309",
    "249": "309",
    "250": "309",
    "251": "309",
    "252": "309",
    "253": "309",
    "254": "309",
    "255": "309",
    "256": "309",
    "257": "309",
    "258": "309",
    "259": "309",
    "260": "309",
    "261": "309",
    "262": "309",
    "263": "309",
    "264": "309",
    "265": "309",
    "266": "309",
    "267": "309",
    "268": "309",
    "269": "309",
    "270": "309",
    "271": "309",
    "272": "309",
    "273": "309",
    "274": "309",
    "275": "309",
    "276": "309",
    "277": "309",
    "278": "309",
    "279": "309",
    "280": "309",
    "281": "309",
    "282": "309",
    "283": "309",
    "284": "309",
    "285": "309",
    "286": "309",
    "287": "309",
    "288": "309",
    "289": "309",
    "290": "309",
    "291": "309",
    "292": "309",
    "293": "309",
    "294": "309",
    "295": "309",
    "296": "309",
    "297": "309",
    "298": "310",
    "299": "311",
    "300": "311",
    "301": "311",
    "302": "312",
    "303": "312",
    "304": "312",
    "305": "312",
    "306": "312",
    "307": "313",
    "308": "313",
    "309": "313",
    "310": "313",
    "311": "313",
    "312": "313",
    "313": "313",
    "314": "313",
    "315": "313",
    "316": "313",
    "317": "313",
    "318": "314",
    "319": "314",
    "320": "314",
    "321": "314",
    "322": "314",
    "323": "314",
    "324": "314",
    "325": "314",
    "326": "315",
    "327": "315",
    "328": "315",
    "329": "316",
    "330": "316",
    "331": "316",
    "332": "316",
    "333": "316",
    "334": "317",
    "335": "317",
    "336": "318",
    "337": "319",
    "338": "319",
    "339": "320",
    "340": "321",
    "341": "322",
    "342": "323",
    "343": "324",
    "344": "324",
    "345": "324",
    "346": "324",
    "347": "325",
    "348": "326",
    "349": "327",
    "350": "327",
    "351": "328",
    "352": "329",
    "353": "329",
    "354": "332",
    "355": "333",
    "356": "334",
    "357": "334",
    "358": "335",
    "359": "335",
    "360": "336",
    "361": "336",
    "362": "336",
    "363": "337",
    "364": "338",
    "365": "338",
    "366": "339",
    "367": "361",
    "368": "362",
    "369": "362",
    "370": "363",
    "371": "364",
    "372": "364",
    "373": "364",
    "374": "365",
    "375": "365",
    "376": "365",
    "377": "365",
    "378": "366",
    "379": "366",
    "380": "366",
    "381": "367",
    "382": "367",
    "383": "367",
    "384": "367",
    "385": "367",
    "386": "368",
    "387": "368",
    "388": "368",
    "389": "368",
    "390": "368",
    "391": "368",
    "392": "368",
    "393": "368",
    "394": "369",
    "395": "369",
    "396": "369",
    "397": "370",
    "398": "370",
    "399": "370",
    "400": "371",
    "401": "371",
    "402": "371",
    "403": "371",
    "404": "371",
    "405": "371",
    "406": "371",
    "407": "371",
    "408": "371",
    "409": "371",
    "410": "371",
    "411": "371",
    "412": "371",
    "413": "372",
    "414": "372",
    "415": "372",
    "416": "372",
    "417": "372",
    "418": "372",
    "419": "372",
    "420": "373",
    "421": "374",
    "422": "375",
    "423": "376",
    "424": "377",
    "425": "377",
    "426": "378",
    "427": "379",
    "428": "379",
    "429": "379",
    "430": "380",
    "431": "381",
    "432": "382",
    "433": "382",
    "434": "383",
    "435": "384",
    "436": "384",
    "437": "385",
    "438": "386",
    "439": "387",
    "440": "387",
    "441": "388",
    "442": "389",
    "443": "390",
    "444": "390",
    "445": "391",
    "446": "392",
    "447": "393",
    "448": "393",
    "449": "394",
    "450": "395",
    "451": "396",
    "452": "396",
    "453": "397",
    "454": "398",
    "455": "398",
    "456": "399",
    "457": "400",
    "458": "401",
    "459": "402",
    "460": "403",
    "461": "404",
    "462": "405",
    "463": "405",
    "464": "406",
    "465": "407",
    "466": "408",
    "467": "408",
    "468": "409",
    "469": "410",
    "470": "411",
    "471": "411",
    "472": "412",
    "473": "412",
    "474": "413",
    "475": "413",
    "476": "414",
    "477": "415",
    "478": "415",
    "479": "416",
    "480": "416",
    "481": "417",
    "482": "417",
    "483": "418",
    "484": "419",
    "485": "420",
    "486": "420",
    "487": "421",
    "488": "422",
    "489": "422",
    "490": "423",
    "491": "424",
    "492": "425",
    "493": "425",
    "494": "426",
    "495": "427",
    "496": "428",
    "497": "428",
    "498": "429",
    "499": "430",
    "500": "431",
    "501": "432",
    "502": "433",
    "503": "434",
    "504": "434",
    "505": "435",
    "506": "436",
    "507": "437",
    "508": "437",
    "509": "438",
    "510": "439",
    "511": "440",
    "512": "440",
    "513": "441",
    "514": "442",
    "515": "443",
    "516": "443",
    "517": "444",
    "518": "445",
    "519": "446",
    "520": "446",
    "521": "447",
    "522": "448",
    "523": "448",
    "524": "449",
    "525": "450",
    "526": "451",
    "527": "451",
    "528": "452",
    "529": "453",
    "530": "454",
    "531": "454",
    "532": "455",
    "533": "456",
    "534": "456",
    "535": "456",
    "536": "457",
    "537": "457",
    "538": "457",
    "539": "458",
    "540": "459",
    "541": "460",
    "542": "461",
    "543": "462",
    "544": "463",
    "545": "464",
    "546": "464",
    "547": "465",
    "548": "466",
    "549": "467",
    "550": "467",
    "551": "468",
    "552": "469",
    "553": "469",
    "554": "470",
    "555": "471",
    "556": "472",
    "557": "472",
    "558": "473",
    "559": "474",
    "560": "475",
    "561": "475",
    "562": "476",
    "563": "477",
    "564": "478",
    "565": "478",
    "566": "479",
    "567": "480",
    "568": "481",
    "569": "481",
    "570": "482",
    "571": "483",
    "572": "483",
    "573": "484",
    "574": "485",
    "575": "486",
    "576": "486",
    "577": "487",
    "578": "488",
    "579": "489",
    "580": "490",
    "581": "491",
    "582": "492",
    "583": "493",
    "584": "493",
    "585": "494",
    "586": "495",
    "587": "495",
    "588": "496",
    "589": "497",
    "590": "498",
    "591": "498",
    "592": "499",
    "593": "500",
    "594": "501",
    "595": "501",
    "596": "502",
    "597": "503",
    "598": "504",
    "599": "504",
    "600": "505",
    "601": "506",
    "602": "507",
    "603": "507",
    "604": "508",
    "605": "509",
    "606": "509",
    "607": "510",
    "608": "511",
    "609": "512",
    "610": "512",
    "611": "513",
    "612": "514",
    "613": "515",
    "614": "515",
    "615": "516",
    "616": "517",
    "617": "518",
    "618": "518",
    "619": "519",
    "620": "520",
    "621": "521",
    "622": "522",
    "623": "523",
    "624": "524",
    "625": "524",
    "626": "525",
    "627": "526",
    "628": "527",
    "629": "527",
    "630": "528",
    "631": "529",
    "632": "530",
    "633": "530",
    "634": "531",
    "635": "532",
    "636": "533",
    "637": "533",
    "638": "534",
    "639": "535",
    "640": "535",
    "641": "536",
    "642": "537",
    "643": "538",
    "644": "538",
    "645": "539",
    "646": "540",
    "647": "541",
    "648": "541",
    "649": "542",
    "650": "543",
    "651": "544",
    "652": "544",
    "653": "545",
    "654": "546",
    "655": "546",
    "656": "547",
    "657": "548",
    "658": "549",
    "659": "550",
    "660": "551",
    "661": "552",
    "662": "553",
    "663": "553",
    "664": "554",
    "665": "555",
    "666": "556",
    "667": "556",
    "668": "557",
    "669": "558",
    "670": "559",
    "671": "559",
    "672": "560",
    "673": "561",
    "674": "561",
    "675": "562",
    "676": "563",
    "677": "564",
    "678": "564",
    "679": "565",
    "680": "566",
    "681": "567",
    "682": "567",
    "683": "568",
    "684": "569",
    "685": "570",
    "686": "570",
    "687": "571",
    "688": "572",
    "689": "572",
    "690": "573",
    "691": "574",
    "692": "575",
    "693": "575",
    "694": "576",
    "695": "577",
    "696": "578",
    "697": "578",
    "698": "579",
    "699": "580",
    "700": "581",
    "701": "582",
    "702": "583",
    "703": "584",
    "704": "584",
    "705": "585",
    "706": "586",
    "707": "587",
    "708": "587",
    "709": "588",
    "710": "589",
    "711": "590",
    "712": "590",
    "713": "591",
    "714": "592",
    "715": "593",
    "716": "593",
    "717": "594",
    "718": "595",
    "719": "596",
    "720": "596",
    "721": "597",
    "722": "598",
    "723": "598",
    "724": "599",
    "725": "600",
    "726": "601",
    "727": "601",
    "728": "602",
    "729": "603",
    "730": "604",
    "731": "604",
    "732": "605",
    "733": "606",
    "734": "607",
    "735": "607",
    "736": "608",
    "737": "609",
    "738": "610",
    "739": "610",
    "740": "611",
    "741": "612",
    "742": "613",
    "743": "614",
    "744": "615",
    "745": "616",
    "746": "616",
    "747": "617",
    "748": "618",
    "749": "619",
    "750": "619",
    "751": "620",
    "752": "621",
    "753": "622",
    "754": "622",
    "755": "623",
    "756": "624",
    "757": "624",
    "758": "625",
    "759": "626",
    "760": "627",
    "761": "627",
    "762": "628",
    "763": "629",
    "764": "630",
    "765": "630",
    "766": "631",
    "767": "632",
    "768": "633",
    "769": "633",
    "770": "634",
    "771": "635",
    "772": "635",
    "773": "636",
    "774": "637",
    "775": "638",
    "776": "638",
    "777": "639",
    "778": "640",
    "779": "641",
    "780": "642",
    "781": "643",
    "782": "644",
    "783": "645",
    "784": "645",
    "785": "646",
    "786": "647",
    "787": "648",
    "788": "648",
    "789": "649",
    "790": "650",
    "791": "650",
    "792": "651",
    "793": "652",
    "794": "653",
    "795": "653",
    "796": "654",
    "797": "655",
    "798": "656",
    "799": "656",
    "800": "657",
    "801": "658",
    "802": "659",
    "803": "659",
    "804": "660",
    "805": "661",
    "806": "661",
    "807": "662",
    "808": "663",
    "809": "664",
    "810": "664",
    "811": "665",
    "812": "666",
    "813": "667",
    "814": "667",
    "815": "668",
    "816": "669",
    "817": "670",
    "818": "670",
    "819": "671",
    "820": "672",
    "821": "673",
    "822": "674",
    "823": "675",
    "824": "676",
    "825": "676",
    "826": "677",
    "827": "678",
    "828": "679",
    "829": "679",
    "830": "680",
    "831": "681",
    "832": "682",
    "833": "682",
    "834": "683",
    "835": "684",
    "836": "685",
    "837": "685",
    "838": "686",
    "839": "687",
    "840": "687",
    "841": "688",
    "842": "689",
    "843": "690",
    "844": "690",
    "845": "691",
    "846": "692",
    "847": "693",
    "848": "693",
    "849": "694",
    "850": "695",
    "851": "696",
    "852": "696",
    "853": "697",
    "854": "698",
    "855": "699",
    "856": "699",
    "857": "700",
    "858": "701",
    "859": "702",
    "860": "703",
    "861": "704",
    "862": "705",
    "863": "705",
    "864": "706",
    "865": "707",
    "866": "708",
    "867": "708",
    "868": "709",
    "869": "710",
    "870": "711",
    "871": "711",
    "872": "712",
    "873": "713",
    "874": "713",
    "875": "714",
    "876": "715",
    "877": "716",
    "878": "716",
    "879": "717",
    "880": "718",
    "881": "719",
    "882": "719",
    "883": "720",
    "884": "721",
    "885": "722",
    "886": "722",
    "887": "723",
    "888": "724",
    "889": "725",
    "890": "725",
    "891": "726",
    "892": "727",
    "893": "727",
    "894": "728",
    "895": "729",
    "896": "730",
    "897": "730",
    "898": "731",
    "899": "732",
    "900": "733",
    "901": "734",
    "902": "735",
    "903": "735",
    "904": "736",
    "905": "737",
    "906": "738",
    "907": "739",
    "908": "739",
    "909": "740",
    "910": "741",
    "911": "742",
    "912": "743",
    "913": "743",
    "914": "744",
    "915": "745",
    "916": "746",
    "917": "747",
    "918": "747",
    "919": "748",
    "920": "749",
    "921": "750",
    "922": "750",
    "923": "751",
    "924": "751",
    "925": "752",
    "926": "753",
    "927": "754",
    "928": "754",
    "929": "755",
    "930": "756",
    "931": "757",
    "932": "758",
    "933": "758",
    "934": "759",
    "935": "760",
    "936": "761",
    "937": "762",
    "938": "762",
    "939": "763",
    "940": "764",
    "941": "765",
    "942": "765",
    "943": "766",
    "944": "766",
    "945": "767",
    "946": "768",
    "947": "769",
    "948": "769",
    "949": "770",
    "950": "771",
    "951": "772",
    "952": "772",
    "953": "773",
    "954": "773",
    "955": "774",
    "956": "775",
    "957": "776",
    "958": "776",
    "959": "777",
    "960": "778",
    "961": "779",
    "962": "780",
    "963": "780",
    "964": "781",
    "965": "782",
    "966": "783",
    "967": "784",
    "968": "784",
    "969": "785",
    "970": "786",
    "971": "787",
    "972": "788",
    "973": "788",
    "974": "789",
    "975": "790",
    "976": "791",
    "977": "792",
    "978": "792",
    "979": "793",
    "980": "794",
    "981": "795",
    "982": "796",
    "983": "796",
    "984": "797",
    "985": "798",
    "986": "799",
    "987": "800",
    "988": "800",
    "989": "801",
    "990": "802",
    "991": "803",
    "992": "804",
    "993": "804",
    "994": "805",
    "995": "806",
    "996": "807",
    "997": "807",
    "998": "808",
    "999": "808",
    "1000": "809",
    "1001": "810",
    "1002": "811",
    "1003": "811",
    "1004": "812",
    "1005": "813",
    "1006": "814",
    "1007": "814",
    "1008": "815",
    "1009": "816",
    "1010": "817",
    "1011": "817",
    "1012": "818",
    "1013": "819",
    "1014": "820",
    "1015": "821",
    "1016": "821",
    "1017": "822",
    "1018": "823",
    "1019": "823",
    "1020": "824",
    "1021": "825",
    "1022": "826",
    "1023": "827",
    "1024": "827",
    "1025": "828",
    "1026": "829",
    "1027": "830",
    "HEA1": "890",
    "HEA2": "925",
    "HEA3": "972",
    "HEB1": "972",
    "HEB2": "1013",
    "HEB3": "1067",
    "HEBB1": "1067",
    "HEBB2": "1095",
    "HEBB3": "1124"
  },
  "2024-01-01": {
    "100": "208",
    "101": "209",
    "102": "209",
    "103": "210",
    "104": "211",
    "105": "212",
    "106": "212",
    "107": "213",
    "108": "214",
    "109": "215",
    "110": "215",
    "111": "216",
    "112": "217",
    "113": "218",
    "114": "218",
    "115": "219",
    "116": "220",
    "117": "220",
    "118": "221",
    "119": "222",
    "120": "223",
    "121": "224",
    "122": "225",
    "123": "226",
    "124": "227",
    "125": "227",
    "126": "228",
    "127": "229",
    "128": "230",
    "129": "230",
    "130": "231",
    "131": "232",
    "132": "233",
    "133": "233",
    "134": "234",
    "135": "235",
    "136": "235",
    "137": "236",
    "138": "236",
    "139": "236",
    "140": "237",
    "141": "237",
    "142": "238",
    "143": "238",
    "144": "239",
    "145": "239",
    "146": "240",
    "147": "241",
    "148": "241",
    "149": "242",
    "150": "242",
    "151": "243",
    "152": "243",
    "153": "243",
    "154": "244",
    "155": "244",
    "156": "244",
    "157": "245",
    "158": "245",
    "159": "246",
    "160": "246",
    "161": "246",
    "162": "247",
    "163": "247",
    "164": "248",
    "165": "249",
    "166": "249",
    "167": "249",
    "168": "250",
    "169": "250",
    "170": "251",
    "171": "251",
    "172": "251",
    "173": "252",
    "174": "252",
    "175": "252",
    "176": "253",
    "177": "253",
    "178": "253",
    "179": "254",
    "180": "254",
    "181": "255",
    "182": "256",
    "183": "256",
    "184": "257",
    "185": "257",
    "186": "257",
    "187": "258",
    "188": "258",
    "189": "259",
    "190": "260",
    "191": "261",
    "192": "262",
    "193": "263",
    "194": "264",
    "195": "265",
    "196": "266",
    "197": "267",
    "198": "268",
    "199": "269",
    "200": "270",
    "201": "271",
    "202": "272",
    "203": "273",
    "204": "274",
    "205": "275",
    "206": "276",
    "207": "277",
    "208": "278",
    "209": "279",
    "210": "280",
    "211": "281",
    "212": "282",
    "213": "283",
    "214": "284",
    "215": "285",
    "216": "286",
    "217": "287",
    "218": "288",
    "219": "289",
    "220": "290",
    "221": "291",
    "222": "292",
    "223": "293",
    "224": "294",
    "225": "295",
    "226": "296",
    "227": "297",
    "228": "298",
    "229": "299",
    "230": "300",
    "231": "301",
    "232": "302",
    "233": "303",
    "234": "304",
    "235": "305",
    "236": "306",
    "237": "307",
    "238": "308",
    "239": "309",
    "240": "310",
    "241": "311",
    "242": "312",
    "243": "313",
    "244": "314",
    "245": "314",
    "246": "314",
    "247": "314",
    "248": "314",
    "249": "314",
    "250": "314",
    "251": "314",
    "252": "314",
    "253": "314",
    "254": "314",
    "255": "314",
    "256": "314",
    "257": "314",
    "258": "314",
    "259": "314",
    "260": "314",
    "261": "314",
    "262": "314",
    "263": "314",
    "264": "314",
    "265": "314",
    "266": "314",
    "267": "314",
    "268": "314",
    "269": "314",
    "270": "314",
    "271": "314",
    "272": "314",
    "273": "314",
    "274": "314",
    "275": "314",
    "276": "314",
    "277": "314",
    "278": "314",
    "279": "314",
    "280": "314",
    "281": "314",
    "282": "314",
    "283": "314",
    "284": "314",
    "285": "314",
    "286": "314",
    "287": "314",
    "288": "314",
    "289": "314",
    "290": "314",
    "291": "314",
    "292": "314",
    "293": "314",
    "294": "314",
    "295": "314",
    "296": "314",
    "297": "314",
    "298": "315",
    "299": "316",
    "300": "316",
    "301": "316",
    "302": "317",
    "303": "317",
    "304": "317",
    "305": "317",
    "306": "317",
    "307": "318",
    "308": "318",
    "309": "318",
    "310": "318",
    "311": "318",
    "312": "318",
    "313": "318",
    "314": "318",
    "315": "318",
    "316": "318",
    "317": "318",
    "318": "319",
    "319": "319",
    "320": "319",
    "321": "319",
    "322": "319",
    "323": "319",
    "324": "319",
    "325": "319",
    "326": "320",
    "327": "320",
    "328": "320",
    "329": "321",
    "330": "321",
    "331": "321",
    "332": "321",
    "333": "321",
    "334": "322",
    "335": "322",
    "336": "323",
    "337": "324",
    "338": "324",
    "339": "325",
    "340": "326",
    "341": "327",
    "342": "328",
    "343": "329",
    "344": "329",
    "345": "329",
    "346": "329",
    "347": "330",
    "348": "331",
    "349": "332",
    "350": "332",
    "351": "333",
    "352": "334",
    "353": "334",
    "354": "337",
    "355": "338",
    "356": "339",
    "357": "339",
    "358": "340",
    "359": "340",
    "360": "341",
    "361": "341",
    "362": "341",
    "363": "342",
    "364": "343",
    "365": "343",
    "366": "344",
    "367": "366",
    "368": "367",
    "369": "367",
    "370": "368",
    "371": "369",
    "372": "369",
    "373": "369",
    "374": "370",
    "375": "370",
    "376": "370",
    "377": "370",
    "378": "371",
    "379": "371",
    "380": "371",
    "381": "372",
    "382": "372",
    "383": "372",
    "384": "372",
    "385": "372",
    "386": "373",
    "387": "373",
    "388": "373",
    "389": "373",
    "390": "373",
    "391": "373",
    "392": "373",
    "393": "373",
    "394": "374",
    "395": "374",
    "396": "374",
    "397": "375",
    "398": "375",
    "399": "375",
    "400": "376",
    "401": "376",
    "402": "376",
    "403": "376",
    "404": "376",
    "405": "376",
    "406": "376",
    "407": "376",
    "408": "376",
    "409": "376",
    "410": "376",
    "411": "376",
    "412": "376",
    "413": "377",
    "414": "377",
    "415": "377",
    "416": "377",
    "417": "377",
    "418": "377",
    "419": "377",
    "420": "378",
    "421": "379",
    "422": "380",
    "423": "381",
    "424": "382",
    "425": "382",
    "426": "383",
    "427": "384",
    "428": "384",
    "429": "384",
    "430": "385",
    "431": "386",
    "432": "387",
    "433": "387",
    "434": "388",
    "435": "389",
    "436": "389",
    "437": "390",
    "438": "391",
    "439": "392",
    "440": "392",
    "441": "393",
    "442": "394",
    "443": "395",
    "444": "395",
    "445": "396",
    "446": "397",
    "447": "398",
    "448": "398",
    "449": "399",
    "450": "400",
    "451": "401",
    "452": "401",
    "453": "402",
    "454": "403",
    "455": "403",
    "456": "404",
    "457": "405",
    "458": "406",
    "459": "407",
    "460": "408",
    "461": "409",
    "462": "410",
    "463": "410",
    "464": "411",
    "465": "412",
    "466": "413",
    "467": "413",
    "468": "414",
    "469": "415",
    "470": "416",
    "471": "416",
    "472": "417",
    "473": "417",
    "474": "418",
    "475": "418",
    "476": "419",
    "477": "420",
    "478": "420",
    "479": "421",
    "480": "421",
    "481": "422",
    "482": "422",
    "483": "423",
    "484": "424",
    "485": "425",
    "486": "425",
    "487": "426",
    "488": "427",
    "489": "427",
    "490": "428",
    "491": "429",
    "492": "430",
    "493": "430",
    "494": "431",
    "495": "432",
    "496": "433",
    "497": "433",
    "498": "434",
    "499": "435",
    "500": "436",
    "501": "437",
    "502": "438",
    "503": "439",
    "504": "439",
    "505": "440",
    "506": "441",
    "507": "442",
    "508": "442",
    "509": "443",
    "510": "444",
    "511": "445",
    "512": "445",
    "513": "446",
    "514": "447",
    "515": "448",
    "516": "448",
    "517": "449",
    "518": "450",
    "519": "451",
    "520": "451",
    "521": "452",
    "522": "453",
    "523": "453",
    "524": "454",
    "525": "455",
    "526": "456",
    "527": "456",
    "528": "457",
    "529": "458",
    "530": "459",
    "531": "459",
    "532": "460",
    "533": "461",
    "534": "461",
    "535": "461",
    "536": "462",
    "537": "462",
    "538": "462",
    "539": "463",
    "540": "464",
    "541": "465",
    "542": "466",
    "543": "467",
    "544": "468",
    "545": "469",
    "546": "469",
    "547": "470",
    "548": "471",
    "549": "472",
    "550": "472",
    "551": "473",
    "552": "474",
    "553": "474",
    "554": "475",
    "555": "476",
    "556": "477",
    "557": "477",
    "558": "478",
    "559": "479",
    "560": "480",
    "561": "480",
    "562": "481",
    "563": "482",
    "564": "483",
    "565": "483",
    "566": "484",
    "567": "485",
    "568": "486",
    "569": "486",
    "570": "487",
    "571": "488",
    "572": "488",
    "573": "489",
    "574": "490",
    "575": "491",
    "576": "491",
    "577": "492",
    "578": "493",
    "579": "494",
    "580": "495",
    "581": "496",
    "582": "497",
    "583": "498",
    "584": "498",
    "585": "499",
    "586": "500",
    "587": "500",
    "588": "501",
    "589": "502",
    "590": "503",
    "591": "503",
    "592": "504",
    "593": "505",
    "594": "506",
    "595": "506",
    "596": "507",
    "597": "508",
    "598": "509",
    "599": "509",
    "600": "510",
    "601": "511",
    "602": "512",
    "603": "512",
    "604": "513",
    "605": "514",
    "606": "514",
    "607": "515",
    "608": "516",
    "609": "517",
    "610": "517",
    "611": "518",
    "612": "519",
    "613": "520",
    "614": "520",
    "615": "521",
    "616": "522",
    "617": "523",
    "618": "523",
    "619": "524",
    "620": "525",
    "621": "526",
    "622": "527",
    "623": "528",
    "624": "529",
    "625": "529",
    "626": "530",
    "627": "531",
    "628": "532",
    "629": "532",
    "630": "533",
    "631": "534",
    "632": "535",
    "633": "535",
    "634": "536",
    "635": "537",
    "636": "538",
    "637": "538",
    "638": "539",
    "639": "540",
    "640": "540",
    "641": "541",
    "642": "542",
    "643": "543",
    "644": "543",
    "645": "544",
    "646": "545",
    "647": "546",
    "648": "546",
    "649": "547",
    "650": "548",
    "651": "549",
    "652": "549",
    "653": "550",
    "654": "551",
    "655": "551",
    "656": "552",
    "657": "553",
    "658": "554",
    "659": "555",
    "660": "556",
    "661": "557",
    "662": "558",
    "663": "558",
    "664": "559",
    "665": "560",
    "666": "561",
    "667": "561",
    "668": "562",
    "669": "563",
    "670": "564",
    "671": "564",
    "672": "565",
    "673": "566",
    "674": "566",
    "675": "567",
    "676": "568",
    "677": "569",
    "678": "569",
    "679": "570",
    "680": "571",
    "681": "572",
    "682": "572",
    "683": "573",
    "684": "574",
    "685": "575",
    "686": "575",
    "687": "576",
    "688": "577",
    "689": "577",
    "690": "578",
    "691": "579",
    "692": "580",
    "693": "580",
    "694": "581",
    "695": "582",
    "696": "583",
    "697": "583",
    "698": "584",
    "699": "585",
    "700": "586",
    "701": "587",
    "702": "588",
    "703": "589",
    "704": "589",
    "705": "590",
    "706": "591",
    "707": "592",
    "708": "592",
    "709": "593",
    "710": "594",
    "711": "595",
    "712": "595",
    "713": "596",
    "714": "597",
    "715": "598",
    "716": "598",
    "717": "599",
    "718": "600",
    "719": "601",
    "720": "601",
    "721": "602",
    "722": "603",
    "723": "603",
    "724": "604",
    "725": "605",
    "726": "606",
    "727": "606",
    "728": "607",
    "729": "608",
    "730": "609",
    "731": "609",
    "732": "610",
    "733": "611",
    "734": "612",
    "735": "612",
    "736": "613",
    "737": "614",
    "738": "615",
    "739": "615",
    "740": "616",
    "741": "617",
    "742": "618",
    "743": "619",
    "744": "620",
    "745": "621",
    "746": "621",
    "747": "622",
    "748": "623",
    "749": "624",
    "750": "624",
    "751": "625",
    "752": "626",
    "753": "627",
    "754": "627",
    "755": "628",
    "756": "629",
    "757": "629",
    "758": "630",
    "759": "631",
    "760": "632",
    "761": "632",
    "762": "633",
    "763": "634",
    "764": "635",
    "765": "635",
    "766": "636",
    "767": "637",
    "768": "638",
    "769": "638",
    "770": "639",
    "771": "640",
    "772": "640",
    "773": "641",
    "774": "642",
    "775": "643",
    "776": "643",
    "777": "644",
    "778": "645",
    "779": "646",
    "780": "647",
    "781": "648",
    "782": "649",
    "783": "650",
    "784": "650",
    "785": "651",
    "786": "652",
    "787": "653",
    "788": "653",
    "789": "654",
    "790": "655",
    "791": "655",
    "792": "656",
    "793": "657",
    "794": "658",
    "795": "658",
    "796": "659",
    "797": "660",
    "798": "661",
    "799": "661",
    "800": "662",
    "801": "663",
    "802": "664",
    "803": "664",
    "804": "665",
    "805": "666",
    "806": "666",
    "807": "667",
    "808": "668",
    "809": "669",
    "810": "669",
    "811": "670",
    "812": "671",
    "813": "672",
    "814": "672",
    "815": "673",
    "816": "674",
    "817": "675",
    "818": "675",
    "819": "676",
    "820": "677",
    "821": "678",
    "822": "679",
    "823": "680",
    "824": "681",
    "825": "681",
    "826": "682",
    "827": "683",
    "828": "684",
    "829": "684",
    "830": "685",
    "831": "686",
    "832": "687",
    "833": "687",
    "834": "688",
    "835": "689",
    "836": "690",
    "837": "690",
    "838": "691",
    "839": "692",
    "840": "692",
    "841": "693",
    "842": "694",
    "843": "695",
    "844": "695",
    "845": "696",
    "846": "697",
    "847": "698",
    "848": "698",
    "849": "699",
    "850": "700",
    "851": "701",
    "852": "701",
    "853": "702",
    "854": "703",
    "855": "704",
    "856": "704",
    "857": "705",
    "858": "706",
    "859": "707",
    "860": "708",
    "861": "709",
    "862": "710",
    "863": "710",
    "864": "711",
    "865": "712",
    "866": "713",
    "867": "713",
    "868": "714",
    "869": "715",
    "870": "716",
    "871": "716",
    "872": "717",
    "873": "718",
    "874": "718",
    "875": "719",
    "876": "720",
    "877": "721",
    "878": "721",
    "879": "722",
    "880": "723",
    "881": "724",
    "882": "724",
    "883": "725",
    "884": "726",
    "885": "727",
    "886": "727",
    "887": "728",
    "888": "729",
    "889": "730",
    "890": "730",
    "891": "731",
    "892": "732",
    "893": "732",
    "894": "733",
    "895": "734",
    "896": "735",
    "897": "735",
    "898": "736",
    "899": "737",
    "900": "738",
    "901": "739",
    "902": "740",
    "903": "740",
    "904": "741",
    "905": "742",
    "906": "743",
    "907": "744",
    "908": "744",
    "909": "745",
    "910": "746",
    "911": "747",
    "912": "748",
    "913": "748",
    "914": "749",
    "915": "750",
    "916": "751",
    "917": "752",
    "918": "752",
    "919": "753",
    "920": "754",
    "921": "755",
    "922": "755",
    "923": "756",
    "924": "756",
    "925": "757",
    "926": "758",
    "927": "759",
    "928": "759",
    "929": "760",
    "930": "761",
    "931": "762",
    "932": "763",
    "933": "763",
    "934": "764",
    "935": "765",
    "936": "766",
    "937": "767",
    "938": "767",
    "939": "768",
    "940": "769",
    "941": "770",
    "942": "770",
    "943": "771",
    "944": "771",
    "945": "772",
    "946": "773",
    "947": "774",
    "948": "774",
    "949": "775",
    "950": "776",
    "951": "777",
    "952": "777",
    "953": "778",
    "954": "778",
    "955": "779",
    "956": "780",
    "957": "781",
    "958": "781",
    "959": "782",
    "960": "783",
    "961": "784",
    "962": "785",
    "963": "785",
    "964": "786",
    "965": "787",
    "966": "788",
    "967": "789",
    "968": "789",
    "969": "790",
    "970": "791",
    "971": "792",
    "972": "793",
    "973": "793",
    "974": "794",
    "975": "795",
    "976": "796",
    "977": "797",
    "978": "797",
    "979": "798",
    "980": "799",
    "981": "800",
    "982": "801",
    "983": "801",
    "984": "802",
    "985": "803",
    "986": "804",
    "987": "805",
    "988": "805",
    "989": "806",
    "990": "807",
    "991": "808",
    "992": "809",
    "993": "809",
    "994": "810",
    "995": "811",
    "996": "812",
    "997": "812",
    "998": "813",
    "999": "813",
    "1000": "814",
    "1001": "815",
    "1002": "816",
    "1003": "816",
    "1004": "817",
    "1005": "818",
    "1006": "819",
    "1007": "819",
    "1008": "820",
    "1009": "821",
    "1010": "822",
    "1011": "822",
    "1012": "823",
    "1013": "824",
    "1014": "825",
    "1015": "826",
    "1016": "826",
    "1017": "827",
    "1018": "828",
    "1019": "828",
    "1020": "829",
    "1021": "830",
    "1022": "831",
    "1023": "832",
    "1024": "832",
    "1025": "833",
    "1026": "834",
    "1027": "835",
    "HEA1": "895",
    "HEA2": "930",
    "HEA3": "977",
    "HEB1": "977",
    "HEB2": "1018",
    "HEB3": "1072",
    "HEBB1": "1072",
    "HEBB2": "1100",
    "HEBB3": "1129"
  }
}
var sites = [
  {
    "oaci": "LFBA",
    "nom": "Agen – La garenne",
    "lon": "0.59319",
    "lat": "44.17423",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "47091",
    "mobilite": "0.75"
  },
  {
    "oaci": "LFMA",
    "nom": "Aix Les Milles",
    "lon": "5.36693",
    "lat": "43.50555",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Sud-Est",
    "citycode": "13001",
    "mobilite": "2"
  },
  {
    "oaci": "LFMM",
    "nom": "Aix-En-Provence CRNA Sud Est",
    "lon": "5.44767",
    "lat": "43.5381009",
    "corps": "tous",
    "groupe": "A6",
    "region": "3",
    "pcs": "",
    "transport": "",
    "reorgST": "service",
    "xpRH": "1bis",
    "siege": "0",
    "SNA": "CRNA",
    "citycode": "13001",
    "mobilite": "2"
  },
  {
    "oaci": "LFKJ",
    "nom": "Ajaccio Napoleon Bonaparte",
    "lon": "8.79991",
    "lat": "41.92152",
    "corps": "icna",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Est",
    "citycode": "2A004",
    "mobilite": "1.1"
  },
  {
    "oaci": "LFAQ",
    "nom": "Amiens – Albert-Bray",
    "lon": "2.69548",
    "lat": "49.97075",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord",
    "citycode": "80523",
    "mobilite": ""
  },
  {
    "oaci": "LFLP",
    "nom": "Annecy Meythet",
    "lon": "6.10014",
    "lat": "45.92907",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "74010",
    "mobilite": "1.2"
  },
  {
    "oaci": "LFFF",
    "nom": "Athis-Mons CRNA-Nord",
    "lon": "2.39635",
    "lat": "48.71479",
    "corps": "tous",
    "groupe": "A6",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "service",
    "xpRH": "1bis",
    "siege": "0",
    "SNA": "CRNA",
    "citycode": "91027",
    "mobilite": "2.01"
  },
  {
    "oaci": "DO",
    "nom": "Athis-Mons Direction des Opérations",
    "lon": "2.39675",
    "lat": "48.71415",
    "corps": "tous",
    "groupe": "",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "91027",
    "mobilite": "2.01"
  },
  {
    "oaci": "DNUM",
    "nom": "Athis-Mons DNUM",
    "lon": "2.39376",
    "lat": "48.71567",
    "corps": "tous",
    "groupe": "",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "91027",
    "mobilite": "2.01"
  },
  {
    "oaci": "LFMV",
    "nom": "Avignon-Caumont",
    "lon": "4.90202",
    "lat": "43.90636",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Sud-Est",
    "citycode": "84007",
    "mobilite": "2"
  },
  {
    "oaci": "LFSB",
    "nom": "Bâle-Mulhouse",
    "lon": "7.52435",
    "lat": "47.60324",
    "corps": "tous",
    "groupe": "B",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord-Est",
    "citycode": "68297",
    "mobilite": "0.6"
  },
  {
    "oaci": "LFKB",
    "nom": "Bastia Porreta",
    "lon": "9.48195",
    "lat": "42.55467",
    "corps": "tous",
    "groupe": "D",
    "region": "3",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Est",
    "citycode": "2B148",
    "mobilite": ""
  },
  {
    "oaci": "LFOB",
    "nom": "Beauvais-Tillé",
    "lon": "2.11167",
    "lat": "49.45432",
    "corps": "tous",
    "groupe": "E",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord",
    "citycode": "60639",
    "mobilite": "0.9"
  },
  {
    "oaci": "LFBE",
    "nom": "Bergerac-Dordogne-Périgord",
    "lon": "0.51465",
    "lat": "44.82544",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "24037",
    "mobilite": "0.6"
  },
  {
    "oaci": "LFMU",
    "nom": "Béziers-Cap d’Agde",
    "lon": "3.35545",
    "lat": "43.32272",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Sud-Est",
    "citycode": "34032",
    "mobilite": "1.45"
  },
  {
    "oaci": "LFBZ",
    "nom": "Biarritz pays basque",
    "lon": "-1.52287",
    "lat": "43.46909",
    "corps": "tous",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "64024",
    "mobilite": "2"
  },
  {
    "oaci": "LFBS",
    "nom": "Biscarosse-Parentis",
    "lon": "-1.1306814",
    "lat": "44.3677557",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "40046",
    "mobilite": ""
  },
  {
    "oaci": "STAB",
    "nom": "Bonneuil sur Marne",
    "lon": "2.4907538",
    "lat": "48.773846",
    "corps": "ieeac;tseeac",
    "groupe": "",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "94011",
    "mobilite": "3.2"
  },
  {
    "oaci": "NTTB",
    "nom": "Bora Bora",
    "lon": "208.24746",
    "lat": "-16.44434",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "SEAC-PF",
    "citycode": "98714",
    "mobilite": ""
  },
  {
    "oaci": "CESNAC",
    "nom": "Bordeaux Cesnac",
    "lon": "-0.6935539",
    "lat": "44.8353921",
    "corps": "ieeac;iessa",
    "groupe": "",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "33281",
    "mobilite": "2"
  },
  {
    "oaci": "LFBB",
    "nom": "Bordeaux CRNA-Sud Ouest",
    "lon": "-0.7013179",
    "lat": "44.834297",
    "corps": "tous",
    "groupe": "A6",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "service",
    "xpRH": "1bis",
    "siege": "0",
    "SNA": "CRNA",
    "citycode": "33281",
    "mobilite": "2"
  },
  {
    "oaci": "LFBD",
    "nom": "Bordeaux Mérignac",
    "lon": "-0.71258",
    "lat": "44.82929",
    "corps": "tous",
    "groupe": "B",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "1",
    "SNA": "Sud-Ouest",
    "citycode": "33281",
    "mobilite": "2"
  },
  {
    "oaci": "LFRR",
    "nom": "Brest CRNA-Ouest",
    "lon": "-4.325361",
    "lat": "48.398423",
    "corps": "tous",
    "groupe": "A6",
    "region": "1",
    "pcs": "0",
    "transport": "",
    "reorgST": "service",
    "xpRH": "1bis",
    "siege": "0",
    "SNA": "CRNA",
    "citycode": "29140",
    "mobilite": "0.25"
  },
  {
    "oaci": "LFRB",
    "nom": "Brest-Bretagne",
    "lon": "-4.41737",
    "lat": "48.44769",
    "corps": "tous",
    "groupe": "D",
    "region": "1",
    "pcs": "0",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "29075",
    "mobilite": "2"
  },
  {
    "oaci": "LFSL",
    "nom": "Brives-Souillac",
    "lon": "1.4878147",
    "lat": "45.0417823",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "19147",
    "mobilite": "0.9"
  },
  {
    "oaci": "LFRK",
    "nom": "Caen-Carpiquet",
    "lon": "-0.45504",
    "lat": "49.17594",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "14137",
    "mobilite": "2"
  },
  {
    "oaci": "LFKC",
    "nom": "Calvi Sainte Catherine",
    "lon": "8.79208",
    "lat": "42.52982",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Est",
    "citycode": "2B050",
    "mobilite": ""
  },
  {
    "oaci": "LFMD",
    "nom": "Cannes Mandelieu",
    "lon": "6.95981",
    "lat": "43.54796",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Est",
    "citycode": "6029",
    "mobilite": "1.75"
  },
  {
    "oaci": "LFMK",
    "nom": "Carcassonne Salvaza",
    "lon": "2.30643",
    "lat": "43.21597",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "11069",
    "mobilite": "1.15"
  },
  {
    "oaci": "SOCA",
    "nom": "Cayenne-Félix Eboué",
    "lon": "-52.36305",
    "lat": "4.82068",
    "corps": "icna;iessa",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "3",
    "siege": "0",
    "SNA": "Antilles-Guyane",
    "citycode": "97307",
    "mobilite": "1.8"
  },
  {
    "oaci": "LFOK",
    "nom": "Châlons-Vatry",
    "lon": "4.18769",
    "lat": "48.7763",
    "corps": "tseeac",
    "groupe": "G",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord",
    "citycode": "51595",
    "mobilite": "0.6"
  },
  {
    "oaci": "LFLB",
    "nom": "Chambéry Aix les bains",
    "lon": "5.8818",
    "lat": "45.63522",
    "corps": "tseeac",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "73065",
    "mobilite": "2"
  },
  {
    "oaci": "LFLX",
    "nom": "Chateauroux-Deols",
    "lon": "1.73077",
    "lat": "46.86384",
    "corps": "iessa",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "36063",
    "mobilite": "0.8"
  },
  {
    "oaci": "LFPX",
    "nom": "Chavenay-Villepreux",
    "lon": "1.9817",
    "lat": "48.84176",
    "corps": "tseeac",
    "groupe": "F",
    "region": "3",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "78152",
    "mobilite": "1.6"
  },
  {
    "oaci": "LFLC",
    "nom": "Clermont-Ferrand Auvergne",
    "lon": "3.16048",
    "lat": "45.78501",
    "corps": "icna;iessa;tseeac",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "63019",
    "mobilite": "2"
  },
  {
    "oaci": "LFGA",
    "nom": "Colmar-Houssen",
    "lon": "7.35945",
    "lat": "48.10875",
    "corps": "tseeac",
    "groupe": "G",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord-Est",
    "citycode": "68066",
    "mobilite": "0.8"
  },
  {
    "oaci": "LFRG",
    "nom": "Deauville – Normandie",
    "lon": "0.15532",
    "lat": "49.36554",
    "corps": "icna",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "14220",
    "mobilite": ""
  },
  {
    "oaci": "LFSD",
    "nom": "Dijon BFC",
    "lon": "5.07015",
    "lat": "47.27848",
    "corps": "iessa;tseeac",
    "groupe": "",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord-Est",
    "citycode": "21473",
    "mobilite": "2"
  },
  {
    "oaci": "LFRD",
    "nom": "Dinard-Pleurtuit - Saint Malo",
    "lon": "-2.08109",
    "lat": "48.58873",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "35228",
    "mobilite": ""
  },
  {
    "oaci": "LFGJ",
    "nom": "Dole-Tavaux",
    "lon": "5.42765",
    "lat": "47.04174",
    "corps": "tseeac",
    "groupe": "G",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord-Est",
    "citycode": "39526",
    "mobilite": "0.6"
  },
  {
    "oaci": "LFKF",
    "nom": "Figari Sud Corse",
    "lon": "9.09833",
    "lat": "41.50327",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Est",
    "citycode": "2A114",
    "mobilite": "0.55"
  },
  {
    "oaci": "LFLG",
    "nom": "Grenoble Le Versoud",
    "lon": "5.8485",
    "lat": "45.21815",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "38538",
    "mobilite": "1.3"
  },
  {
    "oaci": "LFLS",
    "nom": "Grenoble-Isère",
    "lon": "5.32998",
    "lat": "45.36238",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "38384",
    "mobilite": ""
  },
  {
    "oaci": "LFBH",
    "nom": "La Rochelle – Ile de Ré",
    "lon": "-1.19147",
    "lat": "46.17698",
    "corps": "icna",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "17300",
    "mobilite": "1.7"
  },
  {
    "oaci": "LFAT",
    "nom": "Le Touquet Côte d’Opale",
    "lon": "1.61903",
    "lat": "50.5189",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord",
    "citycode": "62826",
    "mobilite": "0.9"
  },
  {
    "oaci": "LFQQ",
    "nom": "Lille – Lesquin",
    "lon": "3.10525",
    "lat": "50.56813",
    "corps": "tous",
    "groupe": "D",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "1",
    "SNA": "Nord",
    "citycode": "59343",
    "mobilite": "2"
  },
  {
    "oaci": "LFBL",
    "nom": "Limoges Bellegarde",
    "lon": "1.18018",
    "lat": "45.86308",
    "corps": "tseeac",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "87085",
    "mobilite": "1.8"
  },
  {
    "oaci": "LFPL",
    "nom": "Lognes-Émerainville",
    "lon": "2.6253",
    "lat": "48.82144",
    "corps": "tseeac",
    "groupe": "F",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "77258",
    "mobilite": "2.01"
  },
  {
    "oaci": "LFLY",
    "nom": "Lyon Bron",
    "lon": "4.9427",
    "lat": "45.72819",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "69271",
    "mobilite": "2"
  },
  {
    "oaci": "LFLL",
    "nom": "Lyon Saint Exupéry",
    "lon": "5.08652",
    "lat": "45.72443",
    "corps": "tous",
    "groupe": "A1",
    "region": "0",
    "pcs": "0",
    "transport": "rhone-express:65.30",
    "reorgST": "B",
    "xpRH": "1bis",
    "siege": "1",
    "SNA": "Centre Est",
    "citycode": "69299",
    "mobilite": "2"
  },
  {
    "oaci": "LFML",
    "nom": "Marseille Provence",
    "lon": "5.22005",
    "lat": "43.44353",
    "corps": "tous",
    "groupe": "A1",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "1bis",
    "siege": "1",
    "SNA": "Sud-Sud-Est",
    "citycode": "13054",
    "mobilite": "2"
  },
  {
    "oaci": "TFFF",
    "nom": "Martinique – Aimé Césaire",
    "lon": "-61.00554",
    "lat": "14.59039",
    "corps": "tous",
    "groupe": "D",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "1",
    "SNA": "Antilles-Guyane",
    "citycode": "97213",
    "mobilite": "2"
  },
  {
    "oaci": "FMCZ",
    "nom": "Mayotte-Marcel Henry",
    "lon": "45.28152",
    "lat": "-12.80845",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "0",
    "SNA": "Océan Indien",
    "citycode": "97615",
    "mobilite": ""
  },
  {
    "oaci": "LFPE",
    "nom": "Meaux Esbly",
    "lon": "2.83508",
    "lat": "48.92512",
    "corps": "tseeac",
    "groupe": "G",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "77284",
    "mobilite": "1.6"
  },
  {
    "oaci": "LFPM",
    "nom": "Melun Seine",
    "lon": "2.66567",
    "lat": "48.60948",
    "corps": "icna",
    "groupe": "C",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "77288",
    "mobilite": "2.01"
  },
  {
    "oaci": "LFQT",
    "nom": "Merville-Calonne",
    "lon": "2.64407",
    "lat": "50.61905",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord",
    "citycode": "62502",
    "mobilite": "0.1"
  },
  {
    "oaci": "LFJL",
    "nom": "Metz-Nancy-Lorraine",
    "lon": "6.24377",
    "lat": "48.97836",
    "corps": "tous",
    "groupe": "E",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord-Est",
    "citycode": "57251",
    "mobilite": ""
  },
  {
    "oaci": "LFMT",
    "nom": "Montpellier Méditerrannée",
    "lon": "3.96315",
    "lat": "43.58038",
    "corps": "tous",
    "groupe": "B",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "1bis",
    "siege": "0",
    "SNA": "Sud-Sud-Est",
    "citycode": "34154",
    "mobilite": "1.2"
  },
  {
    "oaci": "NTTM",
    "nom": "Moorea",
    "lon": "210.2170748",
    "lat": "-17.4902854",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "SEAC-PF",
    "citycode": "98729",
    "mobilite": ""
  },
  {
    "oaci": "LFBR",
    "nom": "Muret-Lherm",
    "lon": "1.26481",
    "lat": "43.44943",
    "corps": "tseeac",
    "groupe": "G",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "31395",
    "mobilite": "2"
  },
  {
    "oaci": "LFRS",
    "nom": "Nantes Atlantique",
    "lon": "-1.60846",
    "lat": "47.15184",
    "corps": "tous",
    "groupe": "B",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "1",
    "SNA": "Ouest",
    "citycode": "44109",
    "mobilite": "2"
  },
  {
    "oaci": "LFMN",
    "nom": "Nice Côte d’Azur",
    "lon": "7.21371",
    "lat": "43.6598",
    "corps": "tous",
    "groupe": "A2",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "1bis",
    "siege": "1",
    "SNA": "Sud-Est",
    "citycode": "6088",
    "mobilite": ""
  },
  {
    "oaci": "LFTW",
    "nom": "Nîmes garons",
    "lon": "4.41539",
    "lat": "43.7585",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Sud-Est",
    "citycode": "30189",
    "mobilite": "2"
  },
  {
    "oaci": "NWWW",
    "nom": "Nouméa La Tontouta",
    "lon": "166.21406",
    "lat": "-22.01489",
    "corps": "tous",
    "groupe": "",
    "region": "0",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "DAC-NC",
    "citycode": "98818",
    "mobilite": ""
  },
  {
    "oaci": "NWWM",
    "nom": "Nouméa Magenta",
    "lon": "166.47299",
    "lat": "-22.25761",
    "corps": "tous",
    "groupe": "E",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "DAC-NC",
    "citycode": "98818",
    "mobilite": ""
  },
  {
    "oaci": "LFPO",
    "nom": "Paris - Orly",
    "lon": "2.36784",
    "lat": "48.73006",
    "corps": "tous",
    "groupe": "A6",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20;orlyval:74.70",
    "reorgST": "service",
    "xpRH": "0",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "91479",
    "mobilite": "2.01"
  },
  {
    "oaci": "LFPB",
    "nom": "Paris – Le Bourget",
    "lon": "2.43828",
    "lat": "48.95877",
    "corps": "tous",
    "groupe": "D",
    "region": "3",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "95088",
    "mobilite": "2.01"
  },
  {
    "oaci": "FARM",
    "nom": "Paris – Siège Farman",
    "lon": "2.27152",
    "lat": "48.83399",
    "corps": "tous",
    "groupe": "",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "75115",
    "mobilite": "3.2"
  },
  {
    "oaci": "LFPG",
    "nom": "Paris-Charles de Gaulle",
    "lon": "2.57108",
    "lat": "49.00689",
    "corps": "tous",
    "groupe": "A6",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "service",
    "xpRH": "1bis",
    "siege": "1",
    "SNA": "Région Parisienne",
    "citycode": "93073",
    "mobilite": "3.2"
  },
  {
    "oaci": "LFPI",
    "nom": "Paris-Issy les Moulineaux- Valérie André",
    "lon": "2.2682359",
    "lat": "48.8331171",
    "corps": "tseeac",
    "groupe": "F",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "92040",
    "mobilite": "3.2"
  },
  {
    "oaci": "LFBP",
    "nom": "Pau-Pyrénées",
    "lon": "-0.41327",
    "lat": "43.38206",
    "corps": "tseeac",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "3",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "64511",
    "mobilite": "1.8"
  },
  {
    "oaci": "LFMP",
    "nom": "Perpignan-Rivesaltes",
    "lon": "2.87033",
    "lat": "42.74304",
    "corps": "tseeac",
    "groupe": "F",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Sud-Est",
    "citycode": "66136",
    "mobilite": "1.7"
  },
  {
    "oaci": "TFFR",
    "nom": "Pointe à Pitre-Le Raizet",
    "lon": "-61.52837",
    "lat": "16.26585",
    "corps": "tous",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Antilles-Guyane",
    "citycode": "97101",
    "mobilite": "1.5"
  },
  {
    "oaci": "LFBI",
    "nom": "Poitiers – Biard",
    "lon": "0.30852",
    "lat": "46.58877",
    "corps": "tseeac",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "86194",
    "mobilite": "2"
  },
  {
    "oaci": "LFPT",
    "nom": "Pontoise-Cormeilles en Vexin",
    "lon": "2.03852",
    "lat": "49.09347",
    "corps": "tseeac",
    "groupe": "G",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "95078",
    "mobilite": "1.6"
  },
  {
    "oaci": "LFRQ",
    "nom": "Quimper-Pluguffan",
    "lon": "-4.17041",
    "lat": "47.97139",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "29216",
    "mobilite": "1.25"
  },
  {
    "oaci": "NTTR",
    "nom": "Raiatea",
    "lon": "208.533",
    "lat": "-16.7203851",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "SEAC-PF",
    "citycode": "98758",
    "mobilite": ""
  },
  {
    "oaci": "LFEE",
    "nom": "Reims CRNA-Est",
    "lon": "4.0625225",
    "lat": "49.2327046",
    "corps": "tous",
    "groupe": "A6",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "service",
    "xpRH": "1bis",
    "siege": "0",
    "SNA": "CRNA",
    "citycode": "51454",
    "mobilite": "2"
  },
  {
    "oaci": "LFRN",
    "nom": "Rennes – Saint Jacques",
    "lon": "-1.73019",
    "lat": "48.06891",
    "corps": "tous",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "35281",
    "mobilite": "2"
  },
  {
    "oaci": "LFCR",
    "nom": "Rodez – Marcillac",
    "lon": "2.48528",
    "lat": "44.40698",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "12254",
    "mobilite": ""
  },
  {
    "oaci": "LFOP",
    "nom": "Rouen – Vallée de Seine",
    "lon": "1.18336",
    "lat": "49.38502",
    "corps": "tseeac",
    "groupe": "E",
    "region": "",
    "pcs": "1",
    "transport": "",
    "reorgST": "service",
    "xpRH": "",
    "siege": "1",
    "SNA": "Nord",
    "citycode": "76116",
    "mobilite": "2"
  },
  {
    "oaci": "FMEE",
    "nom": "Saint Denis La Réunion",
    "lon": "55.5186",
    "lat": "-20.89013",
    "corps": "icna;iessa;tseeac",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Océan Indien",
    "citycode": "97411",
    "mobilite": "2"
  },
  {
    "oaci": "LFMH",
    "nom": "Saint Étienne Loire",
    "lon": "4.29605",
    "lat": "45.54021",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Centre Est",
    "citycode": "42005",
    "mobilite": "2"
  },
  {
    "oaci": "LFRZ",
    "nom": "Saint Nazaire-Montoir",
    "lon": "-2.15175",
    "lat": "47.31264",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Ouest",
    "citycode": "44184",
    "mobilite": "2"
  },
  {
    "oaci": "LFVP",
    "nom": "Saint Pierre Et Miquelon",
    "lon": "-56.1777",
    "lat": "46.76273",
    "corps": "icna ;iessa",
    "groupe": "E",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "SPM",
    "citycode": "97502",
    "mobilite": ""
  },
  {
    "oaci": "LFLN",
    "nom": "Saint Yan",
    "lon": "4.01816",
    "lat": "46.41237",
    "corps": "icna",
    "groupe": "E",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Nord-Est",
    "citycode": "71491",
    "mobilite": ""
  },
  {
    "oaci": "LFPZ",
    "nom": "Saint-Cyr-l’École",
    "lon": "2.0703698",
    "lat": "48.8079132",
    "corps": "tseeac",
    "groupe": "F",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "78545",
    "mobilite": "2.01"
  },
  {
    "oaci": "LFST",
    "nom": "Strasbourg-Entzheim",
    "lon": "7.62486",
    "lat": "48.53747",
    "corps": "tous",
    "groupe": "C",
    "region": "1",
    "pcs": "1",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "1",
    "SNA": "Nord-Est",
    "citycode": "67124",
    "mobilite": "2"
  },
  {
    "oaci": "NTAA",
    "nom": "Tahiti",
    "lon": "210.38966",
    "lat": "-17.55418",
    "corps": "tous",
    "groupe": "D",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "SEAC-PF",
    "citycode": "98715",
    "mobilite": ""
  },
  {
    "oaci": "LFBT",
    "nom": "Tarbes Lourdes Pyrénées",
    "lon": "-0.00038",
    "lat": "43.17918",
    "corps": "icna;iessa",
    "groupe": "D",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud-Ouest",
    "citycode": "65440",
    "mobilite": "1.05"
  },
  {
    "oaci": "DTI",
    "nom": "Toulouse DTI",
    "lon": "1.37221",
    "lat": "43.57829",
    "corps": "tous",
    "groupe": "",
    "region": "0",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "31555",
    "mobilite": "2"
  },
  {
    "oaci": "ENAC",
    "nom": "Toulouse ENAC",
    "lon": "1.48193",
    "lat": "43.56506",
    "corps": "tous",
    "groupe": "",
    "region": "0",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "31555",
    "mobilite": "2"
  },
  {
    "oaci": "LFCL",
    "nom": "Toulouse Lasbordes",
    "lon": "1.49927",
    "lat": "43.58716",
    "corps": "tseeac",
    "groupe": "G",
    "region": "",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "Sud",
    "citycode": "31044",
    "mobilite": "2"
  },
  {
    "oaci": "STAC",
    "nom": "Toulouse STAC",
    "lon": "1.37339",
    "lat": "43.58003",
    "corps": "ieeac;iessa;tseeac",
    "groupe": "",
    "region": "0",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "31555",
    "mobilite": "2"
  },
  {
    "oaci": "LFBO",
    "nom": "Toulouse-Blagnac",
    "lon": "1.36081",
    "lat": "43.62998",
    "corps": "tous",
    "groupe": "B",
    "region": "0",
    "pcs": "0",
    "transport": "",
    "reorgST": "B",
    "xpRH": "",
    "siege": "1",
    "SNA": "Sud",
    "citycode": "31069",
    "mobilite": "2"
  },
  {
    "oaci": "LFPN",
    "nom": "Toussus le Noble",
    "lon": "2.10727",
    "lat": "48.75185",
    "corps": "iessa;tseeac",
    "groupe": "F",
    "region": "3",
    "pcs": "1",
    "transport": "navigo:79.20",
    "reorgST": "service",
    "xpRH": "",
    "siege": "0",
    "SNA": "Région Parisienne",
    "citycode": "78620",
    "mobilite": "1.6"
  },
  {
    "oaci": "NLWW",
    "nom": "Wallis et Futuna",
    "lon": "-176.1986",
    "lat": "-13.23978",
    "corps": "iessa",
    "groupe": "",
    "region": "0",
    "pcs": "",
    "transport": "",
    "reorgST": "",
    "xpRH": "",
    "siege": "0",
    "SNA": "",
    "citycode": "",
    "mobilite": ""
  }
]

export {
echelons,
evs,
partTechIEEAC,
evsLevel,
table_indices,
sites
}
