<template>
  <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
  <div class="item_social">
    <p class="see_tooltip" @pointerover="showTooltip=true" @pointerleave="showTooltip=false">?</p>
    <h5>{{title}}</h5>
    <p class="item_social_valeur" :data-value="value">{{value.toFixed(2)}} €</p>
    <p class="item_social_description" v-show="showTooltip">
      <b v-if="ligne">{{ligne}}</b><br>
          {{desc}}
          <em v-if="complement"><br>{{complement}}</em>
    </p>
  </div>
</template>

<style scoped>
.item_social_valeur {
  font-family: var(--title-text);
  font-size: var(--font-size-x2);
  line-height: var(--baseline-x4);
  height: var(--baseline-x4);
  text-align: center;
  text-transform: uppercase;
  background-color: var(--secondary-lt2-color);
  border: 1px solid var(--secondary-color);
  border-radius: var(--baseline-x2);
}

/* Le  ? */
.see_tooltip {
  position: absolute;
  top : var(--baseline-x5);
  left: var(--baseline);
}

/* 1rst adapt Css vite fait, à modifier par la suite */
@media (max-width: 1024px) {
  .item_social_valeur {
    font-size: var(--font-size-normal);
  }
}
@media (max-width: 800px) {
  .item_social_valeur {
    font-size: var(--font-size-x2);
  }
}

@media (max-width: 640px) {
  .item_social_valeur {
    font-size: var(--font-size-normal);
  }
}
@media (max-width: 1024px) and (min-width: 800px) {
  .see_tooltip {
    position: static;
    left: auto;
    top: auto;
    order: 99;
    text-align: center;
  }
}
</style>

<style>
.item_social h5 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 0;
  height: var(--baseline-x4);
}

.item_social_description {
  /*background-color: var(--grey-light-color);*/
  border-radius: var(--baseline);
  background-color: rgba(var(--grey-light-color-split), .85);
  margin: 0 var(--baseline);
  padding: var(--baseline);
  text-align: justify;
  z-index: 99;

  position:absolute;
  top:80%;
  overflow: visible;
}
/* Le ? */
.item_social { position: relative; top: 0;}
.see_tooltip {
  cursor: pointer;
  font-family: var(--effect-text);
  font-size: var(--font-size-x3);
  color: var(--secondary-lt-color);
  text-shadow: 1px 1px 1px rgba( 0, 0, 0, 0.4),
               0 -1px 0 rgba( 255, 255, 255, 0.4);
}
.see_tooltip:hover {
  color: var(--secondary-color);
}
.see_tooltip:active {
  color: var(--font-light-color);
  text-shadow: -1px -1px 1px rgba( 0, 0, 0, 0.4),
               0 1px 0 rgba( 255, 255, 255, 0.4);
}

@media (max-width: 1024px) {
  .see_tooltip {
    font-size: 150%;
  }
  @media (max-width: 1024px) and (min-width: 800px) {
    .item_social h5 {
      height: auto;
      min-height: var(--baseline-x4);
    }
  }
}
@m
</style>

<script setup>
import {
  defineProps,
  onMounted,
  ref,
/*  computed, */
} from 'vue';
// import { useStore } from 'vuex';
// const store = useStore();
defineProps(['title', 'value', 'desc', 'ligne', 'complement']);
const myId = ref();

// const boolTooltipId = computed(() => `boolTooltip${myId.value}`);

const showTooltip = ref(false);

onMounted(() => {
  myId.value = getId();
});

</script>

<script>
export default {
  name: 'ItemSocial',
};

let counter = 0;
function getId() {
  counter += 1;
  return counter;
}
</script>
