
const codeWikiMap = {
    101000: 'Traitement_brut',
    101050: 'Pension_Civile',
    101053: 'Pension Civile N.B.I',
    101070: 'Traitement_brut_N.B.I',
    102000: 'Indemnité de résidence',
    104000: 'Supplément Familial de Traitement',
    200033: 'Remboursement_des_frais_de_transport_domicile-travail',
    200039: 'Remboursement_des_frais_de_transport_domicile-travail',
    200042: 'Allocation_forfaitaire_de_télétravail_(AFT)',
    200113: 'Indemnité de difficulté administrative (IDA)',
    200141: 'Majoration et indexation de la rémunération en outre-mer',
    200142: 'Majoration et indexation de la rémunération en outre-mer',
    200143: 'Majoration et indexation de la rémunération en outre-mer',
    201768: 'Indemnité de sujétion géographique',
    201958: 'RIST_Part_fonction',
    201959: 'RIST Part expérience professionnelle',
    201960: 'RIST Part Licence de contrôle ISQ',
    201961: 'RIST Part Licence de contrôle ISQ',
    201962: 'RIST_compléments_pour_Expérimentations',
    201969: 'RIST Part expérience professionnelle',
    201986: 'RIST Majoration Géographique',
    201987: 'RIST Majoration Géographique',
    201988: 'RIST Part Licence de contrôle ISQ',
    201989: 'RIST Part Etudes et Exploitation',
    201990: 'RIST Part évolution des qualifications',
    201991: 'RIST Part Qualification et Habilitation',
    201992: 'RIST Part Qualification et Habilitation',
    202206: 'Indemnité_de_compensation_de_la_hausse_CSG',
    202354: 'Participation à la Protection Sociale Complémentaire (PSC)',
    401201: 'Contribution Sociale Généralisée non déductible',
    401301: 'Contribution Sociale Généralisée',
    401501: 'Contribution à la réduction de la dette sociale',
    403301: 'Complément allocation familiale',
    403501: 'Contribution fond national d\'aide au logement',
    403801: 'Contribution Solidarité Autonomie',
    404001: 'Contribution patronale maladie déplafonnée',
    404598: 'Forfait social',
    411050: 'Part patronale de la cotisation retraite',
    411053: 'Part patronale de la cotisation retraite',
    411058: "Contribution à l'allocation temporaire d'invalidité",
    415000: 'Cotisation patronale titres restaurant',
    501080: 'Cotisation salariale RAFP',
    501180: 'Cotisation patronale RAFP',
    545500: 'Contribution patronale au versement mobilité',
    558000: 'Impôt sur le revenu prélevé à la source',
    604970: 'Transfert Primes / Points',
    751095: '24.6% ISQ',
}

export class Payline {
    constructor(shortname,ref,desc,amount, sign) {
        this.shortname = shortname;
        this.ref = ref;
        this.desc = desc;
        this.amount = Math.round(amount * 100) / 100;
        this.sign = sign ? sign : '+';
    }
    get wikiTitle() {
      return codeWikiMap[this.ref];
    }
    get wikilink() {
        if (this.wikiTitle) {
            return `https://usac-cgt.org/wiki/index.php?title=${this.wikiTitle}`;
        }
        return undefined;
    }

    async wikiextract(charNumber = 100) {
        if (this.extract === undefined && this.wikiTitle) {
            const urlApi = `https://usac-cgt.org/wiki/api.php?action=query&prop=extracts&exchars=${charNumber}&explaintext=1&exlimit=1&titles=${this.wikiTitle}&formatversion=2&format=json&redirects`;
            try {
                const response = await fetch(urlApi);
                const json = await response.json();
                this.extract = json.query.pages[0].extract;
            } catch (err) {
                console.log(err);
                this.extract = null;
            }
        }
        return this.extract;
    }

    toString() {
        let valOffset = {
            "+": "",
            "horsimpot": "",
            "-": "\t",
            "0": "\t\t"
        }
        return `${this.ref}\t${this.desc.padEnd(20).substring(0,20)}\t${valOffset[this.sign]}${this.amount}`
    }
}
