

export const modulationGeoIEEAC = {
    "2017" : {
        "10": 61.14,
        "11": 68.25,
        "12": 80.04,
        "13": 85.28,
        "14": 89.46,
        "15": 93.31
    }
};

export const partExperience = {
    "2017": {
        //Personnels stagiaires
        "1": 258.44,
        //TSEEAC N
        "2": 309.84,
        //TSEEAC P ou N avec 1e qualification + 1 an, IESSA N, ICNA N
        "3": 361.46,
        //TSEEAC E ou P avec 2e qualification + 1 an, IESSA P, ICNA P et IEEAC N
        "4": 432.85,
        //IESSA D ou C, ICNA D ou C, IEEAC P ou HC, RTAC ou CTAC
        "5": 532.77
    },
    "2023": {
        //Personnels stagiaires
        "1": 267.49,
        //TSEEAC N
        "2": 320.68,
        //TSEEAC P ou N avec 1e qualification + 1 an, IESSA N, ICNA N
        "3": 374.11,
        //TSEEAC E ou P avec 2e qualification + 1 an, IESSA P, ICNA P et IEEAC N
        "4": 448.00,
        //IESSA D ou C, ICNA D ou C, IEEAC P ou HC, RTAC ou CTAC
        "5": 551.42,
    },
    "2024": {
        //Personnels stagiaires
        "1": 271.50,
        //TSEEAC N
        "2": 325.49,
        //TSEEAC P ou N avec 1e qualification + 1 an, IESSA N, ICNA N
        "3": 379.72,
        //TSEEAC E ou P avec 2e qualification + 1 an, IESSA P, ICNA P et IEEAC N
        "4": 454.72,
        //IESSA D ou C, ICNA D ou C, IEEAC P ou HC, RTAC ou CTAC
        "5": 559.69,
    },
};

//niveau Montant Conditions
//1 170,00 Titulaires de la QT
//2 689,99 Titulaires de la QTS et service non réorganisé
//3 744,18 Titulaires de la QTS et service réorganisé
//4 1 033,19 Titulaires de la QTS + 10 ans et service non réorganisé
//5 1 126,40 Titulaires de la QTS + 10 ans et service réorganisé

export const partTechIESSA = {
    2019: {
        1: 170.00,
        2: 689.99,
        3: 744.18,
        4: 1033.19,
        5: 1126.40
    },
    2023: {
        1: 175.95,
        2: 714.14,
        3: 770.23,
        4: 1069.35,
        5: 1165.82,
    },
    2024: {
        1: 194.10,
        2: 787.81,
        3: 849.69,
        4: 1179.66,
        5: 1286.09,
    },
}

export const partTechIESSALevel = [
    {name: "Titulaires de la QT", partTechIESSA: 1},
    {name: "Titulaires de la QTS et service non réorganisé", partTechIESSA:2},
    {name: "Titulaires de la QTS et service réorganisé", partTechIESSA: 3},
    {name: "Titulaires de la QTS + 10 ans et service non réorganisé", partTechIESSA: 4},
    {name: "Titulaires de la QTS + 10 ans et service réorganisé", partTechIESSA: 5},
];

export const partTechTSQualif = {
    2016: {
        1: 20,
        2: 236.43,
        3: 462.85,
        4: 701.96,
    },
    2018: {
        1: 20,
        2: 236.43,
        3: 462.85,
        4: 713.96,
    },
    2019: {
        1: 20,
        2: 236.43,
        3: 462.85,
        4: 725.96,
    },
    2023: {
        1: 20.70,
        2: 244.71,
        3: 479.05,
        4: 751.37,
    },
    2024: {
        1: 21.01,
        2: 248.38,
        3: 486.24,
        4: 762.64,
    },
}

export const partTechTSQualifLevel = [
    {name: "Détenteur de la 1re Qualification", partTechTSQualif: 1},
    {name: "Détenteur de la 2e Qualification < 4 ans", partTechTSQualif: 2},
    {name: "Détenteur de la 2e Qualification depuis ≥ 4 ans", partTechTSQualif: 3},
    {name: "Détenteur de la 2e Qualification depuis ≥ 4 ans ET sur emploi CTAC", partTechTSQualif: 4},
]


export const partTechTSHabil = {
    2016: {
        1: 50.00,
        2: 124.44,
        3: 150.00,
        4: 162.45,
        5: 216.06,
        6: 250.00,
        7: 324.88,
        8: 360.55,
        9: 432.12,
        10: 485.22,
    },
    2023: {
        1: 51.75,
        2: 128.80,
        3: 155.25,
        4: 168.14,
        5: 223.62,
        6: 258.75,
        7: 336.25,
        8: 373.17,
        9: 447.24,
        10: 502.20,
    },
    2024: {
        1: 52.53,
        2: 130.73,
        3: 157.58,
        4: 170.66,
        5: 226.97,
        6: 262.97,
        7: 341.29,
        8: 378.77,
        9: 453.95,
        10: 509.73,
    },
}

export const partTechTSHabilLevel = [
    {name: "Détenteur du niveau 1 de la licence de surveillance", partTechTSHabil: 1},
    {name: "Détenteur du certificat d’aptitude et AE dans les domaines énergie et climatisation", partTechTSHabil: 2},
    {name: "Habilitation contrôleur multi-système au CESNAC", partTechTSHabil: 2},
    {name: "Détenteur du niveau 2 de la licence de surveillance", partTechTSHabil: 3},
    {name: "Détenteur qualification de contrôles techniques d’aéronefs < 3 ans", partTechTSHabil: 4},
    {name: "Détenteur qualification de contrôles techniques d’aéronefs depuis < 3 ans  et exerçant en DSAC/N ou DSAC/NE", partTechTSHabil: 5},
    {name: "Détenteur du niveau 3 de la licence de surveillance", partTechTSHabil: 6},
    {name: "Détenteur qualification de contrôles techniques d’aéronefs ≥3 ans", partTechTSHabil: 7},
    {name: "Détenteur habilitation à rendre l’information de vol en CRNA (BTIV)", partTechTSHabil: 8},
    {name: "Détenteur habilitation à gérer l’aire de trafic à Roissy", partTechTSHabil: 8},
    {name: "Détenteur qualification de contrôles techniques d’aéronefs ≥ 3 ans  et exerçant en DSAC/N ou DSAC/NE", partTechTSHabil: 9},
    {name: "Détenteur habilitation coordonnateur en DCC", partTechTSHabil: 10},
]


export const expe2016 = {
    2016: {
        "iessa": {
            0:0,
            "A":40,
            "B":60,
            "service":100
        }
    },
    2023: {
        "iessa": {
            0:0,
            "A":41.4,
            "B":62.1,
            "service":103.5
        }
    },
    2024: {
        "iessa": {
            0:0,
            "A":43.06,
            "B":64.58,
            "service":107.64
        }
    },
}

export const expe2016IessaLevel = {
    0:"Non réorganisé",
    "A":"Stade A",
    "B":"Stade B",
    "service":"Stade Projet de service"
}


export const partLicence = {
    2017: {
        1: 858.88,
        2: 1132.26,
        3: 1238.82,
        4: 1025.71,
        5: 1185.54
    },
    2023: {
        1: 896.19,
        2: 1171.89,
        3: 1282.18,
        4: 1061.61,
        5: 1227.03
    },
    2024: {
        1: 932.04,
        2: 1258.42,
        3: 1376.85,
        4: 1077.53,
        5: 1276.11
    },
}

export const partLicenceLevel = [
    {name: "Suivant un PFU à Roissy ou Orly et détenant ou ayant détenu la mention LOC", partLicence: 1},
    {name: "Exerçant dans un groupe G", partLicence:2},
    {name: "Exerçant dans un groupe F", partLicence: 3},
    {name: "PC exerçant dans un groupe D ou E avec toutes les MU, ICNA titulaire Suivant un PFU"+
           "à Roissy ou Orly et détenant ou ayant détenu la mention LOC", partLicence: 4},
    {name: "PC exerçant dans un groupe A, B ou C avec toutes les MU", partLicence: 5},
];

export const complementPartLicence = {
    2019: {
        "E": 845.81,
        "D": 909.54,
        "C": 1275.50,
        "B": 1333.71,
        "A1": 1747.94,
        "A2": 1808.68,
        "A3": 1897.06,
        "A4": 1957.80,
        "A5": 1990.95,
        "A6": 2025.51
    },
    2023: {
        "E": 875.41,
        "D": 941.37,
        "C": 1320.14,
        "B": 1380.39,
        "A1": 1809.12,
        "A2": 1871.98,
        "A3": 1963.46,
        "A4": 2026.32,
        "A5": 2060.63,
        "A6": 2096.40,
    },
    2024: {
        "E": 976.36,
        "D": 1051.15,
        "C": 1436.88,
        "B": 1502.45,
        "A1": 1969.09,
        "A2": 2037.51,
        "A3": 2137.08,
        "A4": 2205.50,
        "A5": 2242.84,
        "A6": 2281.78,
    }
}

export const xpRH2016 = {
    2017: {
        "1":{
            groups: [["LFPG"], "default"],
            "0": 550,
            "1": 500,
        },
        "1bis": {
            groups: [
                ["LFEE", "LFLL"],
                ["LFRR", "LFBB", "LFMM"],
                ["LFPG"],
                "default"
            ],
            "0": 125,
            "1": 170,
            "2": 170,
            "3": 250,
        },
        "2": 255,
        "3": 255,
        "4": 500,
        "5": 255,
        "bonus1bis": {
            "0": 750,
            "1": 750,
            "2": 825,
            "3": 750,
        },
    },
    2023: {
        "1":{
            groups: [["LFPG"], "default"],
            "0": 569.25,
            "1": 517.5,
        },
        "1bis": {
            groups: [
                ["LFEE", "LFLL"],
                ["LFRR", "LFBB", "LFMM"],
                ["LFPG"],
                "default"
            ],
            "0": 129.38,
            "1": 175.95,
            "2": 175.95,
            "3": 258.75,
        },
        "2": 263.93,
        "3": 263.93,
        "4": 517.5,
        "5": 263.93,
        "bonus1bis": {
            "0": 776.25,
            "1": 776.25,
            "2": 853.88,
            "3": 776.25,
        },
    },
}
