<template>
  <div class="evs-selector">
    <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
    <!-- eslint-disable vuejs-accessibility/label-has-for -->

    <div class="fonctions-evs" v-if="likelyFonctions.length > 0">
      <template v-for="fonction in likelyFonctions" :key="fonction.nom">
          <button
            :data="fonction.niveau"
            :title="fonction.desc"
            @click="copyNiveauToInput($event)">
              {{fonction.nom}}
          </button>
    </template>
    </div>
    <div class="evsPlaceholder" v-else-if="grade != 'eleve'">
      <div class="placeholderButtons fonctions-evs">
        <button>Grouillot</button>
        <button>Sous-chef</button>
        <button>Chef</button>
      </div>
      <div class="overPlaceholder">Pas de suggestion.
        {{ grade == 'élève' && site.oaci != 'ENAC' ? "Rentrez votre grade." : "" }}</div>
    </div>
    <div class="list-selection">
      <label @click="showFuzzy ^= true" class="showHide">Toutes les fonctions...</label>
      <div class="fuzzy-container" v-if="showFuzzy">
        <fuzzy-select
          :items="dp.evsLevel.map((level) => {
            return {label:level.name,title:level.evs}
          })"
          prompt="Recherche de fonction :"
          placeholder="Tapez le nom de la fonction"
          @idxSelected="receiveChange" />
      </div>
    </div>
    <AnimatedTextInput label="Niveau" v-model="internal" />
  </div>
</template>

<style scoped>
/* layout */
.evs-selector {
  grid-column-start: auto;
  grid-column-end: span 4;
}

#stub-evs {
    position:absolute;
    top:20%;
    margin: auto;
    width: 70%;
    left: 1em;
    right: 1em;
    font-weight: bold;
}

.fuzzy-container {
  max-height: 13em;
  overflow: auto;
  border: solid 1px;
  margin: .2em;
}

.fuzzy-container > label {
  font-size: 80%;
}

.showHide {
  text-decoration: underline;
}
.evsPlaceholder {
  position: relative;
  width:100%;
  padding-top:.1em;
  padding-bottom: .1em;
}
.overPlaceholder {
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  text-align: center;
  background-color: rgba(255,255,255,.75);
  backdrop-filter: blur(.5px);
  color:black;
}
</style>

<style>
label.fuzzy-result {
  font-size: 80%;
  line-height: 100%;
}
</style>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

import { getLikelyFonction } from '../../../../../js/evs';
import FuzzySelect from '../widgets_general/FuzzySelect.vue';
import AnimatedTextInput from '../widgets_general/AnimatedTextInput.vue';

const store = useStore();
const dp = computed(() => store.getters.paysheet.dp);

const internal = ref(0);
const showFuzzy = ref(false);
const props = defineProps({
  corps: {
    type: String,
  },
  grade: { type: String },
  echelon: { type: Number },
  site: {
    type: Object,
  },
});

const likelyFonctions = computed(
  () => getLikelyFonction(props.corps, props.site, props.grade, props.echelon),
);

const emit = defineEmits(['evs']);

watch(internal, (newV, oldV) => emit('evs', internal.value));

function copyNiveauToInput(e) {
  internal.value = parseInt(e.target.getAttribute('data'), 10);
}

function receiveChange(idxSelected) {
  if (Number.isNaN(parseInt(idxSelected, 10))) {
    throw new Error('should not happen');
  }
  internal.value = dp.value.evsLevel[idxSelected].evs;
}
</script>

<script>
export default {
  components: { FuzzySelect, AnimatedTextInput },
  name: 'EVSSelector',
};
</script>
