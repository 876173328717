<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <!-- eslint-disable vuejs-accessibility/label-has-for -->

  <div class="form-block">
    <div class="carriere">
      <h3>Carrière</h3>
      <div class="steps">
        <template v-for="(grille, idx) in grilles" :key="idx">
          <div class="corps-label" :style="`grid-row:${idx+1}`">
            {{grille[0]}}
          </div>
        </template>
        <template v-for="step in steps" :key="step">
        <div
          :class="step.genClasses()"
          :style="step.genStyle()"
          :title="`${step.grade}-${step.echelon}&#010;`
           + `indice brut ${step.indice}&#010;`
           + `indice majoré ${step.indiceMajo}`"
          @click="$emit('carriere',{
            grade:step.grade,
            echelon:step.echelon,
            indiceMajo:step.indiceMajo,
          })">
          {{step.echelon}}
        </div>
        </template>
        <div class="separator-ef" :style="`grid-row:${grilles.length+1}`"/>
        <template v-for="(grille, idx) in grillesEF" :key="idx">
          <div class="corps-label" :style="`grid-row:${idx + grilles.length + 2}`"
            v-if="showThisEF(grille[0])">
            {{grille[0]}}
          </div>
        </template>
        <template v-for="step in stepsEF" :key="step">
        <div :class="step.genClasses()" v-if="showThisEF(step.grade)"
          :style="step.genStyle(grilles.length + 1)"
          :title="`${step.grade}-${step.echelon}&#010;`
           + `indice brut ${step.indice}&#010;`
           + `indice majoré ${step.indiceMajo}`"
          @click="$emit('carriere',{
            grade:'détaché',
            detachement:step.grade,
            echelon:step.echelon,
            indiceMajo:step.indiceMajo,
          })">
          {{step.echelon}}
        </div>
        </template>
        <div :style="'grid-row:'+(grilles.length + grillesEF.length + 2)+';grid-column: span 1000'"
          class="showHideContainer">
          <label @click="showCST ^= true" class="showHide"
                 title="chef de service grand centre, chef de centre/organisme et au dessus"
                 v-if="corps != 'tseeac'">
            <span v-if="showCST">▲▲▲</span>
            <span v-else>▼▼▼</span>
            <!-- Grand Manitou -->
            <span v-if="showCST">▲▲▲</span>
            <span v-else>▼▼▼</span>
          </label>
        </div>
      </div>
      <!-- <div class="debug">
        <template v-for="step in steps" :key="step">
        <div :class="'step ' + step.grade">
            {{step.grade}}/{{step.echelon}} : {{step.indice}} ({{step.nextIndice}})
        </div>
        </template>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
/* layout */
.carriere {
  display:block;
}
.debug {
    display:flex;
    flex-direction: column;
}
.steps {
    display: grid;
    grid-template-columns: repeat(730, 1fr);
    grid-template-rows: repeat(5, 1fr);
}
.step {
    display: block;
    border:solid 1px;
    color:white;
    cursor: pointer;
}
/* FIXME computed layout*/
.steps > .corps-label:nth-child(1) {
    grid-column: 100;
}
.steps > .corps-label:nth-child(2) {
    grid-column: 600;
}

.élève {
    background-color: hsl(300, 100%, 67%);
}
.stagiaire {
    background-color: hsl(286, 100%, 67%);
}
.normal {
    background-color: hsl(272, 100%, 67%);
}
.principal {
    background-color: hsl(258, 100%, 67%);
}
.divisionnaire {
    background-color: hsl(244, 100%, 67%);
}
.chef, .exceptionnel, .hors {
    background-color: hsl(230, 100%, 67%);
}

.separator-ef {
  background-color: black;
}

.RTAC {
    background-color: hsl(55, 100%, 67%);
}
.CTAC {
    background-color: hsl(45, 100%, 67%);
}
.CSTAC {
    background-color: hsl(35, 100%, 67%);
}
.CUTAC {
    background-color: hsl(25, 100%, 67%);
}
.CST {
    background-color: hsl(15, 100%, 67%);
}
.CSTP {
    background-color: hsl(5, 100%, 67%);
}

.step:hover {
  background-color: var(--secondary-color);
}

.step.selstep {
  border:solid 2px;
  color: black;
  border-color: var(--secondary-color);
  z-index:1;
}

.showHideContainer {
  text-align: center;
}
.showHide {
  text-decoration: underline;
}

</style>

<!-- eslint-disable prefer-destructuring -->
<!-- eslint-disable radix -->

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const { input } = store.state;
const dp = computed(() => store.getters.paysheet.dp);

const props = defineProps({
  corps: { type: String },
});

const grilles = computed(() => Object.entries(dp.value.getGrille(props.corps)));

const grillesEF = computed(() => dp.value.detachementsByCorps[props.corps].map(
  (ef) => [ef, dp.value.getEchelons(props.corps, 'détaché', ef)],
));

const showCST = ref(false);

function getSteps(g) {
  const output = [];
  g.forEach(([grade, echelons], idxGrade) => {
    Object.entries(echelons).forEach(([echelon, indice], indexEchelon) => {
      const step = new Step(
        grade,
        echelon,
        indice,
        idxGrade,
      );
      if (Object.values(echelons)[indexEchelon + 1]) {
        step.nextIndice = Object.values(echelons)[indexEchelon + 1];
      } else if (g[idxGrade + 1]) {
        step.nextIndice = parseInt(Object.values(g[idxGrade + 1][1])
          .filter((ind) => parseInt(ind, 10) > parseInt(indice, 10))[0]);
      }
      output.push(step);
    });
  });
  return output;
}

const steps = computed(() => getSteps(grilles.value));
const stepsEF = computed(() => getSteps(grillesEF.value));

class Step {
  constructor(
    grade,
    echelon,
    indice,
    idxGrade,
  ) {
    this.grade = grade;
    this.echelon = echelon;
    this.indice = parseInt(indice) ? parseInt(indice) : indice;
    this.idxGrade = idxGrade;
  }

  get indiceMajo() {
    return parseInt(dp.value.table_indices[this.indice]);
  }

  genClasses() {
    const classes = ['step', this.grade];
    if ((this.grade === input?.grade
    || (input?.grade === 'détaché'
        && this.grade === input?.detachement))
     && this.echelon === input?.echelon.toString()) {
      classes.push('selstep');
    }
    return classes.join(' ');
  }

  genStyle(rowOffset = 0) {
    const columnEnd = this.nextIndice
      ? `${Step.scaleIndice(this.nextIndice)}`
      : 'span 30';
    const output = [
      `grid-column:${Step.scaleIndice(this.indice)}/${columnEnd}`,
      `grid-row:${this.idxGrade + rowOffset + 1}`,
    ].join(';');
    return output;
  }

  static scaleIndice(indBrut) {
    const ind = parseInt(dp.value.table_indices[indBrut]);
    const minIndice = 313;
    const maxIndice = 1129;
    const columns = maxIndice - minIndice;
    // const curve = (x) => (x < 100 ? 0.7 * x : x - 70);
    const curve = (x) => x;
    return Math.round(curve(ind - minIndice));
  }
}

defineEmits(['carriere']);

function showThisEF(ef) {
  return !(['CST', 'CSTP'].includes(ef)) || showCST.value;
}

</script>

<script>
export default {
  name: 'CarriereGeneric',
};
</script>
