<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="number-input">
    <label :for="myId">{{label}}</label>
    <input type=number :id="myId" :name="myId"
      @change="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      min="0"
    >
  </div>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  ref,
  defineEmits,
} from 'vue';

defineProps(['label', 'modelValue']);
const myId = ref();

onMounted(() => {
  myId.value = `NumberInput${getId()}`;
});

defineEmits(['change', 'update:modelValue']);
</script>

<script>
export default {
  name: 'NumberInput',
};

let counter = 0;
function getId() {
  counter += 1;
  return counter;
}
</script>
