<template>
    <AnimatedSelector
      label="Part Technique"
      title = "ex-PEQ"
      :modelValue="internal"
      :items="partTechIESSALevels"
      @update:modelValue="handleChange($event)"
    />
</template>

<style scoped>
</style>

<script setup>
import {
  computed,
  defineEmits,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';

import AnimatedSelector from '@/components/input/widgets_general/AnimatedSelector.vue';

const store = useStore();
const dp = computed(() => store.getters.paysheet.dp);
const props = defineProps(['modelValue']);

const internal = ref(props.modelValue);

const partTechIESSALevels = computed(() => [...dp.value.partTechIESSALevel].map((item) => ({
  desc: item.name,
  value: item.partTechIESSA,
})));

const emit = defineEmits(['update:modelValue']);
watch(() => props.modelValue, (nVal) => {
  internal.value = nVal;
}, {
  immediate: true,
});

function handleChange(e) {
  emit('update:modelValue', e);
  [internal.value] = e;
}

</script>

<script>
export default {
  name: 'PEQIessa',
};
</script>
