<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <ul class="gnav-tabs">
    <li
      :class="{'gtab-button': true, active: tab == 'social'}"
      @click="tab='social'"
      data-tab="social"
      >
      <a>Explications</a>
    </li>
    <li
      :class="{'gtab-button': true, active: tab == 'feuille'}"
      @click="tab='feuille'"
      data-tab="feuille"
      >
      <a>Fiche de paye</a>
      <img class="icon" src="@/assets/images/icon_BP.gif" alt="Vignette feuille de paye"/>
    </li>
  </ul>

  <article id="social" class="gtab" v-if="tab=='social'">
    <ViewerSocial />
  </article>

  <article id="feuille" class="gtab" v-if="tab=='feuille'">
    <ViewerSheet />
  </article>
</template>

<style>
.gnav-tabs {
  border-bottom: 1px solid var(--secondary-color); ;
}
.gtab-button {
  margin-bottom: -1px;
  border-radius: .2em .2em 0 0;
  padding:.2em 1em .2em 1em;
  cursor: pointer;
  border:1px solid #ccc;
  border-bottom-color: transparent;
  display: flex;
  align-items: center;
}
.gtab-button.active {
  border:1px solid var(--secondary-color);
  border-bottom-color: transparent;
  background-color: #fff;
}
.gtab-button:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  border-bottom-color: transparent;
  color:white;
}
.gtab-button.active:hover {
  background-color: white;
  color:unset;
}

ul.gnav-tabs {
  display:flex;
  list-style: none;
}
.gtab{
  display: grid;
  grid-template-columns: subgrid;
  gap: var(--baseline-x2);
}

img.icon {
  height: 2em;
  margin-left: .4em;
}
</style>

<script>
import ViewerSheet from '@/components/result/ViewerSheet.vue';
import ViewerSocial from '@/components/result/ViewerSocial.vue';

import { ref } from 'vue';

export default {
  name: 'GnavTabs',
  components: [
    ViewerSocial,
    ViewerSheet,
  ],
};
</script>

<script setup>
const tab = ref('feuille');
</script>
