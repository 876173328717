<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
        <div class="section">
          <h3 id="remuneration">Rémunération</h3>

          <div class="moitier">
            <h4>Rémunération de base (RB)</h4>
            <item-social
              title="Traitement indiciaire"
              :value="paysheet.traitement_brut"
              ligne="101000 TRAITEMENT BRUT"
              desc="IM * Valeur du point"
              />
            <item-social
              title="NBI"
              :value="paysheet.nbi"
              ligne="101070 TRAITEMENT BRUT N.B.I."
              desc="NBI * Valeur du point"
              complement="Attribué sous conditions d'âge"
              />
          </div>

          <div class="moitier">
            <h4>Primes et indemnités</h4>

            <item-social
              title="Indemnité de résidence"
              :value="paysheet.indem"
              ligne="102000 INDEMNITE DE RESIDENCE"
              desc="0, 1 % ou 3 % du RB (selon commune d'affectation)"
              />
            <item-social
              title="Supplément Familial de Traitement"
              :value="paysheet.sft"
              ligne="104000 SUPP FAMILIAL TRAITEMENT"
              desc="Part fixe + un % du RB"
              complement="nombre d'enfants à charge de moins de 20ans"
              />
            <item-social
              title="Indemnité de difficultés administratives"
              :value="paysheet.lines[200113].amount"
              ligne="200113 IND. DIFFICULTES ADMINS."
              desc="1€83(IB&lt;341) / 2€28 (342 à 770) / 3€05 > 770"
              complement="Spécifique Alsace-Moselle"
              />
            <item-social
              title="Transfert primes / points"
              :value="paysheet.lines[604970].amount"
              ligne="604970 TRANSFERT PRIMES/POINTS"
              desc="Réduction des primes en échange de quelques points d'indice
              supplémentaire (2016-2019)"
              />
          </div>
        </div>
        <div class="section">
          <h3 id="conquetes_sociales">Conquêtes sociales</h3>

          <div class="quart">
            <h4 class="regime_general">
              CNAM
              <span class="small"
                    >branche maladie, branche accident du travail et maladie pro
                (AT/MP)</span
              >
            </h4>

              <SocialDouble
                title="Assurance maladie"
                value_sal = "----"
                :value_pat = "paysheet.lines[404001].amount"
                ligne="404001 COT PAT MALADIE DEPLAFON"
                />
              <SocialDouble
                title="Accident du travail"
                value_sal = "----"
                :value_pat = "paysheet.lines[411058].amount"
                ligne="411058 CONTRIBUTION ATI"
                />
          </div>
          <div class="quart">
            <h4 class="regime_general">
              CNAF
              <span class="small">branche famille (dont handicap, logement)</span>
            </h4>

              <SocialDouble
                title="Allocation familliale"
                value_sal = "----"
                :value_pat = "paysheet.lines[403301].amount"
                ligne="403301 COTIS PATRON. ALLOC FAMIL"
                />
              <SocialDouble
                title="Aides au logement"
                value_sal = "----"
                :value_pat = "paysheet.lines[403501].amount"
                ligne="403501 COT PAT FNAL DEPLAFONNEE"
                />

          </div>
          <div class="quart">
            <h4 class="regime_general">
              CNSA
              <span class="small">branche autonomie</span>
            </h4>

              <SocialDouble
                title="Autonomie"
                value_sal = "----"
                :value_pat = "paysheet.lines[403801].amount"
                ligne="403801 CONT SOLIDARITE AUTONOMIE"
                />
          </div>
          <div class="quart">
            <h4 class="regime_general">Impôts de financement</h4>

              <SocialDouble
                title="CSG déductible"
                :value_sal = "paysheet.lines[401301].amount"
                value_pat = "----"
                ligne="401301 C.S.G. DEDUCTIBLE"
                desc="Contribution Sociale Généralisée. Les cotisations salariales ont été
                progressivement remplacées par la CSG à partir de 1991. Depuis 2018, les cotisations
                salariales à la sécurité sociale ne sont plus séparées par branche et passent
                exclusivement par la CSG"
                />
              <SocialDouble
                title="CSG non déductible"
                :value_sal = "paysheet.lines[401201].amount"
                value_pat = "----"
                ligne="401201 C.S.G. NON DEDUCTIBLE"
                />
              <SocialDouble
                title="CRDS"
                :value_sal = "paysheet.lines[401501].amount"
                value_pat = "----"
                ligne="401501 C.R.D.S."
                desc="Contribution pour le Remboursement de la Dette Sociale."
                comp="0.5% des revenus"
                />
          </div>
          <h4 class="retraite col8">
            Régime spécial de retraite des fonctionnaires civils et militaires
          </h4>

          <div class="value_retraite">
            <SocialDouble
              title="Retraite de base"
              :value_sal = "paysheet.lines[101050].amount+paysheet.lines[101053].amount"
              :value_pat = "paysheet.lines[411050].amount+paysheet.lines[411053].amount"
              ligne="101050 RETENUE PC
              101053 RETENUE PC NBI
              411050 CONTRIB.PC
              411053 CONTRIB.PC NBI"
              desc="Cotisation retraite et indication pour la «part employeur»"
              />
            <SocialDouble
              title="Retraite complémentaire"
              :value_sal = "paysheet.lines[501080].amount"
              :value_pat = "paysheet.lines[501180].amount"
              ligne=" 501080 COT SAL RAFP
              50180 COT PAT RAFP"
              desc="RAFP Régime Additionnel de la Fonction Publique"
              comp="5 % du total des primes"
              />
          </div>

          <div id="explication_retraite">
            <p>
              Pour info, le régime de retraite de base de la fonction publique d'état
              ne relève pas d'un régime par répartition. Elle est intégralement pris
              en charge par l'État par le biais du Service des Retraites de l’État. Il
              n'existe pas de caisse dédié et les chiffres ci-dessus ("retraite de
              base") ne sont que de l'écriture comptable qui ne donne lieu a aucun
              flux financier.
            </p>
            <figure>
              <blockquote>
                On ne peut pas dire que les actifs payent pour les retraités, l'état
                affecte une partie de son budget aux pensions des fonctionnaires. Ce
                budget est indépendant du nombre de fonctionnaire actif.
              </blockquote>
              <figcaption>
                Voir :
                <a
                  href="https://www.ufsecgt.fr/IMG/pdf/Retraites-fonctionnaire-niveau_retraites_cotis-7avril-v3.pdf"
                  >Retraites : pourquoi les fonctionnaires ne sont pas des privilégiés
                  !</a
                >
                (UFSEcgt, 2010)
              </figcaption>
            </figure>
            <p>
              La retraite complémentaire est un régime obligatoire par capitalisation
              géré par L'ERAFP (Un EPA) et la Caisse des dépôts et consignations.
            </p>
          </div>

        </div>

        <div class="section">
          <h3 id="remuneration">Primes Spécifiques</h3>
          <ItemSocial
            title="Majo Géographique"
            :value="paysheet.lines[201987].amount"
            :ligne="'201987 ' + paysheet.lines[201987].desc"
            desc="accordé aux centres du Nord et du Nord-Est, ex PCS (prime de contrainte
            de service)"
            complement="Initialement : 100 euros horaire du bureau, majoré à 150 pour les
            opérationnels. Réhaussé à la marge depuis."
            />
        </div>
</template>

<style>
.section {
   grid-column: span 8;
   display: grid;
   grid-template-columns: repeat(8, 1fr);
   gap: var(--baseline-x2);
}

.section > h3 {
  grid-column: span 8;
  grid-row:1;
}

.moitier {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--baseline-x2);
  place-content: start center;
}
.moitier h4 {
  grid-column: span 4;
}
.moitier > li {
  grid-column: span 2;
  gap: var(--baseline-x2);
}
.quart {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--baseline-x2);
  align-content: start;
}
.quart h4 {
  grid-column: span 2;
  min-height: var(--baseline-x5);
}
.quart h4 .small {
  display: block;
  margin-top: var(--baseline);
}
.col8 {
  grid-column: span 8;
}

.value_retraite {
  grid-column: 1 / 3;
}
#explication_retraite {
  grid-column: 3 / 9;
}

.gnav-tabs {
  border-bottom: 1px solid var(--secondary-color); ;
}
.gtab-button {
  margin-bottom: -1px;
  border-radius: .2em .2em 0 0;
  padding:.2em 1em .2em 1em;
  cursor: pointer;
  border:1px solid #ccc;
  border-bottom-color: transparent;
  display: flex;
  align-items: center;
}
.gtab-button.active {
  border:1px solid var(--secondary-color);
  border-bottom-color: transparent;
  background-color: #fff;
}
.gtab-button:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  border-bottom-color: transparent;
  color:white;
}
.gtab-button.active:hover {
  background-color: white;
  color:unset;
}

ul.gnav-tabs {
  display:flex;
  list-style: none;
}
.gtab-content, .gtab{
  display: grid;
  grid-template-columns: subgrid;
  gap: var(--baseline-x2);
}

img.icon {
  height: 2em;
  margin-left: .4em;
}

/* fine-grain layout */
.moitier ul {
  display: contents;
}
</style>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import ItemSocial from '@/components/result/inside_parts/ItemSocial.vue';
import SocialDouble from '@/components/result/inside_parts/SocialDouble.vue';

export default {
  name: 'ViewerSocial',
  components: [
    ItemSocial,
    SocialDouble,
  ],
};
</script>

<script setup>

const store = useStore();

const paysheet = computed(() => store.getters.paysheet);

</script>
