/**
 * Copyright (C) 2022-2023 USACcgt
 * License AGPL 3.0
 *
 * This program is free software: you can redistribute it and/or modify it under the terms of the
 * GNU Affero General Public License as published by the Free Software Foundation, version 3.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY;
 * without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See
 * the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License along with this
 * program. If not, see <https://www.gnu.org/licenses/>
 *
 */

/**
 * Basic observer implementation
 */
class Observer {
  constructor() {
    this.bindings = [];
  }
  _bind(msg,cb,userdata) {
    var length = this.bindings.push({q:msg,cb:cb,ud:userdata});
    return length - 1;
  }
  /**
   * bind can be used in 3 way:
   *  * o.bind(cb) bind to any message from this source
   *  * o.bind("msg",cb) bind only to message named "msg"
   *  * o.bind("msg",cb,data) bind only to message named "msg"
   *    and will receive data in callback
   * @param  {...any} args
   * @returns binding index (needed to unbind)
   */
  bind(...args) {
    switch (args.length) {
      case 1:
        return this._bind("any",args[0]);
      case 2:
        return this._bind(...args,null);
      case 3:
        return this._bind(...args);
      default:
        throw Error("bind take 1 or 2 arguments");
    }
  }
  unbind(idx) {
    this.bindings[idx] = null;
  }
  fire(msg,data) {
    for (let i = 0; i < this.bindings.length;i++) {
      let b = this.bindings[i];
      if (b!= null && b.q == msg) {
        if (b.ud != null) {
          b.cb.call(b.ud,data);
        } else {
            b.cb(data);
        }
      } else if (b!= null && b.q == "any") {
        if (b.ud != null) {
            b.cb.call(b.ud,msg,data);
        } else {
            b.cb(msg,data);
        }
      }
    }
  }
}
// copied from https://stackoverflow.com/a/60400899
// by Andrew Kay (kaya3), CC-BY-SA 4.0
function getAllPropertyDescriptors(obj) {
  if (!obj) {
      return Object.create(null);
  } else {
      const proto = Object.getPrototypeOf(obj);
      return {
          ...getAllPropertyDescriptors(proto),
          ...Object.getOwnPropertyDescriptors(obj)
      };
  }
}

// adapted from https://stackoverflow.com/a/68402804
// by Unmitigated, CC-BY-SA 4.0
function getGetterPropnames(obj) {
  return Object.entries(getAllPropertyDescriptors(obj))
    .filter(([k, d])=>k!= '__proto__' && typeof d.get === 'function')
    .map(([k])=>k);
}

function frenchDatestrToDate(d) {
  return new Date(Date.UTC(
    d.slice(6, 10),
    parseInt(d.slice(3, 5), 10) - 1,
    d.slice(0, 2),
  ));
}

function dateToFrenchDatestr (d) {
  return `${d.getDate().toString().padStart(2, '0')}/${
    (d.getMonth() + 1).toString().padStart(2, '0')}/${
    d.getFullYear().toString()}`;
}

function isValidFrenchdateStr(d) {
  let match = d.match(new RegExp('^([0-3][0-9])/(0[0-9]|1[0-2])/([0-9]{4})'))
  return match != null
    && parseInt(match[1]) <= 31
    && parseInt(match[2]) <= 12
}

export {
  Observer,
  getGetterPropnames,
  frenchDatestrToDate,
  dateToFrenchDatestr,
  isValidFrenchdateStr
};
