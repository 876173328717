import { evsLevel } from "./datas/generated.js"

export function getLikelyFonction(corps, site, grade, echelon) {
    let g = Guesser.fromCorps(corps)
    return g.guess(site, grade, echelon);
};

class Fonction {
    constructor(nom, desc, niveau) {
        this.nom = nom,
        this.desc = desc;
        this.niveau = niveau;
    }
}


class Guesser  {
    static fromCorps(corps) {
        switch (corps) {
            case 'iessa':
                return new IESSAGuesser();
            case 'icna':
                return new ICNAGuesser();
            default:
                return new Guesser();
        }
    }
    guess(site, grade, echelon) {
        console.warn('not implemented, should be overloaded');
        return [];
    }
}

let IESSAAffecté = new Fonction("Affecté", "en stage, dès l'arrivée en centre", 1);
let IESSAExamProFinStage = new Fonction("Exam pro > 9 mois", "exam pro après 9 mois de stage", 2);


class IESSAGuesser extends Guesser {
    guess(site, grade, echelon) {
        if (site.oaci == 'ENAC') {
            return this.guessENAC(site, grade, echelon);
        } else if (site.oaci == 'DTI') {
            return this.guessDTI(site, grade, echelon);
        }
        let likely;
        switch (grade) {
            case "eleve":
                return [];
            case "stagiaire":
                return [IESSAAffecté, IESSAExamProFinStage];
            case "normal":
                likely = [
                    new Fonction("Titulaire", "dès la QT", 5),
                    new Fonction("DMS", "", 7),
                ];
                if (site.estSiege()) {
                    likely.push(new Fonction("STM", "", 7));
                }
                if (echelon >= 2) {
                    likely.push(
                        new Fonction("QTS", "", 9),
                        new Fonction("RSO", "", 10),
                        new Fonction("GRT", "", 10),
                    )
                }
                if (echelon >= 3) {
                    likely.push(
                        getExpertConfFromSite(site),
                    );
                }
                return likely;
            case "divisionnaire":
                likely = [
                    new Fonction("QTS", "", 9),
                    new Fonction("RSO", "", 10),
                    new Fonction("GRT", "", 10),
                    new Fonction("IATSEP", "Instructeur ATSEP, QTS+2", 10),
                    getExpertConfFromSite(site),
                ]
                if (echelon >= 3) {
                    likely.push(
                        getExpertSeniorFromSite(site)
                    );
                }
                if (echelon >= 4) {
                    likely.push(
                        new Fonction("IL", "Instructeur Licence (QTS+4)", 11),
                    );
                }
                return likely;
        }
        return [];
    }
    guessENAC(site, grade, echelon) {
        let likely;
        switch (grade) {
            case "eleve":
                return [];
            case "stagiaire":
                return [
                    new Fonction("2ème année","",0),
                    IESSAAffecté];
            case "normal":
                likely = [
                    new Fonction("Titulaire", "dès la QT", 5),
                    new Fonction("Détaché 12/36", "", 7),

                ];
                if (echelon >= 2) {
                    likely.push(
                        new Fonction("QTS", "", 9),
                        new Fonction("Instructeur", "", 10),
                    )
                }
                if (echelon >= 3) {
                    likely.push(
                        new Fonction("EC", "Enseignant confirmé", 11),
                    );
                }
                return likely;
            case "divisionnaire":
                likely = [
                    new Fonction("QTS", "", 9),
                    new Fonction("Instructeur", "", 10),
                    new Fonction("EC", "Enseignant confirmé", 11),
                ]
                if (echelon >= 3) {
                    likely.push(
                        new Fonction("ES", "Enseignant senior", 12)
                    );
                }
                if (echelon >= 4) {
                    likely.push(
                        new Fonction("IE", "Inspecteurs des études", 12),
                    );
                }
                return likely;
            default:
                return [
                    new Fonction("EC", "Enseignant confirmé", 11),
                    new Fonction("ES", "Enseignant senior", 12)
                ]
        }
    }
    guessDTI(site, grade, echelon) {
        let likely = [];
        switch (grade) {
            case "eleve":
                return [];
            case "stagiaire":
                return [IESSAAffecté, IESSAExamProFinStage];
            case "normal":
                likely = [
                    new Fonction("Titulaire", "dès la QT", 5),
                    new Fonction("Détaché 12/36", "", 7),
                    new Fonction("Spécialiste","",8),
                ];
                if (echelon >= 2) {
                    likely.push(
                        new Fonction("QTS", "", 9),
                        new Fonction("Spécialiste confirmé", "", 10),
                        new Fonction("Expert", "", 10),
                    )
                }
                if (echelon >= 3) {
                    likely.push(
                        new Fonction("EC", "Expert confirmé", 11),
                    );
                }
            case "divisionnaire":
                likely = [
                    new Fonction("QTS", "", 9),
                    new Fonction("Expert", "", 10),
                    new Fonction("EC", "Expert confirmé", 11),
                ];
                if (echelon >= 3) {
                    likely.shift(); // remove QTS
                    likely.push(
                        new Fonction("ES", "Expert senior", 12)
                    );
                }
                break;
            default:
                likely = [
                    new Fonction("EC", "Expert confirmé", 11),
                    new Fonction("ES", "Expert senior", 12),
                ]
        }
        return likely;
    }
}
class ICNAGuesser extends Guesser {
    getEvsPC(site) {
        const evsPC = {
            'E': 5,
            'D': 6,
            'C': 8,
            'B': 9,
            'A': 9
        }
        return evsPC[site.groupe[0]];
    }
    getEvsCDT(site) {
        const evsCDT = {
            'E': 6,
            'D': 7,
            'C': 10,
            'B': 11,
            'A': 11
        }
        return evsCDT[site.groupe[0]];
    }
    getEvsCDQ(site) {
        const evsCDQ = {
            'C': 9,
            'B': 10,
            'A': 10
        }
        return evsCDQ[site.groupe[0]];
    }
    getLikelyPC(site) {
        return [
            new Fonction("PC", `PC des organismes de groupe ${site.groupe[0]}`, this.getEvsPC(site)),
            new Fonction("détaché", `détaché des organismes de groupe ${site.groupe[0]}`, this.getEvsPC(site) + 1),
        ];
    }
    guess(site, grade, echelon) {
        let likely;
        if (site.oaci == 'ENAC') {
            return this.guessENAC(site, grade, echelon);
        }
        switch (grade) {
            case "eleve":
                return [];
            case "stagiaire":
            case "normal":
                return [
                    new Fonction("Pas de mention", "intermédiare", 1),
                    new Fonction("1 MI", "1 mention intermédiaire d'unité", 2),
                    new Fonction("2 MI", "2 mention intermédiaire d'unité", 3),
                    new Fonction("3 MI", "3 mention intermédiaire d'unité", 5),
                ];
            case "divisionnaire":
            case "en chef":
                likely = this.getLikelyPC(site);
                if (echelon >= 3) {
                    if (site.estCRNA()){
                        likely.push(
                            new Fonction('CDE', "Chef d'équipe", 10),
                            new Fonction('A-CDS', "Adjoints au chef de salle en charge de l‘ATFCM", 10),
                            new Fonction('CDS', "Chef de salle", 11),
                        )
                    } else {
                        if (site.groupe[0] <= "C") {
                            likely.push(
                                new Fonction("CDQ", "Chef de quart", this.getEvsCDQ(site))
                            );
                        }
                        likely.push(
                            new Fonction("CDT", "Chef de tour", this.getEvsCDT(site)),
                        );
                    }
                }
                return likely;
        }
        return [];
    }

    guessENAC(site, grade, echelon) {
        switch (grade) {
            case "eleve":
            case "stagiaire":
                return [];
            default:
                return [
                    new Fonction ("ICA", "instructeurs de la circulation aérienne à l‘ENAC", 10),
                    new Fonction ("EC", "Enseignants confirmés", 11),
                    new Fonction ("ES", "Enseignants seniors", 12),
                    new Fonction ("IE", "Inspeceurs des études", 12),
                ]
        }
    }
}

function getExpertSeniorFromSite(site) {
    let evs = 11;
    if (site.estSiege() || site.estGroupeA() || ['DTI', 'DO'].includes(site.oaci)) {
        evs = 12;
    }
    return new Fonction("Expert Senior", "experts seniors", evs);
}
function getExpertConfFromSite(site) {
    let evs = 10;
    if (site.estSiege() || site.estGroupeA() || ['DTI', 'DO'].includes(site.oaci)) {
        evs = 11;
    }
    return new Fonction("Expert Conf", "experts confirmés", evs);
}
