<template>
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
      <SimpleForm/>
      <div class="collapsable" :class="{collapsed: simple, expanded: !simple, preview:preview}"
        @mouseenter="preview = simple"
        @mouseleave="preview = false"
        @click="simple = preview && ! simple; preview = false;"
        >
        <ExpertForm v-show="!simple || preview" />
          <div class="expand"
         @click.stop="simple = !simple"
         :title="(simple ? 'Afficher': 'Cacher') +' mode détaillé'">
          <template v-if="preview">
            <p v-for="n in 100" :key="n">▸</p>
          </template>
          <template v-else-if="simple">
            <p v-for="n in 4" :key="n">▸</p>
            <div class="simple-legend">Mode détaillé</div>
            <p v-for="n in 90" :key="n">▸</p>
          </template>
          <template v-else><p v-for="n in 100" :key="n">◂</p></template>
        </div>
      </div>
      <div id="resetContainer">
        <button id="resetButton" class="btn" @click="store.dispatch('resetState')">
          Effacer le formulaire et repartir de zéro
        </button>
      </div>
      <div class="recap" v-show="isDev">
        <hr>
        <span v-for="field in Object.keys(input)" :key="field"> {{field}}: {{input[field]}}</span>
        <hr>
        <span v-for="action in store.state.actions" :key="action"> {{action}} </span>
      </div>
</template>

<style scoped>
/* layout */

aside > .comp {
  /* required to hide ExpertForm*/
  background-color: inherit;
  border-radius:inherit;
}

.collapsable {
  background-color: rgba(var(--secondary-lt2-color-split), .95);
  border-radius: 0 var(--baseline) var(--baseline) 0;
  position: absolute;
  left:100%;
  margin:1em 0 1em 0;
  top:0;
  bottom:0;
  display:flex;
  z-index:-1;
}
.collapsed:hover {
  animation: .5s rotatePreview forwards;
  left: 30%;
  z-index:-1;
  cursor: pointer;
}
@keyframes rotatePreview {
  from { rotate: 0deg; }
  to   { rotate: 3deg; }
}

.expanded, .collapsed:hover {
  width:25vw;
}

@media (max-width: 1024px) {
  .expanded, .collapsed:hover {
    width:50vw;
  }
}

@media (max-width: 800px) {
  .collapsed {
    display: none;
  }
}
.expanded {
  left:100%;
  animation: .3s ease-in-out goLeft forwards;
}
@keyframes goLeft {
  from {
    left: 30%;
    rotate: 3deg
  }
  to {
    left: 100%;
    rotate:0deg;
 }
}

.expand {
  flex: 0 0 auto;
}
.collapsed {
  width:1em;
  overflow: clip;
}
.expanded > .expand {
  backdrop-filter: grayscale(50%);
  cursor: pointer;
  overflow: hidden;
}

.simple-legend {
  writing-mode: vertical-lr;
  transform-origin: 40% 50%;
  rotate:180deg;
  font-size: .7em;
}

.expand > p {
  margin: 0;
}

.recap {
  grid-column: span 4;
}
.recap span {
  display:block;
}

#resetContainer {
  padding: 1em;
  display:flex;
  justify-content: center;
}
</style>
<style>
/* FIXME refactor
 * ou placer ce code ? aside n'est pas dans le composant, ce code n'est nécessaire que pour le
 *  menu détaillé en tirette
 */
aside {
  position: relative;
  z-index:1;
}

.collapsable > div.comp > h3 {
  transform-origin: 90% 2%;
}
.preview > div.comp > h3 {
  rotate: -90deg;
  background-color: rgba(var(--secondary-lt2-color-split), .5);
  color: #333;
}
.expanded > div.comp > h3 {
  animation: .3s ease-in-out rotateBack forwards;
}
@keyframes rotateBack {
  from {
    rotate: -90deg;
    color: #333;

  }
  to {
    rotate: 0deg;
    color: var(--secondary-color);
 }
}

.btn-outline-primary {
  color: black;
}
.btn.active, .btn:hover, .btn:active:focus {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color:white;
}
.btn {
  background-color: var(--secondary-lt2-color);
  border: 1px solid var(--secondary-color);
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import SimpleForm from '@/components/input/SimpleForm.vue';
import ExpertForm from '@/components/input/ExpertForm.vue';

const store = useStore();
const { input } = store.state;

const simple = ref(true);
const preview = ref(false);
const isDev = computed(() => process.env.NODE_ENV === 'development');

</script>

<script>
export default {
  name: 'InputForm',

};
</script>
