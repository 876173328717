<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
    <div class="animated-input">
      <label :for="myId">{{label}}</label>
      <select :id="myId" :name="myId"
        @change="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        :disabled="disabled">
        <template v-for="item in items" :key="item.value">
            <option :value="item.value">{{item.desc}}</option>
        </template>
      </select>
    </div>
</template>

<style>
.new-value {
       animation: pulse-update .8s;
}

@keyframes pulse-update {
       0% {
               transform: scale(1);
       }
       20% {
               text-shadow: #0CF 1px 0 6px;
               transform: scale(1.05);
       }
       100% {
               transform: scale(1);
       }
}
</style>

<script setup>
import {
  defineProps,
  onMounted,
  ref,
  defineEmits,
  watch,
} from 'vue';

const myId = ref();

const props = defineProps(['label', 'modelValue', 'disabled', 'items', 'disabled']);

onMounted(() => {
  myId.value = `Selector${getId()}`;
});
defineEmits(['update:modelValue']);

function animateField() {
  const field = document.getElementById(myId.value);
  field.classList.remove('new-value');
  setTimeout(() => field.classList.add('new-value'), 0);
}

watch(() => props.modelValue, (newV, oldV) => {
  animateField();
});
</script>

<script>
export default {
  name: 'AnimatedSelector',
};

let counter = 0;
function getId() {
  counter += 1;
  return counter;
}
</script>
