<template>
<div class="container">
  <div class="tableQualifTS">
  <div class="row">
    <div class="qualifTSName">
      1ère Qualif
    </div>
    <div class="btn-group qualifTS" role="group" aria-label="1ère qualif">
        <button type="button" class="btn btn-outline-primary col-sm-3" data-TSEtape="1"
        @click="selectEtape">
            Non</button>
        <button type="button" class="btn btn-outline-primary col-sm-3" data-TSEtape="2"
        @click="selectEtape">
            &lt;1 an</button>
        <button type="button" class="btn btn-outline-primary  col-sm-3" data-TSEtape="3"
        @click="selectEtape">
             ≥ 1 an</button>
    </div>
    </div>
  <div class="row">
    <div class="qualifTSName">
      2ème Qualif
    </div>
    <div class="btn-group qualifTS col-sm-8" role="group" aria-label="2e qualif">
      <button type="button" class="btn btn-outline-primary col-sm-3" data-TSEtape="4"
      @click="selectEtape">
        &lt; 1 an</button>
      <button type="button" class="btn btn-outline-primary col-sm-3" data-TSEtape="5"
      @click="selectEtape">
        &lt; 4 ans</button>
      <button type="button" class="btn btn-outline-primary col-sm-3" data-TSEtape="6"
      @click="selectEtape">
        ≥ 4 ans</button>
    </div>
  </div>
  </div>

  <div class="tech-ts-container">
    <toggle-button
      label="Part Technique"
      title="Les primes controle et technique sont exclusives. Si vous être contrôleur, la prime
      dépend du terrain sélectionné."
      v-model="enableTechnique"
      id="toggleTSTechnique"
    />

    <div class="techTS" :class="{enabled: enableTechnique}">
        <AnimatedSelector
          label="Habilitation"
          :items="partTechTSHabilLevels"
          v-model="partTechTSHabil"
          :disabled="!enableTechnique"
          @update:modelValue="partTechTSHabil = $event; emitCarriere()"
        />
    </div>
  </div>
</div>
</template>

<style scoped>
.tableQualifTS {
  padding: 0;
}

.container, .tableQualifTS, .row, .tech-ts-container {
  grid-column: span 4;
  display: grid;
  grid-template-columns: subgrid;
}

.btn-group.qualifTS {
  grid-column: span 3;
}
.btn {
  width: 33.3%;
}

.tableQualifTS > .row:first-child >.btn-group > .btn {
  border-bottom: 0;
}
.tableQualifTS > .row:first-child >.btn-group > .btn:first-child {
  border-top-left-radius: .4em;
}
.tableQualifTS > .row:first-child >.btn-group > .btn:last-child {
  border-top-right-radius: .4em;
}
.tableQualifTS > .row:last-child >.btn-group > .btn:first-child {
  border-bottom-left-radius: .4em;
}
.tableQualifTS > .row:last-child >.btn-group > .btn:last-child {
  border-bottom-right-radius: .4em;
}

.techTS {
  color:grey;
  grid-column: span 4;
  display: grid;
  grid-template-columns: subgrid;
}
.techTS.enabled {
  color: unset;
}
.tech-ts-container {
  margin-top: .3em;
  border: 1px solid var(--secondary-color);
}
.animated-input {
  grid-column: span 4;
  display: grid;
  grid-template-columns: subgrid;
}
#toggleTSTechnique {
  grid-column: span 4;
}
</style>
<style>
/* FIXME */
.animated-input > select {
  grid-column: span 3;
}
</style>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import AnimatedSelector from '@/components/input/widgets_general/AnimatedSelector.vue';

import ToggleButton from '@/components/input/widgets_general/ToggleButton.vue';

const enableTechnique = ref(false);
const partTechTSHabil = ref(0);

const store = useStore();
const dp = computed(() => store.getters.paysheet.dp);

const partTechTSHabilLevels = computed(() => [...dp.value.partTechTSHabilLevel].map((item) => ({
  desc: item.name,
  value: item.partTechTSHabil,
})));

function selectEtape(ev) {
  document
    .querySelectorAll('.tableQualifTS > .row >.btn-group > button.active')
    .forEach((element) => {
      element.classList.remove('active');
    });
  ev.target.classList.add('active');
  const etape = parseInt(ev.target.getAttribute('data-TSEtape'), 10);
  emitCarriere();
}

function emitCarriere() {
  const activeElement = document.querySelector('.tableQualifTS > .row >.btn-group > button.active');
  const etape = activeElement ? parseInt(activeElement.getAttribute('data-TSEtape'), 10) : 0;
  emit('carriere', {
    TSEtape: etape,
    part3: enableTechnique.value ? 'technique' : 'controle',
    partTechTSHabil: partTechTSHabil.value,
  });
}

watch(enableTechnique, (newV, oldV) => {
  if (!newV) {
    partTechTSHabil.value = 0;
    emitCarriere();
  }
});

const emit = defineEmits(['carriere']);
</script>

<script>
export default {
  name: 'CarriereTSEEAC',
};
</script>
