class Deducer {
  constructor(hooks, d) {
    this.hooks = hooks;
    this.deduce = d;
  }

  subscribeOn(o) {
    this.hooks.forEach((element) => {
      o.bind(element, this.deduce.bind(this));
    });
  }
}

/* eslint-disable no-param-reassign */
const licenceDeducer = new Deducer(
  ['corps', 'site', 'grade', 'qualifLOC'],
  (s) => {
    if (s.input.corps === 'icna') {
      let niveauLic = 0;
      switch (s.input.grade) {
        case 'élève':
          break;
        case 'stagiaire':
        case 'normal':
          if (['LFPG', 'LFPO'].includes(s.input.site)
              && s.input.qualifLOC
              && s.input.qualifLOC !== '0') {
            niveauLic = s.input.grade === 'stagiaire' ? 1 : 4;
          }
          break;
        default:
          if (s.input.groupe) {
            niveauLic = {
              G: 2,
              F: 3,
              E: 4,
              D: 4,
              C: 4,
              B: 5,
              A: 5,
            }[s.input.groupe[0]];
          }
      }
      s.deduced.licence = niveauLic;
    } else {
      delete s.deduced.licence;
    }
  },
);

const pcsDeducer = new Deducer(
  ['site', 'corps'],
  (s) => {
    if (s.input.siteData?.pcs === '1') {
      // par défaut: opérationnel
      s.deduced.pcs = 150;
      if (s.input.corps === 'ieeac') {
        s.deduced.pcs = 100;
      }
      if (s.input.corps === 'tseeac') {
        if (s.input.siteData.estTerrainTS()) {
          s.deduced.pcs = 150;
        } else {
          s.deduced.pcs = 100;
        }
      }
    } else {
      delete s.deduced.pcs;
    }
  },
);

const nbiDeducer = new Deducer(
  ['corps', 'grade', 'echelon'],
  (s) => {
    if (['en chef', 'détaché'].includes(s.input.grade)
    || (
      s.input.corps === 'iessa'
      && s.input.grade === 'divisionnaire'
      && (parseInt(s.input.echelon, 10) > 6))
    ) {
      s.deduced.nbi = 1;
    } else {
      delete s.deduced.nbi;
    }
  },
);

const peqDeducer = new Deducer(
  ['corps', 'grade', 'echelon'],
  (s) => {
    if (s.input.corps === 'iessa') {
      switch (s.input.grade) {
        case 'élève':
        case 'stagiaire':
          delete s.deduced.peq;
          break;
        case 'normal':
          s.deduced.peq = 1;
          break;
        case 'divisionnaire':
          s.deduced.peq = parseInt(s.input.echelon, 10) >= 7 ? 5 : 3;
          break;
        default:
          s.deduced.peq = 5;
      }
    } else {
      delete s.deduced.peq;
    }
  },
);

const deducers = [
  licenceDeducer,
  pcsDeducer,
  nbiDeducer,
  peqDeducer,
];

export default deducers;
