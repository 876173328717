<template>
  <div class="abonnement-transport">
    <toggle-button label="Abonnement transport" v-model="showDetails" />
      <div v-if="showDetails">
        <template v-if="site?.transport">
          <template v-for="abo in Object.keys(site.transport)" :key="abo">
          <button
            :data="site.transport[abo]"
            @click="copyPriceToInput($event)">
              {{abo}}
          </button>
        </template>
        </template>
        <input id="inputAbo" size=4 v-model="prixAbo"> →
        <input
          id="inputRembt"
          size=4
          disabled
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        />
      </div>
  </div>
</template>

<style scoped>

</style>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import ToggleButton from '@/components/input/widgets_general/ToggleButton.vue';

const showDetails = ref(false);
const prixAbo = ref();
let oldValue;

const store = useStore();
const dp = computed(() => store.getters.paysheet.dp);

defineProps({
  site: {
    type: Object,
  },
  modelValue: {
    type: Number,
  },
});

const emit = defineEmits(['update:modelValue']);

watch(showDetails, (newV, oldV) => {
  if (!newV) {
    const element = document.getElementById('inputRembt');
    if (element.value) {
      oldValue = element.value;
      setRembtValue(0);
    }
  } else if (oldValue) {
    window.setTimeout(() => {
      const element = document.getElementById('inputRembt');
      element.value = oldValue;
      setRembtValue(oldValue);
    }, 1);
  }
});
watch(prixAbo, (newV, oldV) => {
  if (!Number.isNaN(parseFloat(newV))) {
    setRembtValue(parseFloat(newV) * (dp.value.tauxAboTransport));
  }
});

function setRembtValue(v) {
  document.getElementById('inputRembt').value = v;
  emit('update:modelValue', v);
}

function copyPriceToInput(e) {
  prixAbo.value = parseFloat(e.target.getAttribute('data'));
}
</script>

<script>
export default {
  name: 'TransportSelect',
};
</script>
