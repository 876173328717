<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div class="container">
    <div class="carriere-icna">
      <toggle-button
        label="Qualif LOC"
        title="À Orly et Roissy, la qualif LOC déclenche un cran d'ISQ"
        v-model="input.qualifLOC"
        id="toggleQualifLOC"
        :map="{true:1,false:0}"
        v-show="showQualifLoc"
    />
    </div>
  </div>
</template>

<style scoped>
/* layout */
.container,
.carriere-icna,
.carriere-icna > .toggle-button {
  grid-column: span 4;
  display: grid;
  grid-template-columns: subgrid;
}
</style>

 <!-- eslint-disable no-param-reassign -->
<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

import ToggleButton from '@/components/input/widgets_general/ToggleButton.vue';

const store = useStore();
const { input } = store.state;

const showQualifLoc = computed(() => ['LFPG', 'LFPO'].includes(input.site)
    && ['stagiaire', 'normal'].includes(input.grade));

watch(showQualifLoc, (newV, oldV) => {
  if (!newV) {
    store.commit('deleteInput', 'qualifLOC');
  }
});

</script>

<script>
export default {
  name: 'CarriereICNA',
};
</script>
