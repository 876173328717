<template>
    <div id="matomo"/>
</template>

<script setup>
import { onMounted } from 'vue';

function getInstanceSpecific() {
  const { hostname } = window.location;
  switch (hostname) {
    case 'usaccgt.gitlab.io':
      return '_paq.push(["setSiteId", "2"]);';
    case 'localhost':
      return '';
    default:
      /* usac-cgt.org subdomain */
      return `
    _paq.push(["setSiteId", "1"]);
    _paq.push(["setCookieDomain", "*.usac-cgt.org"]);
    _paq.push(["setDocumentTitle", "${hostname.split('.')[0]} | " + document.title]);
      `;
  }
}

function getTrackingCode() {
  const specific = getInstanceSpecific();
  if (!specific) {
    return '';
  }
  const trackingCode = `
var _paq = _paq || [];
(function () {
    var u = (("https:" == document.location.protocol) ? "https://usac-cgt.org/matomo/" : "https://usac-cgt.org/matomo/");
    ${specific}
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setDoNotTrack", 1]);
    if (!window.matomo_search_results_active) {
         _paq.push(["trackPageView"]);
    }
    _paq.push(["setIgnoreClasses", ["no-tracking", "colorbox"]]);
    _paq.push(["enableLinkTracking"]);
    var d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.defer = true;
    g.async = true;
    g.src = u + "matomo.js"; s.parentNode.insertBefore(g, s);
})();
`;
  return trackingCode;
}

onMounted(() => {
  const tracking = document.createElement('script');
  tracking.innerText = getTrackingCode();
  document.head.append(tracking);
});
</script>
