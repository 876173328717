<template>
  <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
  <div class="site-picker" :id="sitePickerId">
    <select v-model="centre" @change="setCentre(centre)">
        <option value="" selected disabled hidden>
          {{caption}}
        </option>
      <template v-for="site in listSites" :key="site.oaci">
        <option :value="site.oaci">{{ site.nom }}</option>
      </template>
    </select>
    <div class="mapContainer" @keydown.esc="modal &&= false" v-if="withMap">
      <div id="metroMap" />
      <div class="minimapContainer"  v-show="modal">
        <div id="saintPierreMap" class="minimap" data-lon="-56.29" data-lat="46.93" data-zoom="8"/>
        <div id="guadeloupeMap" class="minimap" data-lon="-61.56" data-lat="16.25" data-zoom="8" />
        <div id="martiniqueMap" class="minimap" data-lon="-61" data-lat="14.59" data-zoom="8" />
        <div id="guyaneMap" class="minimap" data-lon="-53.10" data-lat="4.7" data-zoom="6" />

        <div id="pacifiqueMap" class="minimap" data-lon="188.5" data-lat="-17.6" data-zoom="1"
             data-clusterZoomThreshold="4.5" />
        <div id="mayotteMap" class="minimap" data-lon="45.15" data-lat="-12.84" data-zoom="9" />
        <div id="reunionMap" class="minimap" data-lon="55.53" data-lat="-21.13" data-zoom="7" />
      </div>
    </div>
  </div>
</template>

<style scoped>
/* layout */
.site-picker {
  grid-column: span 4;
}

#metroMap {
  min-height: 200px;
  min-width: 200px;
  position:relative;
  inset:0px;
}
#metroMap.gmodal {
  position: absolute;
  inset: 0px;
  z-index: 10;
}

.mapContainer.gmodal {
  position:absolute;
  inset:0px;
}

.minimapContainer {
  display:grid;
  grid-template-columns: repeat(4, 1fr) ;
  grid-template-rows: repeat(8, 1fr);
  height: 100%;
  gap: var(--baseline);
}

.minimap {
  width:100%;
  height:100%;
  z-index:20;
  border: solid 1px;
}

#saintPierreMap {
  grid-column: 1;
  grid-row:1/3;
}
#guadeloupeMap {
  grid-column: 1;
  grid-row:3/5;
}
#martiniqueMap {
  grid-column: 1;
  grid-row:5/7;
}
#guyaneMap {
  grid-column: 1;
  grid-row:7/9;
}

#pacifiqueMap {
  grid-column: 4;
  grid-row:2 / 5;
}

#mayotteMap {
  grid-column: 4;
  grid-row:5/7;
}

#reunionMap {
  grid-column: 4;
  grid-row:7/9;
}

</style>
<style>
img.leaflet-tile-loaded {
  filter: sepia(60%) hue-rotate(310deg);
}
.minimap > div.leaflet-map-pane > div.leaflet-tile-pane > div.leaflet-layer
  > div.leaflet-tile-container > img {
    filter:sepia(20%);
  }
img.leaflet-marker-icon.selected {
  filter:hue-rotate(148deg);
  z-index: 100;
}

.leaflet-overlay-pane {
  z-index: 610;
}

.modal-switch {
  font-size: 2em;
}

.rotate270 {
  transform: rotate(270deg);
  display:inline-block;
}

/* adapt modal map button to WIP */
/* FIXME should be only applied when wip is displayed */
.gmodal > .leaflet-control-container > .leaflet-top > .go-modal {
  margin-top: 4em;
}

/* FIXME component manipulate outer element DOM*/
#aside-gmodal {
  position: absolute;
  inset: 0px;
}
</style>

<script setup>
import {
  onMounted,
  ref,
  watch,
  computed,
} from 'vue';

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';

import {
  getSitesFromCorps,
  getAllSites,
} from '../../../../../js/sites';

const centre = ref('');

const sitePickerId = ref();
/* eslint-disable no-unused-vars */
const modal = ref(false);
/* eslint-enable no-unused-vars */

const props = defineProps({
  corps: {
    type: String,
  },
  withMap: Boolean,
  filterByCorps: Boolean,
  currentSite: String,
});

const emit = defineEmits(['site']);

const listSites = computed(
  () => (props.filterByCorps ? getSitesFromCorps(props.corps) : getAllSites()),
);
const caption = computed(
  () => (props.filterByCorps
    ? 'Choisir le centre dans la liste ou sur la carte'
    : 'Tous les sites'),
);

function setCentre(oaciCode) {
  centre.value = oaciCode;
  emit('site', centre.value);
  selectMarkerByOaci(oaciCode);
}

/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
let map;
let markers = {};
let selected = null;
const minimaps = [];

L.Control.GoModal = L.Control.extend({
  onAdd(map) {
    const div = L.DomUtil.create('div', 'go-modal');
    div.innerHTML = '<button '
      + `onclick="window.switchModals['${map._sitePickerId}']()"`
      + 'class="modal-switch">🗺<span class="rotate270">⇲</span></button>';
    return div;
  },

  onRemove(map) {
    // Nothing to do here
  },
});

L.control.goModal = function (opts) {
  return new L.Control.GoModal(opts);
};

onMounted(() => {
  sitePickerId.value = `sitePicker${getId()}`;

  if (!props.withMap) {
    return;
  }

  map = L.map('metroMap', {
    center: L.latLng(46.5, 2.6),
    zoom: 4.25,
    zoomSnap: 0,
    zoomDelta: 1,
    wheelPxPerZoomLevel: 16,
    zoomControl: false,
  });
  map._sitePickerId = sitePickerId.value;
  getDefaultTileLayer().addTo(map);
  L.control.goModal({ position: 'topright' }).addTo(map);

  Array(...document.getElementsByClassName('minimap')).forEach((elem) => {
    const minimapId = elem.getAttribute('id');
    const minimap = L.map(minimapId, {
      center: L.latLng(
        parseFloat(elem.getAttribute('data-lat')),
        parseFloat(elem.getAttribute('data-lon')),
      ),
      zoom: parseFloat(elem.getAttribute('data-zoom')),
      zoomControl: false,
    });
    if (elem.getAttribute('data-clusterZoomThreshold')) {
      minimap.myClusterZoomThreshold = parseFloat(elem.getAttribute('data-clusterZoomThreshold'));
    }
    getDefaultTileLayer().addTo(minimap);
    minimaps.push(minimap);
  });

  updateMarkers(props.corps);

  // hacky way to expose vue method from outside
  if (window.switchModals === undefined) {
    window.switchModals = {};
  }
  window.switchModals[sitePickerId.value] = switchModal;
});

function switchModal() {
  /* eslint-disable no-bitwise */
  modal.value ^= true;
  /* eslint-enable no-bitwise */
}

function getDefaultTileLayer() {
  return getTileLayer();
}

function getTileLayer(name) {
  switch (name) {
    case 'toner-lite':
      return L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}', {
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        subdomains: 'abcd',
        minZoom: 3,
        maxZoom: 11,
        ext: 'png',
      });
    case 'osm':
    default:
      return L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 14,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      });
  }
}

function updateMarkers(corps) {
  markers = {};
  selected = null;
  [map, ...minimaps].forEach((m) => updateMarkerOnMap(corps, m));
  if (centre.value in markers) {
    selectMarkerByOaci(centre.value);
  }
  applyRatioOnMarkerAccordingModal();
}

class Cluster {
  constructor(name, lat, lon, radius, zoomLevel) {
    this.name = name;
    this.lat = lat;
    this.lon = lon;
    this.radiusKm = radius;
    this.zoomLevel = zoomLevel;
  }

  addMarker(layer, map) {
    const circle = L.circle([this.lat, this.lon], {
      color: '#FF6648',
      fillColor: '#679CC3',
      fillOpacity: 0.5,
      radius: this.radiusKm * 1000,
    });
    circle.addTo(layer);
    circle.on('click', (e) => {
      map.setView(e.target.getLatLng(), this.zoomLevel);
    });
    return circle;
  }
}

const clusters = [
  new Cluster('Paris', 48.88, 2.3, 45, 9),
  new Cluster('Bordeaux', 44.83346, -0.69926, 35, 14),
  new Cluster('Polynésie', -17.7, 210, 1000, 6),
  new Cluster('Toulouse', 43.562, 1.435, 35, 10),
];

/* Par défaut, les markers sont affichés de gauche à droite,
   avec les markeurs à droite au dessus.
*/
const markerOffsets = {
  LFFF: 100,
};

function updateMarkerOnMap(corps, cmap) {
  if (cmap === undefined) {
    return;
  }
  if (cmap.myMarkerLayer !== undefined) {
    cmap.removeLayer(cmap.myMarkerLayer);
  }
  if (cmap.myClusterLayer !== undefined) {
    cmap.removeLayer(cmap.myClusterLayer);
  }
  /* eslint-disable no-param-reassign */
  cmap.myMarkerLayer = L.layerGroup();
  listSites.value.forEach((s) => {
    const options = {};
    if (s.oaci in markerOffsets) {
      console.log(`setOffset for ${s.oaci}`);
      options.zIndexOffset = markerOffsets[s.oaci];
    }
    const marker = L.marker([s.lat, s.lon], options)
      .on('click', () => setCentre(s.oaci))
      .bindTooltip(s.nom, {
        direction: 'center',
      });
    if (!markers[s.oaci]) {
      markers[s.oaci] = [];
    }
    markers[s.oaci].push(marker);
    marker.addTo(cmap.myMarkerLayer);
  });
  cmap.myMarkerLayer.addTo(cmap);

  cmap.myClusterLayer = L.layerGroup();
  clusters.forEach((c) => {
    c.addMarker(cmap.myClusterLayer, cmap)
      .bindTooltip(c.name, {
        direction: 'center',
      });
  });
  cmap.myClusterLayer.addTo(cmap);
  const showHideClusterLayer = () => {
    const clusterZoomThreshold = cmap.myClusterZoomThreshold ?? 8.5;
    if (cmap.getZoom() > clusterZoomThreshold) {
      cmap.removeLayer(cmap.myClusterLayer);
    } else {
      cmap.addLayer(cmap.myClusterLayer);
    }
  };
  cmap.on('zoomend', showHideClusterLayer);
  showHideClusterLayer();
}

watch(() => props.corps, (newV, oldV) => {
  updateMarkers(newV);
});

watch(() => props.currentSite, (newV, oldV) => {
  setCentre(newV);
});

function selectMarkerByOaci(oaciCode) {
  selectMarker(markers[oaciCode]);
}
function selectMarker(ms) {
  if (selected) {
    selected.forEach((s) => {
      s._icon?.classList.remove('selected');
      s.setZIndexOffset(0);
    });
  }
  if (ms) {
    ms.forEach((m) => {
      m._icon?.classList.add('selected');
      m.setZIndexOffset(50);
    });
    selected = ms;
  }
}

watch(modal, (newV, oldV) => {
  if (newV) {
    openModal();
  } else {
    closeModal();
  }
  map.invalidateSize();
  window.setTimeout(() => {
    minimaps.forEach((m) => m.invalidateSize());
  }, 10);
});

/* eslint-disable no-unused-vars */
function openModal() {
  map.setView(map.getCenter(), map.getZoom() + 1.5);
  const mm = document.getElementById('metroMap');
  mm.classList.add('gmodal');
  mm.parentElement.classList.add('gmodal');
  mm.style.position = 'inherit';
  document.getElementsByTagName('aside')[0].setAttribute('id', 'aside-gmodal');
  document.getElementById('metroMap').scrollIntoView();
  applyRatioOnMarkerAccordingModal();
}

function closeModal() {
  map.setView(map.getCenter(), map.getZoom() - 1.5);
  const mm = document.getElementById('metroMap');
  mm.classList.remove('gmodal');
  mm.parentElement.classList.remove('gmodal');
  mm.style.position = 'relative';
  document.getElementsByTagName('aside')[0].removeAttribute('id');
  applyRatioOnMarkerAccordingModal();
}

function applyRatioOnMarkerAccordingModal() {
  applyRatioOnMarker('metroMap', modal.value ? 1 : 0.66);
}

function applyRatioOnMarker(mapId, r) {
  /* cannot use class because leaflet put direcct style on markers */
  const normalWidth = 25;
  const normalHeight = 41;
  const width = normalWidth * r;
  const height = normalHeight * r;
  Array(
    ...document.getElementById(mapId)
      .getElementsByClassName('leaflet-map-pane')[0]
      .getElementsByClassName('leaflet-marker-pane')[0]
      .getElementsByTagName('img'),
  ).forEach((icon) => {
    icon.style.width = `${width}px`;
    icon.style.height = `${height}px`;
    icon.style['margin-left'] = `-${width / 2}px`;
    icon.style['margin-top'] = `-${height}px`;
  });
}

</script>

<script>
export default {
  name: 'SitePicker',
};

let counter = 0;
function getId() {
  counter += 1;
  return counter;
}
</script>
