<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="corps-picker">
    <button v-for="item in items" :key="item"
      class="corpsButton btn"
      :class="{corpsButton, btn, active: item == modelValue}"
      :id="`corpsButton-${item}`"
      @click="click(item)">
      {{item.toUpperCase()}}
    </button>
</div>
</template>

<style>
.corps-picker {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: .4em;
}

.btn {
  /* font-family: var(--effect-text); */
  font-size: 1em;
  box-shadow: 2px 2px 2px rgba( 0, 0, 0, 0.4)
}

.btn.active {
  text-shadow: 1px 0 rgba( 0, 0, 0, 0.75);
  box-shadow: unset;
}
</style>

<script setup>
import {
  defineProps,
  defineEmits,
} from 'vue';

defineProps(['modelValue', 'items']);

const emit = defineEmits(['update:modelValue']);

function click(item) {
  Array(...document.getElementsByClassName('corpsButton')).forEach((element) => {
    element.classList.remove('active');
  });
  document.getElementById(`corpsButton-${item}`).classList.add('active');
  emit('update:modelValue', item);
}

</script>

<script>
export default {
  name: 'CorpsPicker',
};
</script>
